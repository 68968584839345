import React from 'react'
import classNames from 'classnames'

const SectionHero: React.FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col space-y-5 px-3 py-5 md:p-5 md:rounded-lg md:overflow-hidden bg-primary-500',
        className
      )}
    >
      {children}
    </div>
  )
}

export { SectionHero }
