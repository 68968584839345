import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { AsyncView } from '@aboutbits/react-toolbox'
import { useIntl } from 'react-intl'
import { isBefore } from 'date-fns'
import { useGetActivityConfig } from '../db/activities'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { ActionButtonSection } from '../../../shared/section/ActionButtonSection'
import { LeaveDirtyFormPrompt } from '../../../shared/form/LeaveDirtyFormPrompt'
import { ActivityFormFields } from './types'
import { GeneralFormSection } from './GeneralFormSection'
import { TimingFormSection } from './TimingFormSection'
import { ProjectFormSection } from './ProjectFormSection'
import { AddressFormSection } from './AddressFormSection'
import { ArticleFormSection } from './ArticleFormSection'
import { ImageFormSection } from './ImageFormSection'

const ActivityForm: React.FC<{
  initialValues: ActivityFormFields
  onSubmit: (
    values: ActivityFormFields,
    formikHelpers: FormikHelpers<ActivityFormFields>
  ) => void
}> = ({ initialValues, onSubmit }) => {
  const intl = useIntl()
  const history = useHistory()

  const { data } = useGetActivityConfig()

  const requiredField = intl.formatMessage({ id: 'shared.validation.required' })
  const schema = Yup.object().shape({
    name: Yup.string().required(requiredField),
    description: Yup.string(),
    activityCategoryId: Yup.string().required(requiredField),
    activityTypeId: Yup.string().required(requiredField),
    activityProgressId: Yup.string().required(requiredField),
    startDate: Yup.date().required(requiredField),
    endDate: Yup.date()
      .test(
        'startDate_is_before_endDate',
        intl.formatMessage({ id: 'activity.form.validation.endDate' }),
        function (value) {
          if (value) {
            return isBefore(this.parent.startDate, value)
          } else {
            return true
          }
        }
      )
      .required(requiredField),
    addressId: Yup.string().required(requiredField),
  })
  const [isUploading, setIsUploading] = useState(false)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {(props) => (
        <AsyncView
          data={data}
          renderSuccess={(data) => (
            <Form>
              <LeaveDirtyFormPrompt />
              <GeneralFormSection formik={props} config={data} />
              <TimingFormSection />
              <ProjectFormSection formik={props} />
              <AddressFormSection formik={props} />
              <ArticleFormSection formik={props} />
              <ImageFormSection
                formik={props}
                setIsUploading={setIsUploading}
              />
              <ActionButtonSection className="pt-5 md:pt-5 pb-5 md:pb-5">
                <CancelButton
                  disabled={isUploading}
                  onClick={() => history.goBack()}
                />
                <SubmitButton disabled={isUploading} />
              </ActionButtonSection>
            </Form>
          )}
        />
      )}
    </Formik>
  )
}

export { ActivityForm }
