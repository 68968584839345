import { Activity } from '../types'
import { Project } from '../../project/types'

function populateActivityData(
  activities: Array<Activity> | null | undefined,
  projects: Array<Project> | null
): Array<Activity> {
  if (activities === null || activities === undefined) {
    return []
  }

  return activities.map((item) => {
    const project = projects?.find((project) => project.id === item.projectId)

    if (project) {
      return { ...item, projectName: project.name }
    } else {
      return item
    }
  })
}

export { populateActivityData }
