import React from 'react'
import { useIntl } from 'react-intl'
import { Project } from '../types'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { SectionListVariant } from '../../../shared/section/SectionList'
import { ProjectsOverviewItem } from './ProjectsOverviewItem'

const ProjectsOverviewFavorites: React.FC<{
  data: Project[]
}> = ({ data }) => {
  const intl = useIntl()

  if (data.length === 0) return null

  return (
    <Section>
      <SectionTitle>
        {intl.formatMessage({ id: 'project.overview.list.favorites' })}
      </SectionTitle>
      <SectionList variant={SectionListVariant.PRIMARY}>
        {data.map((project, index) => (
          <ProjectsOverviewItem key={index} project={project} />
        ))}
      </SectionList>
    </Section>
  )
}

export { ProjectsOverviewFavorites }
