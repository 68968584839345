import React, { useState } from 'react'
import { useField } from 'formik'
import { FormattedMessage } from 'react-intl'

import { useGetCostCenterById } from '../../costCenter/db/costCenters'
import { SelectWithDialogField } from './SelectWithDialogField'
import { SelectCostCenterDialog } from './SelectCostCenterDialog'

type Props = {
  costCenterIdFieldName: string
  disabled?: boolean
}

const SelectCostCenter: React.FC<Props> = ({
  costCenterIdFieldName,
  disabled,
}) => {
  const [, meta, helpers] = useField({ name: costCenterIdFieldName })
  const [showPopUp, setShowPopUp] = useState(false)
  const { value } = meta
  const { data: selectedCostCenter } = useGetCostCenterById(value || '')

  const { setValue } = helpers

  return (
    <>
      <SelectWithDialogField
        touched={meta.touched}
        hasError={!!meta.error}
        fieldName={costCenterIdFieldName}
        value={selectedCostCenter?.name || ''}
        disabled={disabled}
        onClick={(): void => {
          setShowPopUp(true)
        }}
        label={<FormattedMessage id="timeTracking.edit.costCenter.label" />}
      />
      <SelectCostCenterDialog
        isOpen={showPopUp}
        onDismiss={(): void => {
          setShowPopUp(false)
        }}
        onConfirm={(value): void => {
          setValue(value)
          setShowPopUp(false)
        }}
      />
    </>
  )
}

export { SelectCostCenter }
