import React from 'react'
import { Dialog } from '@reach/dialog'
import { useIntl } from 'react-intl'

import { CostCentersSelection } from '../../costCenter/selection/CostCentersSelection'

type Props = {
  isOpen: boolean
  onConfirm: (value: string) => void
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
}

const SelectCostCenterDialog: React.FC<Props> = ({
  onDismiss,
  isOpen,
  ...props
}) => {
  const intl = useIntl()

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={onDismiss}
      aria-label={intl.formatMessage({
        id: 'timeTracking.select.costCenter.title',
      })}
      className="md:my-20 mx-auto md:w-full md:max-w-lg bg-white md:bg-transparent"
    >
      <CostCentersSelection onDismiss={onDismiss} {...props} />
    </Dialog>
  )
}

export { SelectCostCenterDialog }
