import React from 'react'
import { AppLayout } from '../../layouts/app'
import { ActivityOverview } from './overview/ActivityOverview'

const ActivitiesOverviewPage: React.FC = () => {
  return (
    <AppLayout title={{ messageDescriptor: { id: 'activity.overview.title' } }}>
      <ActivityOverview />
    </AppLayout>
  )
}

export { ActivitiesOverviewPage }
