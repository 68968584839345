import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SectionItem, SectionItemAction } from '../../../shared/section'
import {
  IconFavorite,
  IconFavoriteBorder,
  IconKeyboardArrowRight,
} from '../../../shared/svgs'
import { Address } from '../types'
import { Size } from '../../../shared/section/SectionItem'
import { markAsFavorite, unmarkAsFavorite } from '../api'
import { overrideAddress } from '../db/addresses'
import { AlertsContext } from '../../../app/AlertsContext'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'

const AddressOverviewItem: React.FC<{
  address: Address
}> = ({ address }) => {
  const intl = useIntl()
  const { addErrorMessage } = useContext(AlertsContext)
  const [disabled, setDisabled] = useState<boolean>(false)

  return (
    <Link to={`/addresses/${address.id}`} className="block">
      <SectionItem size={Size.xs}>
        <SectionItemAction
          icon={address.favorite ? IconFavorite : IconFavoriteBorder}
          label={intl.formatMessage({
            id: address.favorite
              ? 'address.favorite.unmark'
              : 'address.favorite.mark',
          })}
          disabled={disabled}
          onClick={async () => {
            setDisabled(true)

            try {
              const updatedAddress = await (address.favorite
                ? unmarkAsFavorite(address.id)
                : markAsFavorite(address.id))

              overrideAddress(updatedAddress).finally(() => {
                setDisabled(false)
              })
            } catch (error: any) {
              addErrorMessage(extractErrorMessage(error))
              setDisabled(false)
            }
          }}
        />
        <div className="flex-1">
          <h1 className="mb-1">{address.name}</h1>
          <h2>{address.label}</h2>
        </div>
        <div>
          <IconKeyboardArrowRight className="w-6 h-6" />
        </div>
      </SectionItem>
    </Link>
  )
}

export { AddressOverviewItem }
