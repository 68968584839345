import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { TimeTrackingEdit } from './edit/TimeTrackingEdit'

const TimeTrackingEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: { id: 'project.overview.title' } }}>
      <TimeTrackingEdit id={id} />
    </AppLayout>
  )
}

export { TimeTrackingEditPage }
