import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { DBContainer } from './db/DBContext'
import { Internationalization } from './app/Internationalization'
import { Routes } from './app/Routes'
import { AuthChecker } from './auth'
import { AlertsContainer } from './app/AlertsContext'
import ScrollToTop from './app/ScrollToTop'

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <AuthChecker>
        <AlertsContainer>
          <DBContainer>
            <Internationalization>
              <Routes />
            </Internationalization>
          </DBContainer>
        </AlertsContainer>
      </AuthChecker>
    </Router>
  )
}

export default App
