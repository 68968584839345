import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FieldArray, FieldArrayRenderProps } from 'formik'
import { InputWithLabel } from '../../../shared/form'
import { Button } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { IconRemoveCircleOutline } from '../../../shared/svgs'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { SectionListAddButton } from '../../../shared/section/SectionListAddButton'
import { AddressFormFields } from './types'

const EditFields: React.FC<{
  index: number
  emailText: string
  removeAction?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  onBlur?: Function
}> = ({ index, emailText, removeAction, onBlur }) => {
  const intl = useIntl()
  return (
    <div className="flex">
      <InputWithLabel
        onBlur={onBlur}
        name={`emailAddresses.${index}.emailAddress`}
        placeholder={emailText}
        label={<FormattedMessage id="address.edit.email.label" />}
      />
      <Button onClick={removeAction} variant={Variant.secondary}>
        <IconRemoveCircleOutline
          height="24"
          title={intl.formatMessage({ id: 'shared.delete' })}
        />
      </Button>
    </div>
  )
}

const EmailEditSection: React.FC<{ data: AddressFormFields }> = ({ data }) => {
  const intl = useIntl()
  let arrayHelperBinding: FieldArrayRenderProps
  const [isFirst, setIsFirst] = useState<boolean>(
    data.emailAddresses.length === 0
  )

  return (
    <div className="mb-10 space-y-5">
      <Section>
        <SectionTitle>
          <FormattedMessage id="address.edit.title.email" />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <FieldArray
              name="emailAddresses"
              validateOnChange={false}
              render={(arrayHelpers) => {
                arrayHelperBinding = arrayHelpers

                return (
                  <div className="space-y-5">
                    {data.emailAddresses &&
                    !isFirst &&
                    data.emailAddresses.length > 0 ? (
                      data.emailAddresses.map((number, index) => (
                        <EditFields
                          index={index}
                          key={index}
                          emailText={intl.formatMessage({
                            id: 'address.edit.email.placeholder',
                          })}
                          removeAction={() => {
                            arrayHelpers.remove(index)
                          }}
                        />
                      ))
                    ) : (
                      <EditFields
                        index={0}
                        onBlur={() => setIsFirst(false)}
                        emailText={intl.formatMessage({
                          id: 'address.edit.email.placeholder',
                        })}
                      />
                    )}
                  </div>
                )
              }}
            />
          </SectionItemForm>
        </SectionList>
      </Section>
      <SectionListAddButton
        text={intl.formatMessage({ id: 'address.button.add.mail' })}
        onClick={() => {
          arrayHelperBinding.push({ emailAddress: '', label: '' })
        }}
      />
    </div>
  )
}

export { EmailEditSection }
