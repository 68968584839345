import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IconWarning } from '../svgs'
import { Section, SectionList, SectionTitle } from '../section'

const Error: React.FC<{ text: string | null }> = ({ text }) => {
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="shared.error.title" />
      </SectionTitle>
      <SectionList>
        <div className="flex flex-col justify-center items-center p-12 md:p-16 bg-gray-300 md:bg-transparent">
          <div className="p-6 md:p-8 text-gray-300 bg-gray-500 rounded-full">
            <IconWarning className="w-8 md:w-10 h-8 md:h-10 fill-current" />
          </div>
          <p className="mt-4 md:mt-6 text-md md:text-xl font-medium leading-normal text-center text-gray-500">
            {text}
          </p>
        </div>
      </SectionList>
    </Section>
  )
}

export { Error }
