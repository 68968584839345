import { Module, ModuleAccessControl } from '../../auth/types'
import { checkSyncPermissions } from '../../shared/utils/syncUtil'
import { getCostCenters } from './api'
import { clearCostCenters, overrideCostCenters } from './db/costCenters'

const syncCostCenter = async (
  modules: Array<ModuleAccessControl>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(modules, Module.timeTacking)) {
      return
    }

    const costCenter = await getCostCenters()
    await overrideCostCenters(costCenter)
  } catch (error: any) {
    if (error.response.status === 401) {
      await clearCostCenters()
    }
  }
}

export { syncCostCenter }
