import { useEffect, useState } from 'react'
import { Address, APIAddress } from '../types'
import { db } from '../../../db'
import { ListResponse, TABLE_ADDRESSES } from '../../../db/types'
import {
  attachTableListener,
  useGetById,
  useGetByIds,
  useGetPaginatedList,
} from '../../../db/hooks'
import { updateDatabase } from '../../../shared/utils/syncUtil'

const createSearchFiled = (address: APIAddress): Array<string> => {
  return address.name
    .toLocaleLowerCase()
    .split(' ')
    .filter((term) => term !== '')
}

const overrideAddresses = async (
  addresses: Array<APIAddress>
): Promise<void> => {
  const searchableAddresses = addresses.map((address: APIAddress) => {
    return {
      ...address,
      search: createSearchFiled(address),
    }
  })

  await updateDatabase(db, TABLE_ADDRESSES, searchableAddresses)
}

const overrideAddress = async (address: APIAddress): Promise<void> => {
  const searchableAddress: Address = {
    ...address,
    search: createSearchFiled(address),
  }

  await db.table(TABLE_ADDRESSES).put(searchableAddress)
}

const addAddress = async (address: APIAddress): Promise<void> => {
  const searchableAddress: Address = {
    ...address,
    search: createSearchFiled(address),
  }

  await db.table(TABLE_ADDRESSES).put(searchableAddress)
}

const clearAddresses = async (): Promise<void> => {
  await db.table(TABLE_ADDRESSES).clear()
}

const useGetAddresses = (search: string, page: number, size: number) =>
  useGetPaginatedList<Address>(TABLE_ADDRESSES, search, page, size)

const useGetFavoriteAddresses = (): ListResponse<Address> => {
  const [data, setData] = useState<Array<Address> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      db.table(TABLE_ADDRESSES)
        .filter((address: Address) => address.favorite)
        .sortBy('name')
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_ADDRESSES)
  }, [])

  return { data, error }
}

const useGetAddressById = (id: string) => {
  return useGetById<Address>(TABLE_ADDRESSES, id)
}

const useGetAddressesByIds = (ids: string[]) => {
  return useGetByIds<Address>(TABLE_ADDRESSES, ids)
}

const useGetRelatedAddresses = (
  address: Address | null
): ListResponse<Address> => {
  const [data, setData] = useState<Array<Address> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      const query = async (address: Address): Promise<Array<Address>> => {
        if (address.parentId !== null) {
          return db
            .table(TABLE_ADDRESSES)
            .where('id')
            .equalsIgnoreCase(address.parentId)
            .sortBy('name')
        }

        return db
          .table(TABLE_ADDRESSES)
          .where('parentId')
          .equalsIgnoreCase(address.id)
          .sortBy('name')
      }

      if (address) {
        query(address)
          .then((items) => {
            setData(items)
            setError(null)
          })
          .catch((error) => {
            setError(error)
            setData(null)
          })
      }
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_ADDRESSES)
  }, [address])

  return { data, error }
}

export {
  overrideAddresses,
  overrideAddress,
  useGetAddresses,
  useGetFavoriteAddresses,
  useGetAddressById,
  useGetRelatedAddresses,
  addAddress,
  clearAddresses,
  useGetAddressesByIds,
}
