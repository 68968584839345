import { Address } from '../types'

const checkForCompleteAddress = (data: Address) =>
  !(
    (data.address.street === null || data.address.street === '') &&
    (data.address.cap === null || data.address.cap === '') &&
    (data.address.city === null || data.address.city === '') &&
    (data.address.country === null || data.address.country === '')
  )

export { checkForCompleteAddress }
