import { ArticleDetail } from '../types'
import { updateDatabase } from '../../../shared/utils/syncUtil'
import { db } from '../../../db'
import { TABLE_ARTICLES } from '../../../db/types'
import { useGetById, useGetByIds, useGetPaginatedList } from '../../../db/hooks'

const overrideArticles = async (articles: Array<ArticleDetail>) => {
  const enrichedArticles = articles.map((article) => {
    return {
      ...article,
      search: article.name.toLocaleLowerCase().split(' ').concat(article.codes),
    }
  })

  await updateDatabase(db, TABLE_ARTICLES, enrichedArticles)
}

const useGetPaginatedArticles = (search: string, page: number, size: number) =>
  useGetPaginatedList<ArticleDetail>(TABLE_ARTICLES, search, page, size)

const useGetArticlesByIds = (ids: string[]) => {
  return useGetByIds<ArticleDetail>(TABLE_ARTICLES, ids)
}

const useGetArticleById = (id: string) =>
  useGetById<ArticleDetail>(TABLE_ARTICLES, id)

export {
  overrideArticles,
  useGetPaginatedArticles,
  useGetArticleById,
  useGetArticlesByIds,
}
