import React, { useState } from 'react'
import { Dialog } from '@reach/dialog'
import { ActivityArticle } from '../../types'
import { AddArticleView } from './steps/AddArticleView'
import { ArticleAmountView } from './steps/ArticleAmountView'

const ArticleDialog: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  isOpen: boolean
  onConfirm: (value: ActivityArticle) => void
  label: string
}> = ({ isOpen, onConfirm, onDismiss, label }) => {
  const [articleId, setArticleId] = useState<string>('')

  const amountStep = (id: string) => {
    setArticleId(id)
  }

  const saveArticle = (amount: number) => {
    onConfirm({
      articleId,
      count: amount,
    })
    setArticleId('')
  }

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={label}
      className="md:my-20 mx-auto md:w-full md:max-w-lg bg-white md:bg-transparent"
    >
      {articleId === '' && (
        <AddArticleView onDismiss={onDismiss} onConfirm={amountStep} />
      )}
      {articleId !== '' && (
        <ArticleAmountView
          onDismiss={() => setArticleId('')}
          onConfirm={saveArticle}
        />
      )}
    </Dialog>
  )
}

export { ArticleDialog }
