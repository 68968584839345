import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ProjectDetail } from './detail/ProjectDetail'

const ProjectDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: { id: 'project.detail.title' } }}>
      <ProjectDetail id={id} />
    </AppLayout>
  )
}

export { ProjectDetailPage }
