import React from 'react'
import { GuestLayout } from '../../../layouts/guest'
import { Login } from './Login'

const LoginPage: React.FC = () => {
  return (
    <GuestLayout
      title={{
        messageDescriptor: {
          id: 'guest.login.page.title',
        },
      }}
    >
      <Login />
    </GuestLayout>
  )
}

export { LoginPage }
