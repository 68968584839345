import { getProjects } from './api'
import { overrideProjects } from './db/projects'

const syncProjects = async (): Promise<void> => {
  try {
    const projects = await getProjects()
    await overrideProjects(projects)
  } catch (e) {}
}

export { syncProjects }
