import React from 'react'
import { Address } from '../../../address/types'
import { DialogSelectionListItem } from '../../../../shared/dialog/selection/DialogSelectionListItem'

const AddressDialogItem: React.FC<{
  address: Address
  onSelect: (addressId: string) => void
}> = ({ address, onSelect }) => {
  return (
    <DialogSelectionListItem<string> itemId={address.id} onSelect={onSelect}>
      {address.name}
    </DialogSelectionListItem>
  )
}

export { AddressDialogItem }
