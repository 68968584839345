import React, { useState } from 'react'
import { FormikProps } from 'formik'
import { FormattedMessage } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { ContentArea } from '../../../shared/content'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { useGetAddressById } from '../../address/db/addresses'
import { SectionListFormError } from '../../../shared/section/SectionListFormError'
import { ActivityFormFields } from './types'
import { FormDialogSelectItem } from './FormDialogSelectItem'
import { AddressDialog } from './addressDialog/AddressDialog'

const AddressFormSection: React.FC<{
  formik: FormikProps<ActivityFormFields>
}> = ({ formik }) => {
  const { values, setFieldValue } = formik
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const { data } = useGetAddressById(values.addressId)

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage id="activity.form.address.title" />
        </SectionTitle>
        <SectionList>
          {data ? (
            <AsyncView
              data={data}
              renderSuccess={(data) => (
                <FormDialogSelectItem onClick={() => setShowDialog(true)}>
                  {data.name}
                </FormDialogSelectItem>
              )}
            />
          ) : (
            <FormDialogSelectItem onClick={() => setShowDialog(true)}>
              <FormattedMessage id="activity.form.no.address" />
            </FormDialogSelectItem>
          )}
          <SectionListFormError name="addressId" />
        </SectionList>
      </Section>
      <AddressDialog
        onDismiss={() => setShowDialog(false)}
        isOpen={showDialog}
        onClear={() => {
          setFieldValue('addressId', '')
          setShowDialog(false)
        }}
        onConfirm={(value) => {
          setFieldValue('addressId', value)
          setShowDialog(false)
        }}
      />
    </ContentArea>
  )
}

export { AddressFormSection }
