import React, { useState } from 'react'
import ReachAlert from '@reach/alert'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import { useInterval } from '../packages/reactUseInterval'
import { IconClose, IconWarning } from '../shared/svgs'

const TEN_SECONDS_IN_MILLISECONDS = 10 * 1000

enum AlertType {
  ERROR,
}

type Alert = {
  id: string
  type: AlertType
  message: string
}

const AlertsContext = React.createContext<{
  addErrorMessage: (message: string) => void
}>({
  addErrorMessage: () => {},
})

const AlertMessage: React.FC<{
  alert: Alert
  onRemove: () => void
}> = ({ alert, onRemove }) => {
  const [delay, setDelay] = useState<number | null>(TEN_SECONDS_IN_MILLISECONDS)

  const remove = () => {
    setDelay(null)
    onRemove()
  }

  useInterval(() => {
    remove()
  }, delay)

  const colors = alert.type === AlertType.ERROR ? 'bg-red-50 text-red-500' : ''
  const Icon = alert.type === AlertType.ERROR ? IconWarning : null

  return (
    <ReachAlert
      className={classNames(
        'p-3 rounded-md flex items-stretch text-sm',
        colors
      )}
    >
      {Icon !== null && (
        <div className="mr-2">
          <Icon className="w-5 h-5 fill-current" />
        </div>
      )}
      <p className="flex-1 max-w-4xl leading-relaxed">{alert.message}</p>
      <div className="ml-2">
        <button onClick={remove}>
          <IconClose className="w-5 h-5 fill-current" />
        </button>
      </div>
    </ReachAlert>
  )
}

const AlertsContainer: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>([])

  return (
    <AlertsContext.Provider
      value={{
        addErrorMessage: (message: string) => {
          setAlerts((previousMessages) => {
            return previousMessages.concat([
              {
                id: uuid(),
                type: AlertType.ERROR,
                message,
              },
            ])
          })
        },
      }}
    >
      {children}
      <div className="flex fixed right-0 bottom-0 flex-col items-end mx-4 mb-4 space-y-2">
        {alerts.map((alert, index) => {
          return (
            <AlertMessage
              key={alert.id}
              alert={alert}
              onRemove={() => {
                setAlerts((previousAlerts) => {
                  let nextAlerts = previousAlerts.concat([])
                  nextAlerts.splice(index, 1)
                  return nextAlerts
                })
              }}
            />
          )
        })}
      </div>
    </AlertsContext.Provider>
  )
}

export { AlertsContext, AlertsContainer }
