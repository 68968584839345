import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { AddressEdit } from './edit/AddressEdit'

const AddressEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: { id: 'address.edit.title' } }}>
      <AddressEdit id={id} />
    </AppLayout>
  )
}

export { AddressEditPage }
