import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { useSearchAndPaginationQueryUrlParameters } from '../../../shared/pagination/routerPagination'
import {
  HeaderArea,
  HeaderRightArea,
  HeaderSearch,
  HeaderSmallAction,
  HeaderTitle,
} from '../../../shared/header'
import { IconSearch } from '../../../shared/svgs'
import { useGetActivities } from '../db/activities'
import { ContentArea } from '../../../shared/content'
import { LinkButton } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { ActionButtonSection } from '../../../shared/section/ActionButtonSection'
import { SectionListLoading } from '../../../shared/section/SectionListLoading'
import { ActivityGeneralOverviewList } from './ActivityGeneralOverviewList'

const ActivityOverview: React.FC = () => {
  const intl = useIntl()
  const { page, size, search, searchActions } =
    useSearchAndPaginationQueryUrlParameters()

  const [searchShow, setSearchShow] = useState<boolean>(search !== '')

  const startSearch = (): void => {
    setSearchShow(true)
  }

  const stopSearch = (): void => {
    setSearchShow(false)
    searchActions.clear()
  }

  const searching = searchShow && search !== ''

  const title = searching
    ? intl.formatMessage({ id: 'activity.overview.search.result' })
    : intl.formatMessage({ id: 'activity.overview.list' })

  const empty = searching
    ? intl.formatMessage({ id: 'activity.overview.search.empty' })
    : intl.formatMessage({ id: 'activity.overview.list.empty' })

  const { data } = useGetActivities(search, page, size)

  return (
    <>
      {searchShow ? (
        <HeaderSearch
          text={search}
          setText={searchActions.search}
          stopSearch={stopSearch}
          clearSearch={searchActions.clear}
        />
      ) : (
        <HeaderArea>
          <HeaderTitle>
            <FormattedMessage id="activity.overview.title" />
          </HeaderTitle>
          <HeaderRightArea>
            <HeaderSmallAction
              icon={IconSearch}
              label={intl.formatMessage({ id: 'activity.overview.search' })}
              onClick={startSearch}
            />
          </HeaderRightArea>
        </HeaderArea>
      )}
      <ContentArea>
        <AsyncView
          data={data}
          renderLoading={<SectionListLoading title={title} />}
          renderSuccess={(data) =>
            data && (
              <ActivityGeneralOverviewList
                title={title}
                empty={empty}
                data={data}
              />
            )
          }
        />
        <ActionButtonSection>
          <LinkButton to={`/activities/add`} variant={Variant.primary}>
            <span className="flex justify-center items-center px-6 h-full">
              <FormattedMessage id="activity.add.title" />
            </span>
          </LinkButton>
        </ActionButtonSection>
      </ContentArea>
    </>
  )
}

export { ActivityOverview }
