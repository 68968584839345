import React, { ReactElement } from 'react'
import { Dialog, DialogContent } from '@reach/dialog'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '../button'
import { Variant } from '../button/Button'

function LeaveDirtyFormDialog({
  isOpen,
  onDismiss,
  onConfirm,
}: {
  isOpen: boolean
  onDismiss: () => void
  onConfirm: () => void
}): ReactElement {
  const intl = useIntl()

  return (
    <Dialog
      isOpen={isOpen}
      className="md:my-64 mx-auto md:w-full md:max-w-lg bg-white md:bg-transparent"
      aria-label={intl.formatMessage({
        id: 'shared.leaveDirtyFormDialog.title',
      })}
    >
      <DialogContent
        aria-label={intl.formatMessage({
          id: 'shared.leaveDirtyFormDialog.title',
        })}
      >
        <div className="p-4 bg-white rounded-lg">
          <div className="text-xl font-bold">
            <FormattedMessage id="shared.leaveDirtyFormDialog.title" />
          </div>
          <p className="mt-3">
            <FormattedMessage id="shared.leaveDirtyFormDialog.text" />
          </p>
          <div className="mt-10 space-x-4 text-right">
            <Button variant={Variant.secondary} onClick={onDismiss}>
              <FormattedMessage id="shared.goBack" />
            </Button>
            <Button variant={Variant.primary} onClick={onConfirm}>
              <FormattedMessage id="shared.leaveDirtyFormDialog.leave" />
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export { LeaveDirtyFormDialog }
