import React, { ComponentType } from 'react'
import { IconProps } from '@aboutbits/react-material-icons/dist/types'
import { HeaderRightArea } from '../header'
import { DialogAction } from './DialogAction'

type DialogGenericActionProps = {
  icon: ComponentType<IconProps>
  label: string
  onClick: () => void
}

const DialogGenericAction: React.FC<DialogGenericActionProps> = ({
  icon: Icon,
  label,
  onClick,
}) => {
  return (
    <HeaderRightArea className="self-end md:self-start">
      <DialogAction
        label={label}
        onClick={onClick}
        className="-mr-4 md:mr-0 -mb-4 md:mb-0 text-white bg-gray-700 hover:bg-gray-600 rounded-full"
      >
        <Icon className="w-6 md:w-8 h-6 md:h-8 fill-current" title={label} />
      </DialogAction>
    </HeaderRightArea>
  )
}

export { DialogGenericAction }
