import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import {
  useGetActivityById,
  useGetActivityConfig,
  deleteActivity as deleteActivityFromDB,
} from '../db/activities'
import { ContentArea } from '../../../shared/content'
import { Error } from '../../../shared/error'
import { ActionButtonSection } from '../../../shared/section/ActionButtonSection'
import { DeleteButton } from '../../../shared/button/DeleteButton'
import { LinkButton, Variant } from '../../../shared/button/Button'
import { deleteActivity } from '../api'
import { AlertsContext } from '../../../app/AlertsContext'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { ActivityDetailHero } from './ActivityDetailHero'
import { ActivityDescriptionSection } from './ActivityDescriptionSection'
import { ActivityAddressContent } from './ActivityAddressContent'
import { ActivityProjectSection } from './ActivityProjectSection'
import { ActivityArticleSection } from './ActivityArticleSection'
import { ActivitySignaturSection } from './ActivitySignaturSection'
import { ActivityDeleteDialog } from './ActivityDeleteDialog'
import { ActivityImageSection } from './ActivityImageSection'

const ActivityDetail: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const history = useHistory()
  const { addErrorMessage } = useContext(AlertsContext)

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState<boolean>(false)

  const onActivityDelete = async () => {
    try {
      setDisableDeleteButton(true)

      await deleteActivity(id)

      await deleteActivityFromDB(id)

      history.goBack()
    } catch (error: any) {
      addErrorMessage(extractErrorMessage(error))
    } finally {
      setShowDeleteDialog(false)
      setDisableDeleteButton(false)
    }
  }

  const { data: activityData, error: activityError } = useGetActivityById(id)

  const { data: configData } = useGetActivityConfig()

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage({ id: 'activity.detail.back' })}
            onClick={() => history.goBack()}
          />
        }
      >
        {activityData ? (
          <HeaderTitle>{activityData.name}</HeaderTitle>
        ) : (
          <HeaderTitle>
            <FormattedMessage id="activity.detail.title" />
          </HeaderTitle>
        )}
      </HeaderArea>
      <ContentArea>
        {activityData && configData && (
          <AsyncView
            data={activityData}
            error={activityError}
            renderSuccess={(activityData) => (
              <ActivityDetailHero activity={activityData} config={configData} />
            )}
            renderError={(activityError) => (
              <Error text={activityError && activityError.message} />
            )}
          />
        )}
        {activityData?.description && (
          <AsyncView
            data={activityData}
            error={activityError}
            renderSuccess={(activityData) => (
              <ActivityDescriptionSection activity={activityData} />
            )}
            renderError={(activityError) => (
              <Error text={activityError && activityError.message} />
            )}
          />
        )}
        {activityData?.addressId && (
          <ActivityAddressContent addressId={activityData.addressId} />
        )}
        {activityData?.projectId && (
          <ActivityProjectSection projectId={activityData.projectId} />
        )}
        {activityData && activityData.articles?.length > 0 && (
          <ActivityArticleSection articleIds={activityData.articles} />
        )}
        {activityData && activityData.images?.length > 0 && (
          <ActivityImageSection images={activityData?.images} />
        )}
        {activityData && (
          <ActivitySignaturSection
            activityId={activityData.id}
            signature={activityData.signature}
          />
        )}
        <ActionButtonSection>
          <DeleteButton onClick={() => setShowDeleteDialog(true)} />
          <LinkButton to={`/activities/${id}/edit`} variant={Variant.primary}>
            <span className="flex justify-center items-center px-6 h-full">
              <FormattedMessage id="shared.edit" />
            </span>
          </LinkButton>
        </ActionButtonSection>
      </ContentArea>
      <ActivityDeleteDialog
        isOpen={showDeleteDialog}
        disableDelete={disableDeleteButton}
        onDismiss={() => setShowDeleteDialog(false)}
        onConfirm={onActivityDelete}
      />
    </>
  )
}

export { ActivityDetail }
