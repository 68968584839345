import React, { useState } from 'react'
import { AsyncView } from '@aboutbits/react-toolbox'
import { useIntl } from 'react-intl'
import { DialogSearchHeader } from '../../../../../shared/dialog/DialogSearchHeader'
import { ContentArea } from '../../../../../shared/content'
import { ArticleDialogList } from '../ArticleDialogList'
import { useSearchAndPaginationInMemory } from '../../../../../shared/pagination/inMemoryPagination'
import { useGetPaginatedArticles } from '../../../db/article'
import { DialogGenericAction } from '../../../../../shared/dialog/DialogGenericAction'
import { IconQrCodeScanner } from '../../../../../shared/svgs'
import { QrCodeView } from './QrCodeView'

const AddArticleView: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (value: string) => void
}> = ({ onConfirm, onDismiss }) => {
  const intl = useIntl()
  const [scanning, setScanning] = useState<boolean>(false)
  let { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  const searching = search !== ''

  const { data } = useGetPaginatedArticles(search, page, size)

  const title = searching
    ? intl.formatMessage({ id: 'activity.article.search.result' })
    : intl.formatMessage({ id: 'activity.article.title' })

  const empty = searching
    ? intl.formatMessage({ id: 'activity.article.search.empty' })
    : intl.formatMessage({ id: 'activity.article.list.empty' })

  return !scanning ? (
    <>
      <DialogSearchHeader
        onDismiss={onDismiss}
        title={intl.formatMessage({ id: 'activity.article.add' })}
        searchLabelId="activity.article.search.start"
        search={search}
        searchActions={searchActions}
        additionalAction={
          <DialogGenericAction
            icon={IconQrCodeScanner}
            label={intl.formatMessage({ id: 'activity.article.qr.title' })}
            onClick={() => {
              setScanning(!scanning)
            }}
          />
        }
      />
      <ContentArea>
        <AsyncView
          data={data}
          renderSuccess={(data) =>
            data && (
              <ArticleDialogList
                title={title}
                empty={empty}
                data={data}
                onSelect={onConfirm}
                paginationActions={paginationActions}
              />
            )
          }
        />
      </ContentArea>
    </>
  ) : (
    <QrCodeView
      onDismiss={() => setScanning(false)}
      onError={() => {
        setScanning(false)
      }}
      onScan={(result) => {
        if (result) {
          searchActions.search(result)
          setScanning(false)
        }
      }}
    />
  )
}

export { AddArticleView }
