import React from 'react'
import classnames from 'classnames'

const HeaderTitle: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return (
    <h1
      className={classnames(
        className,
        'flex-1 text-lg md:text-3xl font-medium'
      )}
    >
      {children}
    </h1>
  )
}

export { HeaderTitle }
