import React from 'react'
import { Project } from '../types'
import { DialogSelectionListItem } from '../../../shared/dialog/selection/DialogSelectionListItem'

const ProjectsSeletionItem: React.FC<{
  project: Project
  onSelect: (projectId: string) => void
}> = ({ project, onSelect }) => {
  return (
    <DialogSelectionListItem<string> itemId={project.id} onSelect={onSelect}>
      {project.name}
    </DialogSelectionListItem>
  )
}

export { ProjectsSeletionItem }
