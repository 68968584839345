import React, { useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { useHistory } from 'react-router-dom'
import { AddressFormFields } from '../forms/types'
import { AddressForm } from '../forms/AddressForm'
import { Address } from '../types'
import { createAddress } from '../api'
import { addAddress } from '../db/addresses'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { AlertsContext } from '../../../app/AlertsContext'

const AddressAddSection: React.FC = () => {
  const history = useHistory()
  const { addErrorMessage } = useContext(AlertsContext)

  const initialValues: AddressFormFields = {
    name: '',
    address: {
      street: '',
      cap: '',
      city: '',
      country: '',
      latitude: 0,
      longitude: 0,
    },
    phoneNumbers: [],
    emailAddresses: [],
  }

  const onSubmit = async (value: AddressFormFields): Promise<void> => {
    try {
      const newAddress: Address = value as Address

      newAddress.id = uuid()
      newAddress.parentId = null
      newAddress.favorite = false
      newAddress.label = ''

      const createdAddress = await createAddress(newAddress)

      await addAddress(createdAddress)

      history.goBack()
    } catch (error: any) {
      addErrorMessage(extractErrorMessage(error))
    }
  }

  return <AddressForm initialValues={initialValues} onSubmit={onSubmit} />
}

export { AddressAddSection }
