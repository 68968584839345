import React from 'react'
import classnames from 'classnames'

const DialogTitle: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return (
    <h1
      className={classnames(
        className,
        'flex flex-1 items-end my-5 md:my-0 lg:min-h-0 text-3xl md:text-2xl'
      )}
    >
      {children}
    </h1>
  )
}

export { DialogTitle }
