import React from 'react'
import { Form, Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Yup from 'yup'
import { DialogHeaderArea } from '../../../../../shared/dialog/DialogHeaderArea'
import { HeaderLeftArea } from '../../../../../shared/header'
import { DialogAction } from '../../../../../shared/dialog/DialogAction'
import { IconClose } from '../../../../../shared/svgs'
import { ContentArea } from '../../../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../../../shared/section'
import { InputWithLabel } from '../../../../../shared/form'
import { ActionButtonSection } from '../../../../../shared/section/ActionButtonSection'
import { CancelButton } from '../../../../../shared/form/button/CancelButton'
import { SubmitButton } from '../../../../../shared/form/button/SubmitButton'
import { DialogTitle } from '../../../../../shared/dialog/DialogTitle'
import { ButtonVariant } from '../../../../../shared/button'

const ArticleAmountView: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (amount: number) => void
  amount?: number
}> = ({ onConfirm, onDismiss, amount = 1 }) => {
  const intl = useIntl()

  const initialValue: {
    count: number
  } = {
    count: amount,
  }

  const schema = Yup.object().shape({
    count: Yup.number()
      .min(0, intl.formatMessage({ id: 'activity.article.not.negative' }))
      .required(intl.formatMessage({ id: 'shared.validation.required' })),
  })

  return (
    <>
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage({
              id: 'shared.goBack',
            })}
            onClick={onDismiss}
            className="bg-white hover:bg-gray-300 rounded-full"
          >
            <IconClose className="w-7 h-7 text-gray-700 fill-current" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">
          <FormattedMessage id="activity.article.amount.title" />
        </DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        <Formik
          initialValues={initialValue}
          onSubmit={(values) => {
            onConfirm(values.count)
          }}
          validationSchema={schema}
          validateOnChange={true}
        >
          <Form>
            <Section>
              <SectionTitle>
                <FormattedMessage id="activity.article.amount.title" />
              </SectionTitle>
              <SectionList>
                <SectionItemForm>
                  <InputWithLabel
                    id="count"
                    name="count"
                    type="number"
                    min={0}
                    step="any"
                    label={intl.formatMessage({
                      id: 'activity.article.amount',
                    })}
                  />
                </SectionItemForm>
              </SectionList>
            </Section>
            <ActionButtonSection className="pt-5 md:pt-5 pb-5 md:pb-5">
              <CancelButton
                variant={ButtonVariant.primaryLightOutlined}
                onClick={onDismiss}
              />
              <SubmitButton variant={ButtonVariant.primaryLight} />
            </ActionButtonSection>
          </Form>
        </Formik>
      </ContentArea>
    </>
  )
}

export { ArticleAmountView }
