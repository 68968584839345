import React, { ComponentType } from 'react'
import { ErrorMessage, FieldAttributes, useField } from 'formik'
import classNames from 'classnames'
import { FieldErrorMessage } from './FieldErrorMessage'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Input: React.FC<
  FieldAttributes<any> &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > & { icon?: ComponentType }
> = ({ icon: Icon, ...props }) => {
  const [field, meta] = useField(props)
  let iconCss = 'text-black'
  let inputCss = 'text-black placeholder-gray-700'
  let borderCss = 'border-black'

  if (meta.touched && meta.error) {
    iconCss = 'text-red-500'
    inputCss = 'text-black placeholder-gray-700'
    borderCss = 'border-red-500'
  }

  if (props.disabled) {
    iconCss = 'text-gray-500'
    inputCss = 'text-gray-500 placeholder-gray-500'
    borderCss = 'border-gray-500'
  }

  return (
    <div className="flex-col flex-1">
      <div
        className={classNames(
          'flex flex-row items-center border-b-2 border-black',
          borderCss
        )}
      >
        {Icon && (
          <Icon className={classNames('w-6 h-6 fill-current', iconCss)} />
        )}
        <input
          {...field}
          {...props}
          className={classNames(
            'flex-1 py-3 lg:py-2 px-5 bg-transparent text-black focus:outline-none border-none',
            inputCss
          )}
        />
      </div>
      <ErrorMessage name={props.name} component={FieldErrorMessage} />
    </div>
  )
}

export { Input }
