import React from 'react'
import { Redirect } from 'react-router-dom'
import { HomeLayout } from './HomeLayout'

const HomePage: React.FC = () => {
  if (window.innerWidth > 760) {
    return (
      <Redirect
        to={{
          pathname: '/projects',
        }}
      />
    )
  }

  return <HomeLayout />
}

export { HomePage }
