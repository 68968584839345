import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormikContext } from 'formik'
import { add, isBefore, sub } from 'date-fns'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { DateTimeInput } from '../../../shared/form/DateTimeInput'
import { ActivityFormFields } from './types'
import { getDurationAsString, getDurationFromString } from './dateFormatters'

const TimingFormSection: React.FC = () => {
  const intl = useIntl()

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage id="activity.form.time.title" />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <DateTimeInput
              name="startDate"
              label={intl.formatMessage({ id: 'activity.detail.date.start' })}
            />
            <DateTimeInput
              name="endDate"
              label={intl.formatMessage({ id: 'activity.detail.date.end' })}
            />
            <DateTimeInput
              name="dueDate"
              label={intl.formatMessage({ id: 'activity.detail.date.due' })}
            />
            <DurationField />
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

const DurationField: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ActivityFormFields>()

  const onChange = (value: string) => {
    const duration = getDurationFromString(value)
    if (duration !== null && values.startDate && values.endDate) {
      setFieldValue('endDate', add(new Date(values.startDate), duration))
    }
    if (duration !== null && values.startDate && values.endDate === '') {
      setFieldValue('endDate', add(new Date(values.startDate), duration))
    }
    if (duration !== null && values.endDate && values.startDate === '') {
      setFieldValue('startDate', sub(new Date(values.endDate), duration))
    }
  }

  return (
    <div className="flex-col flex-1">
      <div className="flex flex-row items-center border-b border-gray-500">
        <label className="w-full">
          <span className="block text-2xs">
            <FormattedMessage id="activity.detail.date.duration" />
          </span>
          <input
            type="time"
            onChange={(event) => onChange(event.target.value)}
            value={
              values.endDate &&
              values.startDate &&
              isBefore(new Date(values.startDate), new Date(values.endDate))
                ? getDurationAsString(values.endDate, values.startDate)
                : ''
            }
            className="flex-1 pt-0.5 pb-1 pl-0 w-full text-black placeholder:text-gray-700 bg-transparent border-none focus:outline-none"
          />
        </label>
      </div>
    </div>
  )
}

export { TimingFormSection }
