import React, { useEffect } from 'react'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import { SelectionInput } from '../../../shared/form/SelectionInput'
import { ActivityConfig, Category, Progress } from '../types'
import { ActivityFormFields } from './types'

const ConfigSelect: React.FC<{
  config: Array<ActivityConfig> | null
  formik: FormikProps<ActivityFormFields>
}> = ({ config, formik }) => {
  const intl = useIntl()

  const { values, handleChange, setFieldValue } = formik

  const setCategory = (categories: Array<Category> | undefined): void => {
    if (!categories) {
      setFieldValue('activityTypeId', '')
      setFieldValue('activityCategoryId', '')
    } else {
      setFieldValue('categories', categories)
      setFieldValue('progresses', '')
    }
  }

  const setProgress = (progresses: Array<Progress> | undefined): void => {
    if (!progresses) {
      setFieldValue('activityProgressId', '')
    } else {
      setFieldValue('progresses', progresses)
    }
  }

  useEffect(() => {
    const type = config?.find((type) => type.id === values.activityTypeId)
    setCategory(type?.categories)

    const category = type?.categories.find(
      (category) => category.id === values.activityCategoryId
    )
    setProgress(category?.progresses)
    // eslint-disable-next-line
  }, [])

  return config ? (
    <>
      <SelectionInput
        name="activityTypeId"
        onChange={(event) => {
          const value = event.target.value

          const type = config.find((type) => type.id === value)
          setCategory(type?.categories)

          setFieldValue('activityTypeId', value)
          setFieldValue('activityCategoryId', '')
          setFieldValue('activityProgressId', '')
        }}
        label={intl.formatMessage({
          id: 'activity.detail.hero.type',
        })}
      >
        <option value="" disabled>
          {intl.formatMessage({ id: 'activity.form.select.type' })}
        </option>
        {config.map((type) => (
          <option key={type.id} value={type.id}>
            {type.name}
          </option>
        ))}
      </SelectionInput>
      <SelectionInput
        onChange={(event) => {
          const value = event.target.value

          const category = values.categories.find(
            (category) => category.id === value
          )
          setProgress(category?.progresses)

          setFieldValue('activityCategoryId', value)
          setFieldValue('activityProgressId', '')
        }}
        name="activityCategoryId"
        label={intl.formatMessage({
          id: 'activity.detail.hero.category',
        })}
      >
        <option value="" disabled>
          {intl.formatMessage({ id: 'activity.form.select.category' })}
        </option>
        {values.categories &&
          values.categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
      </SelectionInput>
      <SelectionInput
        onChange={handleChange}
        name="activityProgressId"
        label={intl.formatMessage({
          id: 'activity.detail.hero.progress',
        })}
      >
        <option value="" disabled>
          {intl.formatMessage({ id: 'activity.form.select.progress' })}
        </option>
        {values.progresses &&
          values.progresses.map((progress) => (
            <option key={progress.id} value={progress.id}>
              {progress.name}
            </option>
          ))}
      </SelectionInput>
    </>
  ) : null
}

export { ConfigSelect }
