import React from 'react'
import { useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Dialog } from '@reach/dialog'
import { useGetAddresses } from '../../../address/db/addresses'
import { useSearchAndPaginationInMemory } from '../../../../shared/pagination/inMemoryPagination'
import { DialogSearchHeader } from '../../../../shared/dialog/DialogSearchHeader'
import { ContentArea } from '../../../../shared/content'
import { DialogGenericAction } from '../../../../shared/dialog/DialogGenericAction'
import { IconDelete } from '../../../../shared/svgs'
import { AddressDialogList } from './AddressDialogList'

const AddressDialog: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  isOpen: boolean
  onConfirm: (value: string) => void
  onClear: () => void
}> = ({ onDismiss, onConfirm, isOpen, onClear }) => {
  const intl = useIntl()
  const { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  const searching = search !== ''

  const { data } = useGetAddresses(search, page, size)

  const title = searching
    ? intl.formatMessage({ id: 'address.overview.search.result' })
    : intl.formatMessage({ id: 'address.overview.list' })

  const empty = searching
    ? intl.formatMessage({ id: 'address.overview.search.empty' })
    : intl.formatMessage({ id: 'address.overview.list.empty' })

  return (
    <Dialog
      isOpen={isOpen}
      aria-label={intl.formatMessage({
        id: 'address.overview.title',
      })}
      className="md:my-20 mx-auto md:w-full md:max-w-lg bg-white md:bg-transparent"
    >
      <DialogSearchHeader
        onDismiss={onDismiss}
        title={title}
        searchLabelId="address.overview.search.start"
        search={search}
        searchActions={searchActions}
        additionalAction={
          <DialogGenericAction
            icon={IconDelete}
            label={intl.formatMessage({ id: 'activity.form.remove.address' })}
            onClick={onClear}
          />
        }
      />
      <ContentArea>
        <AsyncView
          data={data}
          renderSuccess={(data) =>
            data && (
              <AddressDialogList
                title={title}
                empty={empty}
                onSelect={onConfirm}
                paginationActions={paginationActions}
                data={data}
              />
            )
          }
        />
      </ContentArea>
    </Dialog>
  )
}

export { AddressDialog }
