import React, { ComponentType, MouseEvent } from 'react'
import { IconProps } from '@aboutbits/react-material-icons/dist/types'

const SectionTitleWithAction: React.FC<{
  label: string
  buttonIcon: ComponentType<IconProps>
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void
}> = ({ children, buttonIcon: Icon, onClick, label }) => {
  return (
    <div className="md:p-5 py-5 px-3 text-sm font-medium md:font-bold">
      <div className="flex justify-between">
        <div>{children}</div>
        <button type="button" onClick={(evt) => onClick(evt)}>
          <Icon
            height="24"
            className="hover:text-gray-600 fill-current"
            title={label}
          />
        </button>
      </div>
    </div>
  )
}

export { SectionTitleWithAction }
