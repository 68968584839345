import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useInterval } from '../packages/reactUseInterval'
import { AuthContext } from '../auth/AuthContext'
import { getSyncDate, useGetSyncDate } from './tables/settings'
import { syncDB } from './index'

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000
const TWO_MINUTES_IN_MILLISECONDS = 2 * 60 * 1000

const DBContext = React.createContext<{
  lastSynced: Date | null
  refresh: () => void
}>({
  lastSynced: new Date(),
  refresh: () => {},
})

const DBContainer: React.FC = ({ children }) => {
  const { authenticated, data } = useContext(AuthContext)
  const [delay, setDelay] = useState<number | null>(null)
  const lastSynced = useGetSyncDate()

  const refresh = useCallback((): void => {
    syncDB(data?.modules)
      .then(() => {
        setDelay(FIVE_MINUTES_IN_MILLISECONDS)
      })
      .catch(() => {
        setDelay(TWO_MINUTES_IN_MILLISECONDS)
      })
  }, [data])

  useInterval(
    () => {
      refresh()
    },
    authenticated ? delay : null
  )

  useEffect(() => {
    if (authenticated) {
      getSyncDate()
        .then((date) => {
          const delta = new Date().getTime() - date.getTime()

          if (delta > FIVE_MINUTES_IN_MILLISECONDS) {
            refresh()
          } else {
            setDelay(FIVE_MINUTES_IN_MILLISECONDS - delta)
          }
        })
        .catch(() => {
          refresh()
        })
    }
  }, [authenticated, refresh])

  return (
    <DBContext.Provider
      value={{
        lastSynced,
        refresh: () => {
          setDelay(null)
          refresh()
        },
      }}
    >
      {children}
    </DBContext.Provider>
  )
}

export { DBContext, DBContainer }
