import { TABLE_TIME_TRACKING_BUFFER } from '../../../db/types'
import { db } from '../../../db/index'
import {
  NewTimeTrackingBufferEntry,
  TimeTrackingBufferAction,
  TimeTrackingBufferEntry,
  TimeTrackingBufferPayloads,
  TimeTrackingRecord,
} from '../types'

const setBufferEntry = async (
  entry: NewTimeTrackingBufferEntry<TimeTrackingBufferPayloads>
): Promise<void> => {
  await db.table(TABLE_TIME_TRACKING_BUFFER).add(entry)
}

const getAllBufferEntries = async (): Promise<
  TimeTrackingBufferEntry<TimeTrackingBufferPayloads>[]
> => {
  return db
    .table<TimeTrackingBufferEntry<TimeTrackingBufferPayloads>>(
      TABLE_TIME_TRACKING_BUFFER
    )
    .toArray()
}

const getAllBufferEntriesExceptIds = async (
  ids: number[]
): Promise<TimeTrackingBufferEntry<TimeTrackingBufferPayloads>[]> => {
  return db
    .table<TimeTrackingBufferEntry<TimeTrackingBufferPayloads>>(
      TABLE_TIME_TRACKING_BUFFER
    )
    .where('id')
    .noneOf(ids)
    .toArray()
}

const deleteBufferEntriesByIds = async (ids: number[]): Promise<void> => {
  await db.table(TABLE_TIME_TRACKING_BUFFER).bulkDelete(ids)
}

const deleteBufferEntriesByTimeTrackingIds = async (
  ids: string[]
): Promise<void> => {
  await db
    .table(TABLE_TIME_TRACKING_BUFFER)
    .where('timeTrackingId')
    .anyOf(ids)
    .delete()
}

async function addUpsertBufferEntry(
  timeTracking: TimeTrackingRecord
): Promise<void> {
  return setBufferEntry({
    timeTrackingId: timeTracking.id,
    timestamp: new Date(),
    action: TimeTrackingBufferAction.UPSERT,
    payload: timeTracking,
  })
}

async function addDeleteBufferEntry(timeTrackingId: string): Promise<void> {
  return setBufferEntry({
    timeTrackingId,
    timestamp: new Date(),
    action: TimeTrackingBufferAction.DELETE,
    payload: null,
  })
}

export {
  setBufferEntry,
  getAllBufferEntries,
  deleteBufferEntriesByIds,
  getAllBufferEntriesExceptIds,
  deleteBufferEntriesByTimeTrackingIds,
  addUpsertBufferEntry,
  addDeleteBufferEntry,
}
