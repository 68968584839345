import { db } from '../../../db'
import { TABLE_TIME_TRACKING_CONFIG } from '../../../db/types'

export const TIME_TRACKING_TIMESTAMP = 'sync_timestamp'

const setTimeTrackingSyncDate = async (date: string): Promise<void> => {
  await db
    .table(TABLE_TIME_TRACKING_CONFIG)
    .put({ id: TIME_TRACKING_TIMESTAMP, date })
}

const getTimeTrackingSyncDate = async (): Promise<string | null> => {
  const data = await db
    .table(TABLE_TIME_TRACKING_CONFIG)
    .get(TIME_TRACKING_TIMESTAMP)

  return data?.date || null
}

export { setTimeTrackingSyncDate, getTimeTrackingSyncDate }
