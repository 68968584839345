import { useFormikContext } from 'formik'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import classNames from 'classnames'
import { Button, ButtonVariant } from '../../button'

const SubmitButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: ButtonVariant }
> = ({
  children = <FormattedMessage id="shared.save" />,
  className,
  variant = ButtonVariant.primary,
  disabled,
  ...props
}) => {
  const formik = useFormikContext()

  return (
    <Button
      type="submit"
      variant={variant}
      disabled={formik.isSubmitting || disabled}
      className={classNames(
        'flex flex-1 md:flex-initial justify-center md:w-40',
        className
      )}
      {...props}
    >
      {children}
    </Button>
  )
}

export { SubmitButton }
