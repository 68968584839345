import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { Activity } from '../types'
import { ActivityForm } from '../form/ActivityForm'
import { ActivityFormFields } from '../form/types'
import { saveActivity } from '../api'
import { AlertsContext } from '../../../app/AlertsContext'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { overrideActivity } from '../db/activities'
import { formatDateForAPI } from '../form/dateFormatters'

const ActivityEditSection: React.FC<{ activity: Activity }> = ({
  activity,
}) => {
  const history = useHistory()
  const { addErrorMessage } = useContext(AlertsContext)

  const initialValue: ActivityFormFields = {
    ...activity,
    description: activity.description || '',
    categories: [],
    progresses: [],
    images: activity.images.map((image) => {
      return { imageId: image.imageId, description: image.description || '' }
    }),
    startDate: activity.startDate,
    endDate: activity.endDate,
    dueDate: activity.dueDate !== null ? activity.dueDate : '',
  }

  const onSubmit = async (
    values: ActivityFormFields,
    { resetForm }: FormikHelpers<ActivityFormFields>
  ): Promise<void> => {
    try {
      const apiActivity: Activity = {
        ...values,
        id: activity.id,
        signature: activity.signature,
        startDate: formatDateForAPI(values.startDate),
        endDate: formatDateForAPI(values.endDate),
        dueDate: values.dueDate ? formatDateForAPI(values.dueDate) : null,
      }

      const updatedActivity = await saveActivity(apiActivity)
      await overrideActivity(updatedActivity)

      resetForm({ values })

      history.goBack()
    } catch (error: any) {
      addErrorMessage(extractErrorMessage(error))
    }
  }

  return <ActivityForm initialValues={initialValue} onSubmit={onSubmit} />
}

export { ActivityEditSection }
