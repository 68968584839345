import { useEffect, useState } from 'react'
import { Activity, ActivityConfig } from '../types'
import { updateDatabase } from '../../../shared/utils/syncUtil'
import { db } from '../../../db'
import {
  ListResponse,
  TABLE_ACTIVITIES,
  TABLE_ACTIVITIES_CONFIG,
} from '../../../db/types'
import {
  attachTableListener,
  useGetById,
  useGetList,
  useGetPaginatedList,
} from '../../../db/hooks'

// This function generates a sort value for an activity, which allows us to have the activities due dates up front.
// Activities without due date are appended after in order of the name.
// To achieve that we set the "due date" of an activity without due date to 10 years in the future.
function generateSortValue(activity: Activity) {
  return (
    (activity.dueDate !== null
      ? activity.dueDate
      : new Date().getFullYear() + 99 + '-01-01T12:00:00') + activity.name
  )
}

const overrideActivities = async (
  activities: Array<Activity>
): Promise<void> => {
  const enrichedActivities = activities.map((activity) => {
    return {
      ...activity,
      search: activity.name.toLocaleLowerCase().split(' '),
      sort: generateSortValue(activity),
    } as Activity & { sort: string | null }
  })

  await updateDatabase(db, TABLE_ACTIVITIES, enrichedActivities)
}

const overrideActivity = async (activity: Activity): Promise<void> => {
  const enrichedActivity: Activity & { sort: string | null } = {
    ...activity,
    search: activity.name.toLocaleLowerCase().split(' '),
    sort: generateSortValue(activity),
  }

  await db.table(TABLE_ACTIVITIES).put(enrichedActivity)
}

const overrideActivityConfig = async (
  activityConfigs: Array<ActivityConfig>
): Promise<void> => {
  await updateDatabase(db, TABLE_ACTIVITIES_CONFIG, activityConfigs)
}

const deleteActivity = async (id: string): Promise<void> => {
  await db.table(TABLE_ACTIVITIES).delete(id)
}

const useGetActivitiesWithDueDate = (): ListResponse<Activity> => {
  const [data, setData] = useState<Array<Activity> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      return db
        .table(TABLE_ACTIVITIES)
        .filter((activity: Activity) => activity.dueDate !== null)
        .sortBy('dueDate')
        .then((data) => {
          setData(data)
          setError(null)
        })
        .catch((err) => {
          setData(null)
          setError(err)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_ACTIVITIES)
  }, [])

  return { data, error }
}

const useGetActivitiesForProject = (projectId: string) => {
  const [data, setData] = useState<Array<Activity> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      return db
        .table(TABLE_ACTIVITIES)
        .filter(
          (activity: Activity) =>
            activity.projectId !== null && activity.projectId === projectId
        )
        .sortBy('sort')
        .then((data) => {
          setData(data)
          setError(null)
        })
        .catch((err) => {
          setData(null)
          setError(err)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_ACTIVITIES)
  }, [projectId])

  return { data, error }
}

const useGetActivities = (search: string, page: number, size: number) =>
  useGetPaginatedList<Activity>(
    TABLE_ACTIVITIES,
    search,
    page,
    size,
    'sort',
    'asc'
  )

const useGetActivityById = (id: string) =>
  useGetById<Activity>(TABLE_ACTIVITIES, id)

const useGetActivityConfig = () =>
  useGetList<ActivityConfig>(TABLE_ACTIVITIES_CONFIG)

export {
  overrideActivities,
  overrideActivity,
  overrideActivityConfig,
  useGetActivities,
  deleteActivity,
  useGetActivityById,
  useGetActivitiesWithDueDate,
  useGetActivitiesForProject,
  useGetActivityConfig,
}
