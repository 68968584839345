import axios, { AxiosResponse } from 'axios'
import { axiosGet } from '../shared/utils/apiUtils'
import { Me } from './types'

type LogInParams = {
  username: string
  password: string
}

type LogInResponse = Array<string> // its an empty array

async function logIn({
  username,
  password,
}: LogInParams): Promise<LogInResponse> {
  const res: AxiosResponse<LogInResponse> = await axios.post<
    LogInParams,
    AxiosResponse<LogInResponse>
  >('auth/login', {
    username,
    password,
  })

  return res.data
}

async function logOut(): Promise<AxiosResponse> {
  return await axios.post<{}, AxiosResponse<{}>>('auth/logout')
}

async function getMe(): Promise<Me> {
  return await axiosGet<Me>('/auth/me')
}

export { logOut, logIn, getMe }
