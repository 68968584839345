import React from 'react'
import { AppLayout } from '../../layouts/app'
import { ProjectsOverview } from './overview/ProjectsOverview'

const ProjectsOverviewPage: React.FC = () => (
  <AppLayout title={{ messageDescriptor: { id: 'project.overview.title' } }}>
    <ProjectsOverview />
  </AppLayout>
)

export { ProjectsOverviewPage }
