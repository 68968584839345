import Dexie from 'dexie'
import { getMe } from '../auth/api'
import { syncTimeTracking } from '../domain/timeTracking/sync'
import { Me, ModuleAccessControl } from '../auth/types'
import { syncAddresses } from '../domain/address/sync'
import { syncCostCenter } from '../domain/costCenter/sync'
import { syncProjects } from '../domain/project/sync'
import {
  syncActivities,
  syncActivityConfigs,
  syncArticles,
} from '../domain/activity/sync'
import { setAuthMe, setAuthSyncDate, setSyncDate } from './tables/settings'
import {
  TABLE_ACTIVITIES,
  TABLE_ACTIVITIES_CONFIG,
  TABLE_ADDRESSES,
  TABLE_ADDRESS_GROUPS,
  TABLE_ARTICLES,
  TABLE_COST_CENTERS,
  TABLE_PROJECTS,
  TABLE_SETTINGS,
  TABLE_TIME_TRACKING_BUFFER,
  TABLE_TIME_TRACKING_CONFIG,
  TABLE_TIME_TRACKING_RECORDS,
  TABLE_NOTIFICATIONS,
  TABLE_USERS,
} from './types'

const db = new Dexie('zagzag')

db.version(1).stores({
  [TABLE_SETTINGS]: 'id',
  [TABLE_PROJECTS]: 'id,name,sort,*search',
  [TABLE_COST_CENTERS]: 'id,sort,*search',
  [TABLE_TIME_TRACKING_CONFIG]: 'id',
  [TABLE_TIME_TRACKING_RECORDS]: 'id,time',
  [TABLE_TIME_TRACKING_BUFFER]: '++id,timeTrackingId',
})

db.version(2).stores({
  [TABLE_ADDRESSES]: 'id,name,sort,*search',
})

db.version(3).stores({
  [TABLE_ACTIVITIES]: 'id,name,sort,*search',
  [TABLE_ACTIVITIES_CONFIG]: 'id',
})

db.version(4).stores({
  [TABLE_ARTICLES]: 'id,name,*search',
})

db.version(5).stores({
  [TABLE_NOTIFICATIONS]: 'id',
  [TABLE_ADDRESS_GROUPS]: 'id',
  [TABLE_USERS]: 'id',
})

db.version(6).stores({
  [TABLE_ADDRESSES]: 'id,name,*search,parentId',
})

const clearDB = (): Promise<void[]> => {
  return Promise.all(
    db.tables.map((table) => {
      return db.table(table.name).clear()
    })
  )
}

const syncDB = async (
  modules: Array<ModuleAccessControl> | undefined
): Promise<void> => {
  if (modules === undefined) return

  await Promise.all([
    syncProjects(),
    syncAddresses(modules),
    syncCostCenter(modules),
    syncTimeTracking(modules),
    syncActivities(modules),
    syncActivityConfigs(modules),
    syncArticles(modules),
  ])

  const date = new Date()
  await setSyncDate(date)
}

const syncAuth = async (): Promise<Me> => {
  const me = await getMe()

  const date = new Date()

  await setAuthMe(me)
  await setAuthSyncDate(date)

  return me
}

export { db, syncDB, syncAuth, clearDB }
