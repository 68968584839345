import React from 'react'
import { useIntl } from 'react-intl'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { SectionListVariant } from '../../../shared/section/SectionList'
import { Address } from '../types'
import { AddressOverviewItem } from './AddressOverviewItem'

const AddressOverviewFavorites: React.FC<{
  address: Array<Address> | null
}> = ({ address }) => {
  const intl = useIntl()

  if (address === null || address.length === 0) return null

  return (
    <Section>
      <SectionTitle>
        {intl.formatMessage({ id: 'address.overview.list.favorites' })}
      </SectionTitle>
      <SectionList variant={SectionListVariant.PRIMARY}>
        {address.map((address, index) => (
          <AddressOverviewItem address={address} key={index} />
        ))}
      </SectionList>
    </Section>
  )
}

export { AddressOverviewFavorites }
