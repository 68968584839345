import Axios, { AxiosError, AxiosResponse } from 'axios'
import set from 'lodash.set'

export type ErrorBody = {
  message?: string
}

export type FormErrorBody = ErrorBody & {
  errors: {
    [key: string]: string[]
  }
}

export type PagedResponse<T> = {
  items: T[]
  total: number
  perPage: number
  currentPage: number
}

type NestedErrorMessage = {
  [key: string]: NestedErrorMessage | string
}

export const joinFieldErrorMessages = (errors: {
  [key: string]: string[]
}): { [key: string]: NestedErrorMessage } => {
  if (!errors && typeof errors !== 'object') {
    return {}
  }

  return Object.keys(errors).reduce((acc, key) => {
    if (Array.isArray(errors[key])) {
      const errorString = errors[key].join(', ')
      set(acc, key, errorString)
    }
    return acc
  }, {})
}

export async function axiosGet<T>(path: string): Promise<T> {
  const res = await Axios.get(path)
  return res.data
}

export async function axiosGetWithCookies<T>(
  path: string,
  cookie: string
): Promise<AxiosResponse<T>> {
  const res = await Axios.get(path, {
    headers: {
      cookie,
    },
  })
  return res
}

export function extractErrorMessage(error: AxiosError): string {
  if (error.response && error.response.data.message) {
    return error.response.data.message
  }

  return 'Network Error'
}
