import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ActivitySignature } from './signature/ActivitySignature'

const ActivitySignaturePage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: { id: 'activity.sign.title' } }}>
      <ActivitySignature activityId={id} />
    </AppLayout>
  )
}

export { ActivitySignaturePage }
