import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { AsyncView } from '@aboutbits/react-toolbox'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { IconClose } from '../../../shared/svgs'
import { useGetAddressById } from '../db/addresses'
import { AddressEditSection } from './AddressEditSection'

const AddressEdit: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const history = useHistory()

  const { data } = useGetAddressById(id)

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage({ id: 'shared.goBack' })}
            onClick={() => history.goBack()}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage id="address.edit.title" />
        </HeaderTitle>
      </HeaderArea>
      {data && (
        <AsyncView
          data={data}
          renderSuccess={(data) => <AddressEditSection data={data} />}
        />
      )}
    </>
  )
}

export { AddressEdit }
