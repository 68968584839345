import { TABLE_COST_CENTERS } from '../../../db/types'
import { CostCenter } from '../types'
import { useGetList, useGetById, useGetPaginatedList } from '../../../db/hooks'
import { db } from '../../../db'
import { updateDatabase } from '../../../shared/utils/syncUtil'

const useGetAllCostCenters = () => useGetList<CostCenter>(TABLE_COST_CENTERS)

const useGetCostCenterById = (id: string) =>
  useGetById<CostCenter>(TABLE_COST_CENTERS, id)

const useGetCostCentersBySearch = (
  search: string,
  page: number,
  size: number
) =>
  useGetPaginatedList<CostCenter>(
    TABLE_COST_CENTERS,
    search,
    page,
    size,
    'sort'
  )

const overrideCostCenters = async (
  costCenters: Array<CostCenter>
): Promise<void> => {
  const searchableCostCenters = costCenters.map((costCenter) => {
    return {
      ...costCenter,
      search: costCenter.name.toLocaleLowerCase().split(' '),
    }
  })

  await updateDatabase(db, TABLE_COST_CENTERS, searchableCostCenters)
}

const clearCostCenters = async (): Promise<void> => {
  await db.table(TABLE_COST_CENTERS).clear()
}

export {
  useGetAllCostCenters,
  useGetCostCenterById,
  useGetCostCentersBySearch,
  overrideCostCenters,
  clearCostCenters,
}
