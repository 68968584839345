import { useEffect, useState } from 'react'
import { Project } from '../types'
import { ListResponse, TABLE_PROJECTS } from '../../../db/types'
import { db } from '../../../db'
import {
  useGetById,
  attachTableListener,
  useGetPaginatedList,
  useGetByIds,
} from '../../../db/hooks'
import { updateDatabase } from '../../../shared/utils/syncUtil'

const overrideProjects = async (projects: Project[]): Promise<void> => {
  const searchableProjects = projects.map((project) => {
    return {
      ...project,
      search: project.name.toLocaleLowerCase().split(' '),
    }
  })

  await updateDatabase(db, TABLE_PROJECTS, searchableProjects)
}

const overrideProject = async (project: Project): Promise<void> => {
  const searchableProject = {
    ...project,
    search: project.name.toLocaleLowerCase().split(' '),
  }

  await db.table(TABLE_PROJECTS).put(searchableProject)
}

const useGetFavoriteProjects = (): ListResponse<Project> => {
  const [data, setData] = useState<Project[] | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      db.table(TABLE_PROJECTS)
        .filter((project) => project.favorite)
        .toArray()
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_PROJECTS)
  }, [])

  return { data, error }
}

const useGetProjects = (search: string, page: number, size: number) =>
  useGetPaginatedList<Project>(TABLE_PROJECTS, search, page, size, 'sort')

const useGetProjectById = (id: string) =>
  useGetById<Project>(TABLE_PROJECTS, id)

const useGetProjectsByIds = (ids: string[]) => {
  return useGetByIds<Project>(TABLE_PROJECTS, ids)
}

const useGetProjectByAddressId = (id: string): ListResponse<Project> => {
  const [data, setData] = useState<Array<Project> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      return db
        .table(TABLE_PROJECTS)
        .filter((p: Project) => p.addressId === id)
        .sortBy('name')
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setError(error)
          setData(null)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, TABLE_PROJECTS)
  }, [id])

  return { data, error }
}

export {
  overrideProjects,
  overrideProject,
  useGetFavoriteProjects,
  useGetProjects,
  useGetProjectById,
  useGetProjectByAddressId,
  useGetProjectsByIds,
}
