import React from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import { calculatePagination } from '@aboutbits/pagination'
import { useIntl } from 'react-intl'
import {
  SectionPaginationContainer,
  SectionPaginationNextContent,
  SectionPaginationPagesList,
  SectionPaginationPagesListItem,
  SectionPaginationPreviousContent,
} from './SectionPagination'

const calculateLink = (
  routerUrl: string,
  routerQuery: string,
  page: number,
  size: number
): string => {
  const params = new URLSearchParams(routerQuery)

  params.set('page', String(page))
  params.set('size', String(size))

  return routerUrl + '?' + params.toString()
}

const SectionPaginationRouter: React.FC<{
  page: number
  size: number
  total: number
}> = ({ page, size, total }) => {
  const intl = useIntl()
  let { url: routerUrl } = useRouteMatch()
  let { search: routeQuery } = useLocation()

  const pagination = calculatePagination(page, size, total)

  if (pagination === null) return null

  const enabledLink = 'hover:underline'
  const disabledLink = 'text-gray-500 cursor-not-allowed pointer-events-none'
  const currentLink = 'font-bold'

  return (
    <SectionPaginationContainer>
      <Link
        to={calculateLink(
          routerUrl,
          routeQuery,
          pagination.previous.indexNumber,
          size
        )}
        aria-label={intl.formatMessage({ id: 'shared.pagination.previous' })}
        aria-disabled={pagination.previous.isDisabled}
        role="previous-link"
        className={classNames(
          'flex items-center',
          pagination.previous.isDisabled ? disabledLink : enabledLink
        )}
      >
        <SectionPaginationPreviousContent />
      </Link>

      <SectionPaginationPagesList>
        {pagination.pages.map((page) => {
          return (
            <SectionPaginationPagesListItem key={page.indexNumber}>
              <Link
                to={calculateLink(
                  routerUrl,
                  routeQuery,
                  page.indexNumber,
                  size
                )}
                aria-current={page.isCurrent ? 'page' : false}
                aria-label={intl.formatMessage(
                  { id: 'shared.pagination.page' },
                  { page: page.displayNumber }
                )}
                className={classNames(
                  enabledLink,
                  page.isCurrent ? currentLink : ''
                )}
              >
                {page.displayNumber}
              </Link>
            </SectionPaginationPagesListItem>
          )
        })}
      </SectionPaginationPagesList>

      <Link
        to={calculateLink(
          routerUrl,
          routeQuery,
          pagination.next.indexNumber,
          size
        )}
        aria-label={intl.formatMessage({ id: 'shared.pagination.next' })}
        aria-disabled={pagination.next.isDisabled}
        role="next-link"
        className={classNames(
          'flex items-center',
          pagination.next.isDisabled ? disabledLink : enabledLink
        )}
      >
        <SectionPaginationNextContent />
      </Link>
    </SectionPaginationContainer>
  )
}

export { SectionPaginationRouter }
