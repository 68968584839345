import Axios, { AxiosResponse } from 'axios'
import { axiosGet } from '../../shared/utils/apiUtils'
import { Address, APIAddress } from './types'

type AddressResponse = Array<APIAddress>

const getAddresses = (): Promise<AddressResponse> =>
  axiosGet('/v1/user/addresses')

async function markAsFavorite(id: string): Promise<APIAddress> {
  const res = await Axios.put<
    Record<string, unknown>,
    AxiosResponse<APIAddress>
  >(`/v1/user/addresses/${id}/favorite`, {})

  return res.data
}

async function unmarkAsFavorite(id: string): Promise<APIAddress> {
  const res = await Axios.delete<
    Record<string, unknown>,
    AxiosResponse<APIAddress>
  >(`/v1/user/addresses/${id}/favorite`, {})

  return res.data
}

async function updateAddress(address: Address): Promise<APIAddress> {
  const res = await Axios.put<
    Record<string, unknown>,
    AxiosResponse<APIAddress>
  >(`/v1/user/addresses/${address.id}`, address)

  return res.data
}

async function createAddress(address: Address): Promise<APIAddress> {
  const res = await Axios.post<
    Record<string, unknown>,
    AxiosResponse<APIAddress>
  >(`/v1/user/addresses/${address.id}`, address)

  return res.data
}

export {
  getAddresses,
  markAsFavorite,
  unmarkAsFavorite,
  updateAddress,
  createAddress,
}
export type { AddressResponse }
