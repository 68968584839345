import { v4 as uuid } from 'uuid'
import { format } from 'date-fns'
import { TimeTrackingRecord } from '../types'
import { useGetById, useGetList } from '../../../db/hooks'
import { TABLE_TIME_TRACKING_RECORDS } from '../../../db/types'
import { db } from '../../../db'

const useGetTimeTrackingRecords = () =>
  useGetList<TimeTrackingRecord>(TABLE_TIME_TRACKING_RECORDS, {
    sortBy: 'time',
    direction: 'asc',
  })

async function createTimeTrackingRecord(timeTracking: TimeTrackingRecord) {
  await db
    .table<TimeTrackingRecord>(TABLE_TIME_TRACKING_RECORDS)
    .put(timeTracking)
}

async function updateTimeTrackingRecord(timeTracking: TimeTrackingRecord) {
  await db
    .table<TimeTrackingRecord>(TABLE_TIME_TRACKING_RECORDS)
    .update(timeTracking.id, timeTracking)
}

async function bulkUpsertTimeTrackingRecords(records: TimeTrackingRecord[]) {
  await db
    .table<TimeTrackingRecord>(TABLE_TIME_TRACKING_RECORDS)
    .bulkPut(records)
}

async function deleteTimeTrackingRecord(timeTrackingId: string) {
  await db
    .table<TimeTrackingRecord>(TABLE_TIME_TRACKING_RECORDS)
    .delete(timeTrackingId)
}

async function deleteTimeTrackingRecords(ids: string[]) {
  await db
    .table<TimeTrackingRecord>(TABLE_TIME_TRACKING_RECORDS)
    .bulkDelete(ids)
}

async function duplicateTimeTrackingRecord(
  timeTrackingId: string,
  location: null | GeolocationPosition
): Promise<TimeTrackingRecord> {
  const record = await db
    .table<TimeTrackingRecord>(TABLE_TIME_TRACKING_RECORDS)
    .get(timeTrackingId)

  if (record) {
    const newRecord = {
      ...record,
      location: {
        latitude: location == null ? null : location.coords.latitude,
        longitude: location == null ? null : location.coords.longitude,
      },
      id: uuid(),
      time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
    }
    await db
      .table<TimeTrackingRecord>(TABLE_TIME_TRACKING_RECORDS)
      .add(newRecord)

    return newRecord
  } else {
    return Promise.reject()
  }
}

const useGetTimeTrackingRecordById = (id: string) =>
  useGetById<TimeTrackingRecord>(TABLE_TIME_TRACKING_RECORDS, id)

export {
  useGetTimeTrackingRecords,
  useGetTimeTrackingRecordById,
  createTimeTrackingRecord,
  deleteTimeTrackingRecord,
  deleteTimeTrackingRecords,
  duplicateTimeTrackingRecord,
  updateTimeTrackingRecord,
  bulkUpsertTimeTrackingRecords,
}
