import Dexie from 'dexie'
import { Module, ModuleAccessControl } from '../../auth/types'

type syncUtil = {
  [key: string]: any
}

function diffObject<T>(
  base: Array<T>,
  to: Array<T>,
  searchBy: string
): Array<T> {
  return base.filter(
    (bd: syncUtil) => !to.some((td: syncUtil) => bd[searchBy] === td[searchBy])
  )
}

async function updateDatabase<T>(
  db: Dexie,
  table: string,
  data: Array<T>,
  key = 'id'
) {
  await db.table(table).bulkPut(data)

  const dbData = await db.table(table).toArray()
  const toDelete = diffObject<T>(dbData, data, key).map((d: syncUtil) => d[key])

  await db.table(table).bulkDelete(toDelete)
}

function checkSyncPermissions(
  modules: Array<ModuleAccessControl>,
  checkFor: Module
): boolean {
  return modules.some((m) => m.module === checkFor)
}

export { diffObject, updateDatabase, checkSyncPermissions }
