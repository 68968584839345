import React from 'react'
import { Project } from '../../../project/types'
import { DialogSelectionListItem } from '../../../../shared/dialog/selection/DialogSelectionListItem'

const ProjectDialogItem: React.FC<{
  project: Project
  onSelect: (projectId: string, project: Project) => void
}> = ({ project, onSelect }) => {
  return (
    <DialogSelectionListItem<string>
      itemId={project.id}
      onSelect={(projectId: string) => onSelect(projectId, project)}
    >
      {project.name}
    </DialogSelectionListItem>
  )
}

export { ProjectDialogItem }
