import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormikProps } from 'formik'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { InputWithLabel } from '../../../shared/form'
import { ActivityConfig } from '../types'
import { ActivityFormFields } from './types'
import { ConfigSelect } from './ConfigSelect'

const GeneralFormSection: React.FC<{
  config: Array<ActivityConfig> | null
  formik: FormikProps<ActivityFormFields>
}> = ({ config, formik }) => {
  const intl = useIntl()

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage id="activity.form.general.title" />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <InputWithLabel
              type="text"
              name="name"
              placeholder={intl.formatMessage({
                id: 'activity.form.general.name',
              })}
              label={<FormattedMessage id="activity.form.general.name" />}
            />
            <InputWithLabel
              component="textarea"
              name="description"
              rows={5}
              placeholder={intl.formatMessage({
                id: 'activity.form.general.description',
              })}
              label={
                <FormattedMessage id="activity.form.general.description" />
              }
            />
            <ConfigSelect config={config} formik={formik} />
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

export { GeneralFormSection }
