import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useIntl, FormattedMessage } from 'react-intl'
import { AxiosError } from 'axios'
import { Input, FormGroup } from '../../../shared/form'
import { logIn } from '../../../auth/api'
import {
  FormErrorBody,
  joinFieldErrorMessages,
} from '../../../shared/utils/apiUtils'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { IconLock, IconPerson } from '../../../shared/svgs'
import { ErrorMessage } from '../../../shared/messages'

const Login: React.FC = () => {
  const intl = useIntl()
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null)

  const initialValues = {
    username: '',
    password: '',
  }

  const schema = Yup.object().shape({
    username: Yup.string().required(
      intl.formatMessage({ id: 'shared.validation.required' })
    ),
    password: Yup.string().required(
      intl.formatMessage({ id: 'shared.validation.required' })
    ),
  })

  const onSuccess = (): void => {
    window.location.pathname = '/'
  }

  const onSubmit = async (
    values: { username: string; password: string },
    { setSubmitting, setErrors }: any
  ): Promise<void> => {
    try {
      setApiErrorMessage(null)
      await logIn(values)
      onSuccess()
    } catch (error: any) {
      const { response }: AxiosError<FormErrorBody> = error
      if (response) {
        setErrors(joinFieldErrorMessages(response.data.errors))
        setApiErrorMessage(response.data.message || null)
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      <Form>
        <FormGroup>
          <Input
            autoCapitalize="none"
            icon={IconPerson}
            name="username"
            type="username"
            placeholder={intl.formatMessage({ id: 'shared.username' })}
          />
        </FormGroup>
        <FormGroup>
          <Input
            icon={IconLock}
            name="password"
            type="password"
            placeholder={intl.formatMessage({ id: 'shared.password' })}
          />
        </FormGroup>
        <SubmitButton className="mt-6 w-full">
          <FormattedMessage id="guest.login.btn" />
        </SubmitButton>
        {apiErrorMessage && (
          <ErrorMessage className="mt-10">{apiErrorMessage}</ErrorMessage>
        )}
      </Form>
    </Formik>
  )
}

export { Login }
