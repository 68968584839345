import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Project } from '../types'
import { SectionItem, SectionItemAction } from '../../../shared/section'
import {
  IconFavoriteBorder,
  IconFavorite,
  IconKeyboardArrowRight,
} from '../../../shared/svgs'
import { markAsFavorite, unmarkAsFavorite } from '../api'
import { overrideProject } from '../db/projects'
import { AlertsContext } from '../../../app/AlertsContext'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'

const ProjectsOverviewItem: React.FC<{
  project: Project
}> = ({ project }) => {
  const intl = useIntl()
  const { addErrorMessage } = useContext(AlertsContext)
  const [disabled, setDisabled] = useState<boolean>(false)

  return (
    <Link to={`/projects/${project.id}`} key={project.id} className="block">
      <SectionItem>
        <SectionItemAction
          icon={project.favorite ? IconFavorite : IconFavoriteBorder}
          label={intl.formatMessage({
            id: project.favorite
              ? 'project.favorite.unmark'
              : 'project.favorite.mark',
          })}
          disabled={disabled}
          onClick={async () => {
            setDisabled(true)
            try {
              const updatedProject = await (project.favorite
                ? unmarkAsFavorite(project.id)
                : markAsFavorite(project.id))

              await overrideProject(updatedProject)
            } catch (error: any) {
              addErrorMessage(extractErrorMessage(error))
              setDisabled(false)
            } finally {
              setDisabled(false)
            }
          }}
        />
        <div className="flex-1">{project.name}</div>
        <div>
          <IconKeyboardArrowRight className="w-6 h-6" />
        </div>
      </SectionItem>
    </Link>
  )
}

export { ProjectsOverviewItem }
