import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Error } from '../../../shared/error'
import { useGetFavoriteProjects, useGetProjects } from '../db/projects'
import { ContentArea } from '../../../shared/content'
import { useSearchAndPaginationInMemory } from '../../../shared/pagination/inMemoryPagination'
import { DialogSelectionLoading } from '../../../shared/dialog/selection/DialogSelectionLoading'
import { DialogSearchHeader } from '../../../shared/dialog/DialogSearchHeader'
import { ProjectsSelectionList } from './ProjectsSelectionList'
import { ProjectsSelectionListFavorites } from './ProjectSelectionListFavorites'

type Props = {
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (value: string) => void
}

const ProjectsSelection: React.FC<Props> = ({ onDismiss, onConfirm }) => {
  const intl = useIntl()
  const { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  const searching = search !== ''

  const { data: favoritesData } = useGetFavoriteProjects()

  const { data: listData, error: listError } = useGetProjects(
    search,
    page,
    size
  )

  const title = searching
    ? intl.formatMessage({ id: 'project.overview.search.result' })
    : intl.formatMessage({ id: 'project.overview.list.all' })

  const empty = searching
    ? intl.formatMessage({ id: 'project.overview.search.empty' })
    : intl.formatMessage({ id: 'project.overview.list.empty' })

  return (
    <>
      <DialogSearchHeader
        searchActions={searchActions}
        search={search}
        searchLabelId="project.overview.search.start"
        title={<FormattedMessage id="project.overview.title" />}
        onDismiss={onDismiss}
      />
      <ContentArea>
        {page === 1 && search === '' && (
          <AsyncView
            data={favoritesData}
            renderSuccess={(favoritesData) =>
              favoritesData && (
                <ProjectsSelectionListFavorites
                  data={favoritesData}
                  onSelect={onConfirm}
                />
              )
            }
          />
        )}
        <AsyncView
          data={listData}
          error={listError}
          renderLoading={<DialogSelectionLoading title={title} />}
          renderSuccess={(listData) =>
            listData && (
              <ProjectsSelectionList
                title={title}
                empty={empty}
                data={listData}
                onSelect={onConfirm}
                paginationActions={paginationActions}
              />
            )
          }
          renderError={(listError) => (
            <Error text={listError && listError.message} />
          )}
        />
      </ContentArea>
    </>
  )
}

export { ProjectsSelection }
