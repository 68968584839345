import React from 'react'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { Address } from '../types'
import { RelatedAddressItem } from './RelatedAddressItem'

const RelatedAddressesList: React.FC<{
  data: Array<Address>
  title: string
}> = ({ data, title }) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.map((d, index) => (
          <RelatedAddressItem data={d} key={index} />
        ))}
      </SectionList>
    </Section>
  )
}

export { RelatedAddressesList }
