import { AdditionalAddress } from '../types'
import { Address } from '../../address/types'

const additionalAddressesFilter = (
  additionalAddresses: Array<AdditionalAddress> | undefined,
  addresses: Array<Address> | null
): Array<AdditionalAddress> => {
  if (!additionalAddresses || !addresses) {
    return []
  }

  const addressStack: Array<AdditionalAddress> = []
  additionalAddresses.forEach((data) => {
    const addr = addresses.find((address) => address.id === data.addressId)

    if (addr) {
      addressStack.push({ ...data, name: addr.name })
    }
  })

  return addressStack
}

export { additionalAddressesFilter }
