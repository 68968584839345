import React, { ComponentType, useContext, useState } from 'react'
import classnames from 'classnames'
import { v4 as uuid } from 'uuid'
import { format } from 'date-fns'

import { FormattedMessage, useIntl } from 'react-intl'
import { LocationContext } from '@aboutbits/react-toolbox'
import {
  IconPlayArrow,
  IconStop,
  IconPause,
  IconAlarm,
} from '../../../shared/svgs'
import { IconProps } from '../../../shared/svgs/types'
import { syncTimeTracking } from '../sync'
import { RecordType } from '../types'
import { SelectProjectDialog } from '../shared/SelectProjectDialog'
import { SelectCostCenterDialog } from '../shared/SelectCostCenterDialog'
import { createTimeTrackingRecord } from '../db/records'
import { addUpsertBufferEntry } from '../db/buffer'
import { AuthContext } from '../../../auth/AuthContext'
import { ModuleAccessControl } from '../../../auth/types'
import styles from './TimeTrackingWidgetAction.module.css'

export enum Variant {
  inline,
  stacked,
}

type CustomActionProps = {
  variant?: Variant
}

async function createRecord(
  type: RecordType,
  projectId: string | null = null,
  costCenterId: string | null = null,
  location: GeolocationPosition | null,
  modules?: Array<ModuleAccessControl>
) {
  const timeTrackingEntry = {
    id: uuid(),
    time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
    location: {
      longitude: location == null ? null : location.coords.longitude,
      latitude: location == null ? null : location.coords.latitude,
    },
    type,
    projectId: projectId,
    costCenterId: costCenterId,
  }

  await createTimeTrackingRecord(timeTrackingEntry)
  await addUpsertBufferEntry(timeTrackingEntry)

  if (modules !== undefined) {
    syncTimeTracking(modules)
  }
}

const variantButtonStyle: Record<Variant, string> = {
  [Variant.inline]: 'flex flex-row justify-center',
  [Variant.stacked]: 'flex flex-col',
}

const variantIconStyle: Record<Variant, string> = {
  [Variant.inline]: 'm-2 p-2',
  [Variant.stacked]: 'm-4 p-4 lg:p-6',
}

const variantTextStyle: Record<Variant, string> = {
  [Variant.inline]: '',
  [Variant.stacked]: 'mt-4 md:h-11',
}

const TimeTrackingWidgetAction: React.FC<{
  Icon: ComponentType<IconProps>
  textId: string
  labelId: string
  onClick: () => void
  variant?: Variant
}> = ({ Icon, textId, labelId, onClick, variant = Variant.stacked }) => {
  const intl = useIntl()

  return (
    <button
      className={classnames(
        variantButtonStyle[variant],
        'flex-1 md:flex-auto items-center py-6 md:py-7 md:px-7 lg:px-9 md:max-w-1/3 md:bg-white md:rounded-lg hover:shadow-xl md:shadow-lg focus:outline-none'
      )}
      aria-label={intl.formatMessage({ id: labelId })}
      onClick={onClick}
    >
      <div className="rounded-full border border-primary-500">
        <div
          className={classnames(
            styles['shadow-icon'],
            variantIconStyle[variant],
            'overflow-hidden bg-primary-500 rounded-full'
          )}
        >
          <Icon className="w-6 h-6" />
        </div>
      </div>
      <div
        className={classnames(
          variantTextStyle[variant],
          'self-center px-4 md:px-0 md:w-26 text-md text-white md:text-gray-700'
        )}
      >
        <FormattedMessage id={textId} />
      </div>
    </button>
  )
}

const TimeTrackingWidgetActionStart: React.FC<CustomActionProps> = (props) => {
  const { data: moduleData } = useContext(AuthContext)
  const { location } = useContext(LocationContext)

  const start = () => {
    createRecord(
      RecordType.WORK_START,
      null,
      null,
      location,
      moduleData?.modules
    )
  }

  return (
    <TimeTrackingWidgetAction
      Icon={IconPlayArrow}
      textId="timeTracking.action.work_start.text"
      labelId="timeTracking.action.work_start.label"
      onClick={start}
      {...props}
    />
  )
}

const TimeTrackingWidgetActionStop: React.FC<CustomActionProps> = (props) => {
  const { data: moduleData } = useContext(AuthContext)
  const { location } = useContext(LocationContext)

  const stop = () => {
    createRecord(
      RecordType.WORK_STOP,
      null,
      null,
      location,
      moduleData?.modules
    )
  }

  return (
    <TimeTrackingWidgetAction
      Icon={IconStop}
      textId="timeTracking.action.work_stop.text"
      labelId="timeTracking.action.work_stop.label"
      onClick={stop}
      {...props}
    />
  )
}

const TimeTrackingWidgetActionPause: React.FC<CustomActionProps> = (props) => {
  const { data: moduleData } = useContext(AuthContext)
  const { location } = useContext(LocationContext)

  const pause = () => {
    createRecord(
      RecordType.WORK_PAUSE,
      null,
      null,
      location,
      moduleData?.modules
    )
  }

  return (
    <TimeTrackingWidgetAction
      Icon={IconPause}
      textId="timeTracking.action.work_pause.text"
      labelId="timeTracking.action.work_pause.label"
      onClick={pause}
      {...props}
    />
  )
}

enum SelectionState {
  closed,
  step_project,
  step_cost_center,
}

const TimeTrackingWidgetActionProjectCostCenter: React.FC<CustomActionProps> = (
  props
) => {
  const { data: moduleData } = useContext(AuthContext)
  const { location } = useContext(LocationContext)
  const [selectionState, setSelectionState] = useState<SelectionState>(
    SelectionState.closed
  )
  const [projectId, setProjectId] = useState<string | null>(null)

  return (
    <>
      <TimeTrackingWidgetAction
        Icon={IconAlarm}
        textId="timeTracking.action.work_project_costCenter.text"
        labelId="timeTracking.action.work_project_costCenter.label"
        onClick={() => {
          setSelectionState(SelectionState.step_project)
        }}
        {...props}
      />
      <SelectProjectDialog
        isOpen={selectionState === SelectionState.step_project}
        onDismiss={(): void => {
          setSelectionState(SelectionState.closed)
        }}
        onConfirm={(projectId): void => {
          setProjectId(projectId)
          setSelectionState(SelectionState.step_cost_center)
        }}
      />
      <SelectCostCenterDialog
        isOpen={selectionState === SelectionState.step_cost_center}
        onDismiss={(): void => {
          setSelectionState(SelectionState.step_project)
        }}
        onConfirm={async (costCenterId): Promise<void> => {
          try {
            createRecord(
              RecordType.PROJECT_COSTCENTER,
              projectId,
              costCenterId,
              location,
              moduleData?.modules
            )
          } finally {
            setSelectionState(SelectionState.closed)
          }
        }}
      />
    </>
  )
}

export {
  TimeTrackingWidgetAction,
  TimeTrackingWidgetActionStart,
  TimeTrackingWidgetActionStop,
  TimeTrackingWidgetActionPause,
  TimeTrackingWidgetActionProjectCostCenter,
}
