import React from 'react'
import { FormattedMessage, IntlProvider } from 'react-intl'
import classNames from 'classnames'
import { Translations } from '../shared/translations/types'
import { LinkButton } from '../shared/button'
import { IconWarning } from '../shared/svgs'
import { i18n } from '../config/i18n'
import { Variant } from '../shared/button/Button'
import styles from './Error.module.css'

const messages: Translations = {
  en: {
    notFoundMessage: 'This page does not exist.',
    notFoundButton: 'Go back home',
  },
  de: {
    notFoundMessage: 'Diese Seite existiert nicht.',
    notFoundButton: 'Zurück zur Startseite',
  },
  it: {
    notFoundMessage: 'This page does not exist.',
    notFoundButton: 'Go back home',
  },
}

const NotFoundError: React.FC = () => {
  const language = i18n.detectBrowserLanguage()

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div data-testid="network-error" className="flex flex-col h-screen">
        <div className="flex flex-col flex-1 justify-center text-center bg-primary-500 md:bg-white">
          <h1 className="text-3xl font-bold">Ups!</h1>
          <p className="mt-3 text-lg">
            <FormattedMessage id="notFoundMessage" />
          </p>
        </div>

        <div
          className={classNames(
            styles.lines,
            'flex-1 py-10 w-full flex justify-center items-center rounded-b-lg bg-primary-500 md:bg-white'
          )}
        >
          <div className="p-4 bg-primary-500 md:bg-white rounded-full border border-black md:border-primary-500">
            <div className="p-4 text-primary-500 md:text-black bg-black md:bg-primary-500 rounded-full">
              <IconWarning className="w-10 md:w-16 h-10 md:h-16 fill-current" />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            styles.button,
            'flex justify-center items-center'
          )}
        >
          <LinkButton
            to="/"
            className="w-full max-w-xs"
            variant={Variant.primary}
          >
            <FormattedMessage id="notFoundButton" />
          </LinkButton>
        </div>
      </div>
    </IntlProvider>
  )
}

export { NotFoundError }
