import React, { ReactNode } from 'react'
import { ErrorMessage, Field, FieldAttributes, useField } from 'formik'
import classNames from 'classnames'

import { FieldErrorMessage } from './FieldErrorMessage'

const InputWithLabel: React.FC<
  FieldAttributes<any> &
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > & { label: ReactNode }
> = ({ label, children, ...props }) => {
  const [field, meta] = useField(props)
  let inputCss = 'text-black placeholder-gray-700'
  let borderCss = 'border-black'

  if (meta.touched && meta.error) {
    inputCss = 'text-black placeholder-gray-700'
    borderCss = 'border-red-500'
  }

  if (props.disabled) {
    inputCss = 'text-gray-500 placeholder-gray-500'
    borderCss = 'border-gray-500'
  }

  return (
    <div className="flex-col flex-1">
      <div className={classNames('border-b border-gray-500', borderCss)}>
        <label className="block w-full">
          <span className="block text-2xs">{label}</span>
          <Field
            {...field}
            {...props}
            className={classNames(
              'block pl-0 pb-1 pt-0.5 bg-transparent text-black focus:outline-none w-full border-none',
              inputCss
            )}
          />
        </label>
      </div>
      <ErrorMessage name={props.name} component={FieldErrorMessage} />
    </div>
  )
}

export { InputWithLabel }
