import React from 'react'
import { useIntl } from 'react-intl'

import { HeaderRightArea } from '../header'
import { IconSearch } from '../svgs'
import { DialogAction } from './DialogAction'

const DialogStartSearchAction: React.FC<{
  startSearch: () => void
  label: string
}> = ({ label, startSearch }) => {
  const intl = useIntl()

  return (
    <HeaderRightArea className="self-end md:self-start">
      <DialogAction
        label={intl.formatMessage({
          id: 'project.overview.search.start',
        })}
        onClick={startSearch}
        className="-mr-4 md:mr-0 -mb-4 md:mb-0 text-white bg-gray-700 hover:bg-gray-600 rounded-full"
      >
        <IconSearch
          className="w-6 md:w-8 h-6 md:h-8 fill-current"
          title={label}
        />
      </DialogAction>
    </HeaderRightArea>
  )
}

export { DialogStartSearchAction }
