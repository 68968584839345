import React from 'react'
import { ErrorMessage } from 'formik'
import { SectionItem } from './index'

const SectionErrorItem: React.FC = ({ children }) => {
  return (
    <SectionItem>
      <div className="text-sm text-red-500">{children}</div>
    </SectionItem>
  )
}

const SectionListFormError: React.FC<{ name: string }> = ({ name }) => {
  return <ErrorMessage name={name} component={SectionErrorItem} />
}

export { SectionListFormError }
