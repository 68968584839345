import React from 'react'
import classnames from 'classnames'

const ActionButtonSection: React.FC<{
  className?: string
}> = ({ className, children }) => {
  return (
    <div
      className={classnames(
        'flex flex-col-reverse md:flex-row justify-center md:justify-end px-3 md:px-0 space-y-4 md:space-y-0 md:space-x-4 space-y-reverse',
        className
      )}
    >
      {children}
    </div>
  )
}

export { ActionButtonSection }
