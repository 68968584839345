import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { Activity } from '../types'

const ActivityDescriptionSection: React.FC<{ activity: Activity }> = ({
  activity,
}) => {
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="activity.detail.description" />
      </SectionTitle>
      <SectionList>
        <SectionItem>{activity.description}</SectionItem>
      </SectionList>
    </Section>
  )
}

export { ActivityDescriptionSection }
