import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { AddressDetail } from './details/AddressDetail'

const AddressDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: { id: 'address.detail.title' } }}>
      <AddressDetail id={id} />
    </AppLayout>
  )
}

export { AddressDetailPage }
