import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { AsyncView } from '@aboutbits/react-toolbox'
import { overrideProject, useGetProjectById } from '../db/projects'
import {
  HeaderArea,
  HeaderRightArea,
  HeaderSmallAction,
  HeaderTitle,
} from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { IconFavorite, IconFavoriteBorder } from '../../../shared/svgs'
import { markAsFavorite, unmarkAsFavorite } from '../api'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { AlertsContext } from '../../../app/AlertsContext'
import { ContentArea } from '../../../shared/content'
import { useGetActivitiesForProject } from '../../activity/db/activities'
import { ActionButtonSection } from '../../../shared/section/ActionButtonSection'
import { LinkButton } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { RelatedActivitiesList } from './RelatedActivitiesList'
import { ProjectAddress } from './ProjectAdress'
import { ProjectAdditionalAddresses } from './ProjectAdditionalAdresses'

const ProjectDetail: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const history = useHistory()
  const { addErrorMessage } = useContext(AlertsContext)
  const [disabled, setDisabled] = useState<boolean>(false)

  const onBack = (): void => {
    history.goBack()
  }

  const { data: projectData } = useGetProjectById(id)
  const { data: relatedActivitiesData } = useGetActivitiesForProject(id)

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage({ id: 'project.detail.back' })}
            onClick={onBack}
          />
        }
      >
        {projectData ? (
          <>
            <HeaderTitle>{projectData.name}</HeaderTitle>
            <HeaderRightArea>
              <HeaderSmallAction
                icon={projectData.favorite ? IconFavorite : IconFavoriteBorder}
                label={intl.formatMessage({
                  id: projectData.favorite
                    ? 'project.favorite.unmark'
                    : 'project.favorite.mark',
                })}
                disabled={disabled}
                onClick={async () => {
                  setDisabled(true)
                  try {
                    const updatedProject = await (projectData.favorite
                      ? unmarkAsFavorite(projectData.id)
                      : markAsFavorite(projectData.id))

                    overrideProject(updatedProject).finally(() => {
                      setDisabled(false)
                    })
                  } catch (error: any) {
                    addErrorMessage(extractErrorMessage(error))
                    setDisabled(false)
                  }
                }}
              />
            </HeaderRightArea>
          </>
        ) : (
          <HeaderTitle>
            {intl.formatMessage({ id: 'project.detail.title' })}
          </HeaderTitle>
        )}
      </HeaderArea>
      <ContentArea>
        {projectData?.addressId && (
          <ProjectAddress addressId={projectData.addressId} />
        )}
        {projectData?.additionalAddresses && (
          <ProjectAdditionalAddresses
            additionalAddresses={projectData.additionalAddresses}
          />
        )}
        {relatedActivitiesData && relatedActivitiesData.length > 0 && (
          <AsyncView
            data={relatedActivitiesData}
            renderSuccess={(relatedActivitiesData) => (
              <RelatedActivitiesList activities={relatedActivitiesData} />
            )}
          />
        )}
        <ActionButtonSection>
          <LinkButton
            to={`/activities/add?project=${projectData?.id}&address=${projectData?.addressId}`}
            variant={Variant.primaryOutlined}
          >
            <span className="flex justify-center items-center px-6 h-full">
              <FormattedMessage id="project.detail.addActivity.title" />
            </span>
          </LinkButton>
        </ActionButtonSection>
      </ContentArea>
    </>
  )
}

export { ProjectDetail }
