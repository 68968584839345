import React from 'react'
import { Project } from '../types'
import {
  Section,
  SectionList,
  SectionPaginationRouter,
  SectionTitle,
} from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconStore as ProjectIcon } from '../../../shared/svgs'
import { PaginatedData } from '../../../db/types'
import { ProjectsOverviewItem } from './ProjectsOverviewItem'

const ProjectsOverviewList: React.FC<{
  title: string
  empty: string
  data: PaginatedData<Project>
}> = ({ title, empty, data }) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.items.map((project, index) => (
          <ProjectsOverviewItem key={index} project={project} />
        ))}
        {data.items.length === 0 && <Empty icon={ProjectIcon} text={empty} />}
      </SectionList>
      <SectionPaginationRouter
        total={data.total}
        size={data.size}
        page={data.page}
      />
    </Section>
  )
}

export { ProjectsOverviewList }
