import React, { ReactNode } from 'react'
import { RecordType } from '../types'

const TimeTrackingTypeSwitch: React.FC<{
  cases: Partial<Record<RecordType, ReactNode>>
  fallback?: ReactNode
  value: RecordType
}> = ({ cases, fallback, value }) => {
  return <>{cases[value] || fallback}</>
}

export { TimeTrackingTypeSwitch }
