import React from 'react'
import classNames from 'classnames'
import { IconError } from '../svgs'

const ErrorMessage: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return (
    <div
      className={classNames(
        'flex items-start space-x-2 text-red-500',
        className
      )}
    >
      <IconError className="w-5 h-5 fill-current" />
      <p className="flex-1 text-sm leading-normal">{children}</p>
    </div>
  )
}

export { ErrorMessage }
