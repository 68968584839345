import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { useGetAddressById } from '../../address/db/addresses'
import { Size } from '../../../shared/section/SectionItem'
import { IconKeyboardArrowRight } from '../../../shared/svgs'
import { Error } from '../../../shared/error'

const ActivityAddressContent: React.FC<{
  addressId: string
}> = ({ addressId }) => {
  const { data, error } = useGetAddressById(addressId)

  return (
    <AsyncView
      error={error}
      data={data}
      renderSuccess={(data) =>
        data && (
          <Section>
            <SectionTitle>
              <FormattedMessage id="activity.detail.address" />
            </SectionTitle>
            <SectionList>
              <Link to={`/addresses/${data.id}`} className="block">
                <SectionItem size={Size.xs}>
                  <div className="flex-1">
                    <h2>{data.name}</h2>
                    {data.label ? (
                      <h3 className="mt-1 text-sm">{data.label}</h3>
                    ) : null}
                  </div>
                  <div>
                    <IconKeyboardArrowRight className="w-6 h-6" />
                  </div>
                </SectionItem>
              </Link>
            </SectionList>
          </Section>
        )
      }
      renderError={(error) => <Error text={error && error.message} />}
    />
  )
}

export { ActivityAddressContent }
