import React from 'react'
import QrReader from 'react-qr-scanner'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../../../shared/section'
import { HeaderLeftArea } from '../../../../../shared/header'
import { DialogAction } from '../../../../../shared/dialog/DialogAction'
import { IconClose } from '../../../../../shared/svgs'
import { DialogHeaderArea } from '../../../../../shared/dialog/DialogHeaderArea'
import { ContentArea } from '../../../../../shared/content'
import { DialogTitle } from '../../../../../shared/dialog/DialogTitle'

const QrCodeView: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onError: (error: string) => void
  onScan: (result: string) => void
}> = ({ onDismiss, onScan, onError }) => {
  const intl = useIntl()

  return (
    <>
      <DialogHeaderArea>
        <HeaderLeftArea>
          <DialogAction
            label={intl.formatMessage({
              id: 'shared.goBack',
            })}
            onClick={onDismiss}
            className="bg-white hover:bg-gray-300 rounded-full"
          >
            <IconClose className="w-7 h-7 text-gray-700 fill-current" />
          </DialogAction>
        </HeaderLeftArea>
        <DialogTitle className="text-white">
          <FormattedMessage id="activity.article.search.start" />
        </DialogTitle>
      </DialogHeaderArea>
      <ContentArea>
        <Section>
          <SectionTitle>
            <FormattedMessage id="activity.article.qr.title" />
          </SectionTitle>
          <SectionList>
            <SectionItem>
              <QrReader onError={onError} onScan={onScan} delay={100} />
            </SectionItem>
          </SectionList>
        </Section>
      </ContentArea>
    </>
  )
}

export { QrCodeView }
