import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  IconAlarm,
  IconFormatListBulleted,
  IconPermContactCalendar,
  IconStore,
} from '../../shared/svgs'
import { AppLayout } from '../../layouts/app'
import { AuthContext } from '../../auth/AuthContext'
import { Module } from '../../auth/types'
import { HeaderArea, HeaderTitle } from '../../shared/header'
import { HomeSection } from './HomeSection'

const HomeLayout: React.FC = () => {
  const intl = useIntl()
  const authContext = useContext(AuthContext)

  const modules = authContext.data === null ? [] : authContext.data.modules

  const hasModule = (searchedModule: Module): boolean => {
    const filteredModules = modules.filter((currentModule) => {
      return currentModule.module === searchedModule
    })

    return filteredModules.length > 0
  }

  return (
    <AppLayout title={{ messageDescriptor: { id: 'home.title' } }}>
      <HeaderArea>
        <HeaderTitle>
          <FormattedMessage id="home.title" />
        </HeaderTitle>
      </HeaderArea>
      <nav
        className="flex flex-col"
        aria-label={intl.formatMessage({ id: 'app.nav.accessibility.main' })}
      >
        <HomeSection
          active={true}
          titleId="home.nav.projects"
          subTitleId="home.nav.projects.sub"
          to="/projects"
          Icon={IconStore}
        />
        <HomeSection
          active={hasModule(Module.timeTacking)}
          titleId="home.nav.timeTracking"
          subTitleId="home.nav.timeTracking.sub"
          to="/time-tracking"
          Icon={IconAlarm}
        />
        <HomeSection
          active={hasModule(Module.activities)}
          titleId="home.nav.activities"
          subTitleId="home.nav.activities.sub"
          to="/activities"
          Icon={IconFormatListBulleted}
        />
        <HomeSection
          active={hasModule(Module.addresses)}
          titleId="home.nav.addresses"
          subTitleId="home.nav.addresses.sub"
          to="/addresses"
          Icon={IconPermContactCalendar}
        />
      </nav>
    </AppLayout>
  )
}

export { HomeLayout }
