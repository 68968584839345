import React, { ReactNode, useState } from 'react'
import { useIntl } from 'react-intl'

import { UseSearchQuery } from '../pagination/types'
import { HeaderLeftArea } from '../header'
import { IconClose } from '../svgs'
import { DialogSearch } from './DialogSearch'
import { DialogHeaderArea } from './DialogHeaderArea'
import { DialogAction } from './DialogAction'
import { DialogTitle } from './DialogTitle'
import { DialogStartSearchAction } from './DialogStartSearchAction'

type Props = {
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  title: ReactNode
  searchLabelId: string
  additionalAction?: ReactNode
} & UseSearchQuery

const DialogSearchHeader: React.FC<Props> = ({
  search,
  onDismiss,
  searchActions,
  searchLabelId,
  title,
  additionalAction,
}) => {
  const intl = useIntl()
  const [searchShow, setSearchShow] = useState<boolean>(search !== '')

  const startSearch = (): void => {
    setSearchShow(true)
  }

  const stopSearch = (): void => {
    setSearchShow(false)
    searchActions.clear()
  }

  return searchShow ? (
    <DialogSearch
      text={search}
      searchLabelId="costCenter.overview.search.start"
      title={title}
      setText={searchActions.search}
      stopSearch={stopSearch}
      onDismiss={onDismiss}
    />
  ) : (
    <DialogHeaderArea>
      <HeaderLeftArea>
        <DialogAction
          label={intl.formatMessage({
            id: searchLabelId,
          })}
          onClick={onDismiss}
          className="bg-white hover:bg-gray-300 rounded-full"
        >
          <IconClose
            className="w-7 h-7 text-gray-700 fill-current"
            title={intl.formatMessage({
              id: 'shared.close',
            })}
          />
        </DialogAction>
      </HeaderLeftArea>
      <DialogTitle className="text-white">{title}</DialogTitle>
      <div className="inline-flex flex-row self-end pr-2 md:pr-0 pb-2 md:pb-0">
        {additionalAction}
        <DialogStartSearchAction
          startSearch={startSearch}
          label={intl.formatMessage({
            id: searchLabelId,
          })}
        />
      </div>
    </DialogHeaderArea>
  )
}

export { DialogSearchHeader }
