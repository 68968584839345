import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import {
  overrideAddress,
  useGetAddressById,
  useGetRelatedAddresses,
} from '../db/addresses'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import {
  HeaderArea,
  HeaderRightArea,
  HeaderSmallAction,
  HeaderTitle,
} from '../../../shared/header'
import { IconFavorite, IconFavoriteBorder } from '../../../shared/svgs'
import { markAsFavorite, unmarkAsFavorite } from '../api'
import { AlertsContext } from '../../../app/AlertsContext'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { ContentArea } from '../../../shared/content'
import { Error } from '../../../shared/error'
import { useGetProjectByAddressId } from '../../project/db/projects'
import { Button } from '../../../shared/button'
import { LinkButton, Variant } from '../../../shared/button/Button'
import { ActionButtonSection } from '../../../shared/section/ActionButtonSection'
import { RelatedProjectsList } from './RelatedProjectsList'
import { RelatedAddressesList } from './RelatedAddressesList'
import { AddressDetailHero } from './AddressDetailHero'

const AddressDetail: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const history = useHistory()
  const { addErrorMessage } = useContext(AlertsContext)
  const [disabled, setDisabled] = useState<boolean>(false)

  const onBack = (): void => {
    history.goBack()
  }

  const { data: detailData, error: detailError } = useGetAddressById(id)
  const { data: relatedData, error: relatedError } =
    useGetRelatedAddresses(detailData)
  const { data: projectData, error: projectError } =
    useGetProjectByAddressId(id)

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage({ id: 'shared.goBack' })}
            onClick={onBack}
          />
        }
      >
        {detailData ? (
          <>
            <HeaderTitle>{detailData.name}</HeaderTitle>
            <HeaderRightArea>
              <HeaderSmallAction
                icon={detailData.favorite ? IconFavorite : IconFavoriteBorder}
                label={intl.formatMessage({
                  id: detailData.favorite
                    ? 'address.favorite.unmark'
                    : 'address.favorite.mark',
                })}
                disabled={disabled}
                onClick={async () => {
                  setDisabled(true)

                  try {
                    const updateAddress = await (detailData?.favorite
                      ? unmarkAsFavorite(detailData.id)
                      : markAsFavorite(detailData.id))

                    overrideAddress(updateAddress).finally(() => {
                      setDisabled(false)
                    })
                  } catch (error: any) {
                    addErrorMessage(extractErrorMessage(error))
                    setDisabled(false)
                  }
                }}
              />
            </HeaderRightArea>
          </>
        ) : (
          <HeaderTitle>
            {intl.formatMessage({ id: 'address.detail.title' })}
          </HeaderTitle>
        )}
      </HeaderArea>
      <ContentArea>
        <AsyncView
          data={detailData}
          error={detailError}
          renderSuccess={(detailData) => (
            <AddressDetailHero data={detailData} />
          )}
          renderError={(detailError) => (
            <Error text={detailError && detailError.message} />
          )}
        />
        {relatedData && relatedData.length > 0 && (
          <AsyncView
            data={relatedData}
            error={relatedError}
            renderSuccess={(relatedData) => (
              <RelatedAddressesList
                title={intl.formatMessage({
                  id: detailData?.parentId
                    ? 'address.detail.parent.addresses'
                    : 'address.detail.related.addresses',
                })}
                data={relatedData}
              />
            )}
            renderError={(relatedError) => (
              <Error text={relatedError && relatedError.message} />
            )}
          />
        )}
        {projectData && projectData.length > 0 && (
          <AsyncView
            data={projectData}
            error={projectError}
            renderSuccess={(projectData) => (
              <RelatedProjectsList
                data={projectData}
                title={intl.formatMessage({
                  id: 'address.detail.related.projects',
                })}
              />
            )}
            renderError={(projectError) => (
              <Error text={projectError && projectError.message} />
            )}
          />
        )}
        <ActionButtonSection>
          <LinkButton
            to={`/activities/add?address=${detailData?.id}`}
            variant={Variant.primaryOutlined}
          >
            <span className="flex justify-center items-center px-6 h-full">
              <FormattedMessage id="address.detail.addActivity" />
            </span>
          </LinkButton>
          <Button
            className="md:float-right w-full md:w-auto"
            onClick={() => history.push(`/addresses/${id}/edit`)}
            variant={Variant.primary}
          >
            <p className="mx-auto md:mx-10">
              {intl.formatMessage({ id: 'shared.edit' })}
            </p>
          </Button>
        </ActionButtonSection>
      </ContentArea>
    </>
  )
}

export { AddressDetail }
