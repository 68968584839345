import React, { ComponentType } from 'react'
import { IconArrowBack } from '../svgs'
import { IconProps } from '../svgs/types'
import { HeaderLeftArea } from './HeaderLeftArea'
import { HeaderLargeAction } from './index'

const HeaderBackAction: React.FC<{
  label: string
  onClick: () => void
  icon?: ComponentType<IconProps>
}> = ({ label, onClick, icon = IconArrowBack }) => {
  return (
    <HeaderLeftArea>
      <HeaderLargeAction icon={icon} label={label} onClick={onClick} />
    </HeaderLeftArea>
  )
}

export { HeaderBackAction }
