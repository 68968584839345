import React, { useEffect, useRef, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { IconClose, IconArrowBack } from '../svgs'
import { HeaderRightArea, HeaderLeftArea } from '../header/index'
import { DialogAction } from './DialogAction'
import { DialogHeaderArea } from './DialogHeaderArea'
import { DialogTitle } from './DialogTitle'

const DialogSearch: React.FC<{
  text: string
  title: ReactNode
  searchLabelId: string
  setText: (string: string) => void
  stopSearch: () => void
  onDismiss: () => void
}> = ({ text, title, searchLabelId, setText, stopSearch, onDismiss }) => {
  const intl = useIntl()
  const searchInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (searchInput.current !== null) {
      searchInput.current.focus()
    }
  }, [])

  return (
    <DialogHeaderArea>
      <HeaderLeftArea className="md:hidden">
        <DialogAction
          label={intl.formatMessage({
            id: searchLabelId,
          })}
          onClick={onDismiss}
          className="bg-white rounded-full"
        >
          <IconClose
            className="w-7 h-7 text-gray-700 fill-current"
            title={intl.formatMessage({
              id: 'shared.close',
            })}
          />
        </DialogAction>
      </HeaderLeftArea>
      <DialogTitle className="md:hidden text-white">{title}</DialogTitle>
      <div className="flex flex-1 pt-1.5 md:pt-0">
        <div className="md:hidden mr-4">
          <button
            aria-label={intl.formatMessage({ id: 'shared.search.back' })}
            onClick={stopSearch}
            className="text-white hover:text-white focus:outline-none"
          >
            <IconArrowBack className="w-6 h-6 fill-current" />
          </button>
        </div>
        <div className="flex flex-1 md:p-0 py-1 px-3 bg-gray-300 md:bg-transparent rounded-full">
          <input
            ref={searchInput}
            value={text}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
              setText(ev.target.value)
            }
            placeholder={intl.formatMessage({
              id: 'shared.search.placeholder',
            })}
            className="flex-1 w-full text-base md:text-2xl placeholder-gray-300 text-gray-700 md:text-white bg-transparent border-none outline-none"
          />
        </div>
      </div>
      <HeaderRightArea className="hidden md:block">
        <DialogAction
          label={intl.formatMessage({
            id: 'shared.search.back',
          })}
          onClick={stopSearch}
          className="text-white bg-gray-700 hover:bg-gray-600 rounded-full"
        >
          <IconClose
            className="w-7 h-7 fill-current"
            title={intl.formatMessage({
              id: 'shared.close',
            })}
          />
        </DialogAction>
      </HeaderRightArea>
    </DialogHeaderArea>
  )
}

export { DialogSearch }
