import React from 'react'
import styles from './CircleBouncer.module.css'

const CircleBouncer: React.FC = () => {
  return (
    <div className={styles.spinner}>
      <div className={styles.doublebounce1}></div>
      <div className={styles.doublebounce2}></div>
    </div>
  )
}

export { CircleBouncer }
