import Dexie, { PromiseExtended } from 'dexie'

/**
 * This function makes checks if ALL search terms match a multi value index.
 * The implementation has been taken from: https://github.com/dexie/Dexie.js/issues/281#issuecomment-229228163
 */
export function matchAllWithPrefix(
  db: Dexie,
  tableName: string,
  indexName: string,
  prefixes: string[],
  offset: number,
  limit: number,
  sortBy: string,
  sortDirection: 'asc' | 'desc'
): PromiseExtended {
  return db.transaction('r', db.table(tableName), function* () {
    // Parallel search for all prefixes - just select resulting primary keys
    const results = yield Dexie.Promise.all(
      prefixes.map((prefix) =>
        db
          .table(tableName)
          .where(indexName)
          .startsWithIgnoreCase(prefix)
          .primaryKeys()
      )
    )

    // Intersect result set of primary keys
    const reduced = results.reduce((a: string[], b: string[]) => {
      const set = new Set(b)
      return a.filter((k) => set.has(k))
    })

    const count = reduced.length

    // Finally select entire documents from intersection
    let dbQuery = db
      .table(tableName)
      .where(':id')
      .anyOf(reduced)
      .offset(offset)
      .limit(limit)

    return yield sortDirection === 'desc'
      ? dbQuery.sortBy(sortBy).then((items) => ({
          items,
          count,
        }))
      : dbQuery
          .reverse()
          .sortBy(sortBy)
          .then((items) => ({
            items,
            count,
          }))
  })
}
