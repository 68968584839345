import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import {
  HeaderArea,
  HeaderRightArea,
  HeaderSearch,
  HeaderSmallAction,
  HeaderTitle,
} from '../../../shared/header'
import { IconSearch } from '../../../shared/svgs'
import { ContentArea } from '../../../shared/content'
import { useSearchAndPaginationQueryUrlParameters } from '../../../shared/pagination/routerPagination'
import { useGetAddresses, useGetFavoriteAddresses } from '../db/addresses'
import { Button } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'
import { ActionButtonSection } from '../../../shared/section/ActionButtonSection'
import { SectionListLoading } from '../../../shared/section/SectionListLoading'
import { AddressOverviewList } from './AddressOverviewList'
import { AddressOverviewFavorites } from './AddressOverviewFavorites'

const AddressOverview: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const { page, search, size, searchActions } =
    useSearchAndPaginationQueryUrlParameters()

  const [showSearch, setShowSearch] = useState<boolean>(search !== '')

  const startSearch = (): void => {
    setShowSearch(true)
  }

  const stopSearch = (): void => {
    setShowSearch(false)
    searchActions.clear()
  }

  const searching = showSearch && search !== ''

  const title = searching
    ? intl.formatMessage({ id: 'address.overview.search.result' })
    : intl.formatMessage({ id: 'address.overview.list' })

  const empty = searching
    ? intl.formatMessage({ id: 'address.overview.search.empty' })
    : intl.formatMessage({ id: 'address.overview.list.empty' })

  const { data: favoriteData } = useGetFavoriteAddresses()

  const { data: listData } = useGetAddresses(search, page, size)

  return (
    <>
      {showSearch ? (
        <HeaderSearch
          text={search}
          setText={searchActions.search}
          stopSearch={stopSearch}
          clearSearch={searchActions.clear}
        />
      ) : (
        <HeaderArea>
          <HeaderTitle>
            <FormattedMessage id="address.overview.title" />
          </HeaderTitle>
          <HeaderRightArea>
            <HeaderSmallAction
              icon={IconSearch}
              label={intl.formatMessage({
                id: 'address.overview.search.start',
              })}
              onClick={startSearch}
            />
          </HeaderRightArea>
        </HeaderArea>
      )}
      <ContentArea>
        {page === 1 && search === '' && (
          <AsyncView
            data={favoriteData}
            renderSuccess={(favoriteData) =>
              favoriteData && (
                <AddressOverviewFavorites address={favoriteData} />
              )
            }
          />
        )}
        <AsyncView
          data={listData}
          renderLoading={<SectionListLoading title={title} />}
          renderSuccess={(listData) =>
            listData && (
              <AddressOverviewList
                title={title}
                empty={empty}
                data={listData}
              />
            )
          }
        />
        <ActionButtonSection>
          <Button
            className="inline-flex justify-center w-full md:w-auto h-10"
            onClick={() => history.push('/addresses/add')}
            variant={Variant.primary}
          >
            <span className="mx-auto md:mx-5">
              <FormattedMessage id="address.button.add" />
            </span>
          </Button>
        </ActionButtonSection>
      </ContentArea>
    </>
  )
}

export { AddressOverview }
