import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'

import { TimeTrackingRecord, RecordType } from '../types'
import {
  Section,
  SectionList,
  SectionTitle,
  SectionItemForm,
} from '../../../shared/section'
import { InputWithLabel } from '../../../shared/form'

import { ContentArea } from '../../../shared/content'
import { syncTimeTracking } from '../sync'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SelectProject } from '../shared/SelectProject'
import { SelectCostCenter } from '../shared/SelectCostCenter'
import { updateTimeTrackingRecord } from '../db/records'
import { addUpsertBufferEntry } from '../db/buffer'
import { ActionButtonSection } from '../../../shared/section/ActionButtonSection'
import { AuthContext } from '../../../auth/AuthContext'
import { ModuleAccessControl } from '../../../auth/types'
import { LeaveDirtyFormPrompt } from '../../../shared/form/LeaveDirtyFormPrompt'

type TimeTrackingFormValues = {
  time: string
  date: string
  projectId: string | null
  costCenterId: string | null
}

async function updateRecord(
  old: TimeTrackingRecord,
  { date, time, ...values }: TimeTrackingFormValues,
  modules?: Array<ModuleAccessControl>
) {
  const milliseconds = old.time.substr(16)
  const updatedDateTime = date + 'T' + time + milliseconds

  const timeTrackingEntry = {
    ...old,
    time: updatedDateTime,
    ...values,
  }

  await updateTimeTrackingRecord(timeTrackingEntry)
  await addUpsertBufferEntry(timeTrackingEntry)
  if (modules !== undefined) {
    await syncTimeTracking(modules)
  }
}

const TimeTrackingEditForm: React.FC<{ data: TimeTrackingRecord }> = ({
  data,
}) => {
  const intl = useIntl()
  const history = useHistory()
  const { data: moduleData } = useContext(AuthContext)

  const cancel = () => {
    history.goBack()
  }

  const initialValues: TimeTrackingFormValues = {
    time: data.time.substr(11, 5),
    date: data.time.substr(0, 10),
    projectId: data.projectId,
    costCenterId: data.costCenterId,
  }

  const showProjectAndCostCenterInputs =
    data.type === RecordType.PROJECT_COSTCENTER

  const schema = Yup.object().shape({
    time: Yup.string()
      .matches(/^\d\d:\d\d$/, {
        message: intl.formatMessage({ id: 'shared.validation.time' }),
      })
      .required(intl.formatMessage({ id: 'shared.validation.required' })),
    date: Yup.string()
      .matches(/^\d\d\d\d-\d\d-\d\d$/, {
        message: intl.formatMessage({ id: 'shared.validation.date' }),
      })
      .required(intl.formatMessage({ id: 'shared.validation.required' })),
    ...(showProjectAndCostCenterInputs
      ? {
          projectId: Yup.string().required(
            intl.formatMessage({ id: 'shared.validation.required' })
          ),
          costCenterId: Yup.string().required(
            intl.formatMessage({ id: 'shared.validation.required' })
          ),
        }
      : {}),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: TimeTrackingFormValues, { resetForm }) => {
        updateRecord(data, values, moduleData?.modules)

        resetForm({ values })

        history.goBack()
      }}
      validationSchema={schema}
      validateOnChange={false}
    >
      <Form>
        <LeaveDirtyFormPrompt />
        <ContentArea>
          <Section>
            <SectionTitle>
              <FormattedMessage id="timeTracking.edit.time.title" />
            </SectionTitle>

            <SectionList>
              <SectionItemForm>
                <InputWithLabel
                  type="time"
                  name="time"
                  placeholder={intl.formatMessage({
                    id: 'timeTracking.edit.time.label',
                  })}
                  label={<FormattedMessage id="timeTracking.edit.time.label" />}
                />
                <InputWithLabel
                  type="date"
                  name="date"
                  placeholder={intl.formatMessage({
                    id: 'timeTracking.edit.date.label',
                  })}
                  label={<FormattedMessage id="timeTracking.edit.date.label" />}
                />
              </SectionItemForm>
            </SectionList>
          </Section>
          {showProjectAndCostCenterInputs && (
            <Section>
              <SectionTitle>
                <FormattedMessage id="timeTracking.edit.costCenter.title" />
              </SectionTitle>

              <SectionList>
                <SectionItemForm>
                  <SelectProject projectIdFieldName="projectId" />
                  <SelectCostCenter costCenterIdFieldName="costCenterId" />
                </SectionItemForm>
              </SectionList>
            </Section>
          )}
          <ActionButtonSection>
            <CancelButton onClick={cancel} />
            <SubmitButton />
          </ActionButtonSection>
        </ContentArea>
      </Form>
    </Formik>
  )
}

export { TimeTrackingEditForm }
