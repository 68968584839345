import React, { ComponentType, useContext } from 'react'
import classNames from 'classnames'
import {
  FormattedDate,
  FormattedTime,
  FormattedMessage,
  useIntl,
} from 'react-intl'
import { Dialog } from '@reach/dialog'
import { LinkProps, NavLink } from 'react-router-dom'
import { LogoLeft } from '../../shared/logo'
import { Button, ButtonVariant } from '../../shared/button'
import { logout } from '../../auth/logout'
import {
  IconFormatListBulleted,
  IconPermContactCalendar,
  IconClose,
  IconLock,
  IconPowerSettingsNew,
  IconStore,
  IconSync,
  IconAlarm,
} from '../../shared/svgs'
import { Footer } from '../shared/Footer'
import { AuthContext } from '../../auth/AuthContext'
import { IconProps } from '../../shared/svgs/types'
import { Module } from '../../auth/types'
import { DBContext } from '../../db/DBContext'
import { APP_VERSION } from '../../config/env'
import { useMenuState, useMenuToggle } from './MenuContext'

export interface MenuLinkProps extends LinkProps {
  text: string
  icon: ComponentType<IconProps>
  locked: boolean
}

const MenuLink: React.FC<MenuLinkProps> = ({
  text,
  icon: Icon,
  locked,
  ...props
}) => {
  return locked ? (
    <li>
      <div className="flex flex-row items-center py-1 px-4">
        <Icon className="mr-4 w-6 h-6 fill-current" />
        <div className="flex-1">{text}</div>
        <div className="p-1 text-gray-300 bg-black rounded-full">
          <IconLock className="w-4 h-4 fill-current" />
        </div>
      </div>
    </li>
  ) : (
    <li>
      <NavLink
        {...props}
        className="flex flex-row items-center py-1 px-4 text-sm bg-white hover:bg-gray-300 focus:bg-gray-300 rounded-full outline-none fill-current"
        activeClassName="bg-primary-500 hover:bg-primary-700 focus:bg-primary-700"
      >
        <Icon className="mr-4 w-6 h-6 fill-current" />
        <div className="flex-1">{text}</div>
      </NavLink>
    </li>
  )
}

const Menu: React.FC<{ className?: string }> = ({ className }) => {
  const intl = useIntl()
  const authContext = useContext(AuthContext)
  const { lastSynced, refresh } = useContext(DBContext)

  const modules = authContext.data === null ? [] : authContext.data.modules

  const hasModule = (searchedModule: Module): boolean => {
    const filteredModules = modules.filter((currentModule) => {
      return currentModule.module === searchedModule
    })

    return filteredModules.length > 0
  }

  return (
    <div
      className={classNames(
        className,
        'flex flex-col w-lg shadow-lg rounded-r-lg bg-white'
      )}
    >
      <LogoLeft className="p-8" />
      <div className="border-t-2 border-gray-300" />
      <nav
        aria-label={intl.formatMessage({ id: 'app.nav.accessibility.main' })}
        className="flex flex-col flex-1 justify-between py-8 px-4"
      >
        <ul className="space-y-6">
          <MenuLink
            text={intl.formatMessage({ id: 'app.nav.projects' })}
            icon={IconStore}
            to="/projects"
            locked={false}
          />
          <MenuLink
            text={intl.formatMessage({ id: 'app.nav.timeTracking' })}
            icon={IconAlarm}
            to="/time-tracking"
            locked={!hasModule(Module.timeTacking)}
          />
          <MenuLink
            text={intl.formatMessage({ id: 'app.nav.addresses' })}
            icon={IconPermContactCalendar}
            to="/addresses"
            locked={!hasModule(Module.addresses)}
          />
          <MenuLink
            text={intl.formatMessage({ id: 'app.nav.activities' })}
            icon={IconFormatListBulleted}
            to="/activities"
            locked={!hasModule(Module.activities)}
          />
        </ul>
        <div>
          <Button
            variant={ButtonVariant.secondary}
            className="flex flex-row items-center mx-4 mt-16 text-sm"
            onClick={logout}
          >
            <IconPowerSettingsNew className="mr-4 w-6 h-6" />
            <FormattedMessage id="app.nav.logout" />
          </Button>
          <Button
            variant={ButtonVariant.secondary}
            className="flex flex-row items-center mx-4 mt-8 text-sm"
            onClick={refresh}
          >
            <IconSync className="mr-4 w-6 h-6" />
            <FormattedMessage id="app.nav.refresh" />
          </Button>
          <div className="mt-8 text-xs leading-snug text-gray-500">
            <FormattedMessage id="app.nav.sync" />
            {': '}
            {lastSynced ? (
              <>
                <FormattedDate value={lastSynced} />{' '}
                <FormattedTime value={lastSynced} />
              </>
            ) : (
              '-'
            )}
          </div>
          <div className="mt-2 text-xs leading-snug text-gray-500">
            Version: {APP_VERSION}
          </div>
        </div>
      </nav>
    </div>
  )
}

const DesktopMenu: React.FC = () => {
  return (
    <div className="hidden md:flex flex-col min-h-screen">
      <Menu className="flex-1 mt-8" />
      <Footer className="my-6 ml-2" />
    </div>
  )
}

const MobileMenu: React.FC = () => {
  const intl = useIntl()
  const menuState = useMenuState()
  const menuToggle = useMenuToggle()

  return (
    <Dialog
      isOpen={menuState}
      onDismiss={menuToggle}
      aria-label={intl.formatMessage({ id: 'app.nav.accessibility.main' })}
    >
      <div className="flex flex-row items-stretch min-h-screen">
        <Menu />
        <div className="flex items-center ml-6">
          <button
            className="p-2 bg-white hover:bg-gray-300 focus:bg-gray-300 rounded-full"
            onClick={menuToggle}
            aria-label={intl.formatMessage({ id: 'app.nav.close' })}
          >
            <IconClose className="w-8 h-8" />
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export { Menu, MobileMenu, DesktopMenu }
