import classNames from 'classnames'
import React from 'react'
import styles from './LoadingBlock.module.css'

const LoadingBlock: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={classNames('rounded', styles.loading, className)}>
      &nbsp;
    </div>
  )
}

export { LoadingBlock }
