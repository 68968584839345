import React from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { ActionButtonSection } from '../../../shared/section/ActionButtonSection'
import { LeaveDirtyFormPrompt } from '../../../shared/form/LeaveDirtyFormPrompt'
import { EmailEditSection } from './EmailEditSection'
import { PhoneEditSection } from './PhoneEditSection'
import { AddressFormFields } from './types'
import { GeneralEditSection } from './GeneralEditSection'

const AddressForm: React.FC<{
  initialValues: AddressFormFields
  onSubmit: (
    values: AddressFormFields,
    formikHelpers: FormikHelpers<AddressFormFields>
  ) => void
}> = ({ initialValues, onSubmit }) => {
  const intl = useIntl()
  const history = useHistory()

  const requiredField = intl.formatMessage({ id: 'shared.validation.required' })
  const schema = Yup.object().shape({
    name: Yup.string().required(requiredField),
    address: Yup.object().shape({
      street: Yup.string().nullable(),
      city: Yup.string().nullable(),
      cap: Yup.string().nullable(),
      country: Yup.string().nullable(),
    }),
    phoneNumbers: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required(requiredField),
        phoneNumber: Yup.string().required(requiredField),
      })
    ),
    emailAddresses: Yup.array().of(
      Yup.object().shape({
        emailAddress: Yup.string()
          .email(intl.formatMessage({ id: 'address.edit.email.error' }))
          .required(requiredField),
      })
    ),
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
      validationSchema={schema}
      validateOnChange={false}
    >
      {({ values }) => (
        <Form>
          <LeaveDirtyFormPrompt />
          <GeneralEditSection />
          <PhoneEditSection data={values} />
          <EmailEditSection data={values} />
          <ActionButtonSection className="pb-5 md:pb-5">
            <CancelButton onClick={() => history.goBack()} />
            <SubmitButton />
          </ActionButtonSection>
        </Form>
      )}
    </Formik>
  )
}

export { AddressForm }
