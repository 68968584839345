import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { InputWithLabel } from '../../../../shared/form'
import { ActivityImage } from '../../types'
import { IconDelete } from '../../../../shared/svgs'
import { SectionItem } from '../../../../shared/section'

const ImageFormItem: React.FC<{
  image: ActivityImage
  onDelete: () => void
  name: string
  url: string
}> = ({ image, name, url, onDelete }) => {
  const intl = useIntl()

  return (
    <SectionItem>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row justify-between items-start mb-8">
          <div className="w-2/6 h-32 bg-gray-500 rounded-lg">
            <img
              src={url}
              className="flex object-cover overflow-hidden items-center w-full h-full text-center rounded-lg"
              alt={image.description}
            />
          </div>
          <button
            type="button"
            aria-label={intl.formatMessage({
              id: 'activity.form.image.delete',
            })}
            onClick={onDelete}
          >
            <IconDelete className="h-5" />
          </button>
        </div>

        <InputWithLabel
          type="text"
          name={name}
          rows={5}
          label={<FormattedMessage id="activity.form.image.description" />}
          placeholder={intl.formatMessage({
            id: 'activity.form.image.description',
          })}
        />
      </div>
    </SectionItem>
  )
}

export { ImageFormItem }
