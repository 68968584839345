import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedDate } from 'react-intl'
import { Activity } from '../types'
import { SectionItem } from '../../../shared/section'
import { IconKeyboardArrowRight } from '../../../shared/svgs'
import { Size } from '../../../shared/section/SectionItem'

const ActivityOverviewItem: React.FC<{ activity: Activity; size?: Size }> = ({
  activity,
  size = Size.xs,
}) => {
  return (
    <Link to={`/activities/${activity.id}`} className="block">
      <SectionItem size={size}>
        <div className="flex-1">
          <h2>{activity.name}</h2>
          {activity.projectName ? (
            <h3 className="mt-1 text-sm">{activity.projectName}</h3>
          ) : null}
        </div>
        <div className="inline-flex items-center">
          {activity.dueDate && (
            <span className="px-3">
              <FormattedDate value={activity.dueDate} />
            </span>
          )}
          <IconKeyboardArrowRight className="w-6 h-6" />
        </div>
      </SectionItem>
    </Link>
  )
}

export { ActivityOverviewItem }
