import React, { ComponentType } from 'react'
import { IconProps } from '../svgs/types'

const Empty: React.FC<{ icon: ComponentType<IconProps>; text: string }> = ({
  icon: Icon,
  text,
}) => {
  return (
    <div className="flex flex-col justify-center items-center p-12 md:p-16 bg-gray-300 md:bg-transparent">
      <div className="p-6 md:p-8 text-gray-300 bg-gray-500 rounded-full">
        <Icon className="w-8 md:w-10 h-8 md:h-10 fill-current" />
      </div>
      <p className="mt-4 md:mt-6 text-md md:text-xl font-medium leading-normal text-center text-gray-500">
        {text}
      </p>
    </div>
  )
}

export { Empty }
