import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { LocationContext } from '@aboutbits/react-toolbox'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { CancelButton } from '../../../shared/form/button/CancelButton'
import { SubmitButton } from '../../../shared/form/button/SubmitButton'
import { ActionButtonSection } from '../../../shared/section/ActionButtonSection'
import { overrideActivity, useGetActivityById } from '../db/activities'
import { AlertsContext } from '../../../app/AlertsContext'
import { saveActivity } from '../api'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { IconClose } from '../../../shared/svgs'
import { LeaveDirtyFormPrompt } from '../../../shared/form/LeaveDirtyFormPrompt'
import { SignaturePadField } from './SignaturePadField'
import { SignatureNameField } from './SignatureNameField'

type ActivitySignatureValues = {
  name: string
  signature: string
}

const ActivitySignature: React.FC<{ activityId: string }> = ({
  activityId,
}) => {
  const intl = useIntl()
  const history = useHistory()
  const { location } = useContext(LocationContext)
  const { addErrorMessage } = useContext(AlertsContext)

  const { data: activityData, error: activityError } =
    useGetActivityById(activityId)

  const initialValue: ActivitySignatureValues = {
    name: '',
    signature: '',
  }

  const onSubmit = async (
    values: ActivitySignatureValues,
    { resetForm }: FormikHelpers<ActivitySignatureValues>
  ) => {
    if (!activityData) {
      activityError && addErrorMessage(activityError.message)

      return
    }

    const activity = activityData
    const timestamp = new Date().toISOString()
    activity.signature = {
      date: timestamp.substring(0, timestamp.length - 5),
      image: values.signature,
      name: values.name,
      location: null,
    }

    if (location !== null) {
      activity.signature.location = {
        longitude: location.coords.longitude,
        latitude: location.coords.latitude,
      }
    }

    try {
      const updatedActivity = await saveActivity(activity)
      await overrideActivity(updatedActivity)

      resetForm({ values })

      history.goBack()
    } catch (error: any) {
      addErrorMessage(extractErrorMessage(error))
    }
  }

  const requiredField = intl.formatMessage({ id: 'shared.validation.required' })
  const schema = Yup.object().shape({
    name: Yup.string().required(requiredField),
    signature: Yup.string().required(requiredField),
  })

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage({ id: 'activity.detail.back' })}
            onClick={() => history.goBack()}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage id="activity.sign.title" />
        </HeaderTitle>
      </HeaderArea>
      <Formik
        initialValues={initialValue}
        onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
        validationSchema={schema}
        validateOnChange={false}
      >
        {({ values }) => (
          <Form>
            <LeaveDirtyFormPrompt />
            <SignatureNameField />
            <SignaturePadField values={values} />
            <ActionButtonSection className="pb-5 md:pb-5">
              <CancelButton onClick={() => history.goBack()} />
              <SubmitButton />
            </ActionButtonSection>
          </Form>
        )}
      </Formik>
    </>
  )
}

export type { ActivitySignatureValues }
export { ActivitySignature }
