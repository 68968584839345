import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IconKeyboardArrowLeft, IconKeyboardArrowRight } from '../svgs'

const SectionPaginationContainer: React.FC = ({ children }) => (
  <div className="flex justify-between md:p-5 py-5 px-3 md:rounded-lg">
    {children}
  </div>
)

const SectionPaginationPreviousContent: React.FC = () => (
  <>
    <IconKeyboardArrowLeft className="inline-block w-6 h-6 fill-current" />
    <span className="hidden md:block">
      <FormattedMessage id="shared.pagination.previous" />
    </span>
  </>
)

const SectionPaginationNextContent: React.FC = () => (
  <>
    <span className="hidden md:block">
      <FormattedMessage id="shared.pagination.next" />
    </span>
    <IconKeyboardArrowRight className="inline-block w-6 h-6 fill-current" />
  </>
)

const SectionPaginationPagesList: React.FC = ({ children }) => (
  <ul className="flex items-center space-x-4">{children}</ul>
)

const SectionPaginationPagesListItem: React.FC = ({ children }) => (
  <li>{children}</li>
)

export {
  SectionPaginationContainer,
  SectionPaginationPreviousContent,
  SectionPaginationNextContent,
  SectionPaginationPagesList,
  SectionPaginationPagesListItem,
}
