import React from 'react'
import { AsyncView } from '@aboutbits/react-toolbox'
import { FormattedMessage } from 'react-intl'
import { ActivityArticle } from '../types'
import { useGetArticlesByIds } from '../db/article'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { Size } from '../../../shared/section/SectionItem'
import { populateArticleData } from './populateArticleData'

const ActivityArticleSection: React.FC<{
  articleIds: Array<ActivityArticle>
}> = ({ articleIds }) => {
  const { data } = useGetArticlesByIds(
    articleIds.map((article) => article.articleId)
  )
  const articleDetails = populateArticleData(articleIds, data)

  return (
    <AsyncView
      data={articleDetails}
      renderSuccess={(articleDetails) =>
        articleDetails && articleDetails.length > 0 ? (
          <Section>
            <SectionTitle>
              <FormattedMessage id="activity.detail.article" />
            </SectionTitle>
            <SectionList>
              {articleDetails.map(
                (article, index) =>
                  article && (
                    <SectionItem key={index} size={Size.xs}>
                      <div className="flex-1">
                        <h2>{article.name}</h2>
                        <h3 className="pt-1 text-sm break-all">
                          {article.code}
                        </h3>
                      </div>
                      <div>
                        <span>{article.count}</span>
                      </div>
                    </SectionItem>
                  )
              )}
            </SectionList>
          </Section>
        ) : null
      }
    />
  )
}

export { ActivityArticleSection }
