import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ActivityEdit } from './edit/ActivityEdit'

const ActivityEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: { id: 'activity.edit.title' } }}>
      <ActivityEdit id={id} />
    </AppLayout>
  )
}

export { ActivityEditPage }
