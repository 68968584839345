import React from 'react'
import { Project } from '../types'
import {
  Section,
  SectionList,
  SectionTitle,
  SectionPaginationInMemory,
} from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconStore as ProjectIcon } from '../../../shared/svgs'
import { PaginatedData } from '../../../db/types'
import { PaginationActions } from '../../../shared/pagination/types'
import { ProjectsSeletionItem } from './ProjectsSelectionItem'

type Props = {
  title: string
  empty: string
  data: PaginatedData<Project>
  onSelect: (projectId: string) => void
} & PaginationActions

const ProjectsSelectionList: React.FC<Props> = ({
  title,
  empty,
  data,
  onSelect,
  paginationActions,
}) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.items.map((project, index) => (
          <ProjectsSeletionItem
            key={index}
            project={project}
            onSelect={onSelect}
          />
        ))}
        {data.items.length === 0 && <Empty icon={ProjectIcon} text={empty} />}
      </SectionList>
      <SectionPaginationInMemory
        total={data.total}
        size={data.size}
        page={data.page}
        paginationActions={paginationActions}
      />
    </Section>
  )
}

export { ProjectsSelectionList }
