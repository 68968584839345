import React from 'react'
import { AdditionalAddress } from '../types'
import { groupArrayByKey } from '../../../shared/utils/arrayUtils'
import { AdditionalAddressList } from './AdditionalAddressList'

const AdditionalAddressGroup: React.FC<{ data: Array<AdditionalAddress> }> = ({
  data,
}) => {
  const grouped = groupArrayByKey<AdditionalAddress>(data, 'label')

  return (
    <>
      {grouped.map((groupedLists, index) => (
        <AdditionalAddressList key={index} data={groupedLists} />
      ))}
    </>
  )
}

export { AdditionalAddressGroup }
