import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { InputWithLabel } from '../../../shared/form'
import { ContentArea } from '../../../shared/content'

const SignatureNameField: React.FC = () => {
  const intl = useIntl()

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage id="activity.sign.form.name.title" />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <InputWithLabel
              type="text"
              id="name"
              name="name"
              label={<FormattedMessage id="activity.sign.form.name" />}
              placeholder={intl.formatMessage({
                id: 'activity.sign.form.name',
              })}
            />
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

export { SignatureNameField }
