import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { IconClose } from '../../../shared/svgs'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { AddressAddSection } from './AddressAddSection'

const AddressAdd: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage({ id: 'shared.goBack' })}
            onClick={() => history.goBack()}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage id="address.add.title" />
        </HeaderTitle>
      </HeaderArea>
      <AddressAddSection />
    </>
  )
}

export { AddressAdd }
