import Axios, { CancelToken } from 'axios'
import { axiosGet } from '../../shared/utils/apiUtils'
import { Activity, ActivityConfig, ArticleDetail, ImageToUpload } from './types'

const getActivities = (): Promise<Array<Activity>> =>
  axiosGet('/v1/user/activities')

const getActivityConfig = (): Promise<{
  activityTypes: Array<ActivityConfig>
}> => axiosGet('/v1/user/activities/config')

const deleteActivity = async (id: string): Promise<void> =>
  Axios.delete(`/v1/user/activities/${id}`)

const getArticles = (): Promise<Array<ArticleDetail>> =>
  axiosGet('/v1/user/articles')

async function saveActivity(activity: Activity): Promise<Activity> {
  const res = await Axios.put<Activity>(
    `/v1/user/activities/${activity.id}`,
    activity
  )

  return res.data
}

async function createImage(image: ImageToUpload, cancelToken: CancelToken) {
  let fd = new FormData()
  fd.append('file', image.file)
  const res = Axios.post(`/v1/user/images/${image.imageId}`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    cancelToken: cancelToken,
  })
  return res
}

export {
  getActivities,
  getArticles,
  getActivityConfig,
  saveActivity,
  deleteActivity,
  createImage,
}
