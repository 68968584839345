import React from 'react'
import { Link } from 'react-router-dom'
import { AdditionalAddress } from '../types'
import { SectionItem } from '../../../shared/section'
import { IconKeyboardArrowRight } from '../../../shared/svgs'

const AdditionalAddressItem: React.FC<{ data: AdditionalAddress }> = ({
  data,
}) => {
  return (
    <Link to={`/addresses/${data.addressId}`} className="block">
      <SectionItem>
        <div className="flex-1">{data.name}</div>
        <div>
          <IconKeyboardArrowRight className="w-6 h-6" />
        </div>
      </SectionItem>
    </Link>
  )
}

export { AdditionalAddressItem }
