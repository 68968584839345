import React from 'react'
import classNames from 'classnames'

const DialogAction: React.FC<{
  label: string
  disabled?: boolean
  onClick: () => void
  className?: string
}> = ({ label, onClick, disabled = false, className, children }) => {
  return (
    <button
      className={classNames(
        'w-12 h-12 flex items-center justify-center focus:outline-none',
        className
      )}
      aria-label={label}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export { DialogAction }
