import React from 'react'

const SectionTitle: React.FC = ({ children }) => {
  return (
    <div className="md:p-5 py-5 px-3 text-sm font-medium md:font-bold">
      {children}
    </div>
  )
}

export { SectionTitle }
