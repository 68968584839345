import React from 'react'
import { IconKeyboardArrowRight } from '../../../shared/svgs'

const FormDialogSelectItem: React.FC<{ onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <button
      className="flex items-center py-5 md:py-4 px-3 md:px-5 space-x-2 md:space-x-4 w-full text-left"
      type="button"
      onClick={onClick}
    >
      <div className="flex-1">{children}</div>
      <div>
        <IconKeyboardArrowRight className="w-6 h-6" />
      </div>
    </button>
  )
}

export { FormDialogSelectItem }
