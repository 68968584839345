import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Activity } from '../../activity/types'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { ActivityOverviewItem } from '../../activity/overview/ActivityOverviewItem'
import { Size } from '../../../shared/section/SectionItem'

const RelatedActivitiesList: React.FC<{ activities: Array<Activity> }> = ({
  activities,
}) => {
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="activity.overview.title" />
      </SectionTitle>
      <SectionList>
        {activities.map((activity, index) => (
          <ActivityOverviewItem
            activity={activity}
            key={index}
            size={Size.sm}
          />
        ))}
      </SectionList>
    </Section>
  )
}

export { RelatedActivitiesList }
