import { DexieError } from 'dexie'

export const TABLE_SETTINGS = 'settings'
export const TABLE_PROJECTS = 'projects'
export const TABLE_COST_CENTERS = 'cost_centers'
export const TABLE_TIME_TRACKING_CONFIG = 'time_tracking_config'
export const TABLE_TIME_TRACKING_RECORDS = 'time_tracking_records'
export const TABLE_TIME_TRACKING_BUFFER = 'time_tracking_buffer'
export const TABLE_ADDRESSES = 'addresses'
export const TABLE_ADDRESS_GROUPS = 'address_groups'
export const TABLE_ACTIVITIES = 'activities'
export const TABLE_ACTIVITIES_CONFIG = 'activities_config'
export const TABLE_ARTICLES = 'articles'
export const TABLE_NOTIFICATIONS = 'notifications'
export const TABLE_USERS = 'users'

export const SETTINGS_ID_SYNC_TIMESTAMP = 'sync_timestamp'
export const SETTINGS_ID_AUTH_ME = 'auth_me'
export const SETTINGS_ID_AUTH_SYNC_TIMESTAMP = 'auth_sync_timestamp'

export type PaginatedData<T> = {
  items: T[]
  total: number
  page: number
  size: number
}

export type PaginatedResponse<T> = {
  data: PaginatedData<T> | null
  error: DexieError | null
}

export type ListResponse<T> = {
  data: Array<T> | null
  error: DexieError | null
}

export type ItemResponse<T> = {
  data: T | null
  error: DexieError | null
}
