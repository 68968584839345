import axios from 'axios'
import { getCookieFromDocument } from '../shared/utils/cookieUtils'
import { BACKEND_API, APP_VERSION } from './env'

// In order to be able to read cross domain cookies, we need to set withCredentials = true
axios.defaults.withCredentials = true
axios.defaults.baseURL = BACKEND_API

// Request interceptor, that gets the XSRF-TOKEN from the cookie and sets it in the header as X-XSRF-TOKEN.
// For the auth/me request it can be ignored, as it is that request, that will get the fresh value in the cookie.
axios.interceptors.request.use((config) => {
  if (config.url !== '/auth/me' && window !== undefined) {
    config.headers.common['X-XSRF-TOKEN'] = getCookieFromDocument('XSRF-TOKEN')
  }

  config.headers.common['APP_VERSION'] = APP_VERSION

  return config
})
