import React, { useEffect, useState } from 'react'
import { FieldArray, FormikProps } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'
import { SectionListAddButton } from '../../../shared/section/SectionListAddButton'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { ContentArea } from '../../../shared/content'
import { BACKEND_API } from '../../../config/env'
import { createImage } from '../api'
import { ImageToUpload } from '../types'
import { ImageFormItem } from './activityForm/ImageFormItem'
import { ActivityFormFields } from './types'
import { ImageToUploadFormItem } from './activityForm/ImageToUploadFormItem'

const ImageFormSection: React.FC<{
  formik: FormikProps<ActivityFormFields>
  setIsUploading: (isUploading: boolean) => void
}> = ({ formik, setIsUploading }) => {
  const [imagesToUpload, setImagesToUpload] = useState<Array<ImageToUpload>>([])
  const intl = useIntl()
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  useEffect(
    () =>
      imagesToUpload.length === 0
        ? setIsUploading(false)
        : setIsUploading(true),
    [imagesToUpload, setIsUploading]
  )

  const openImageSelectionDialog = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  const onFileSelection = ({
    target,
  }: {
    target: EventTarget & HTMLInputElement
  }) => {
    const id = uuidv4()
    if (target.files && target.files.length > 0) {
      const imageToUpload: ImageToUpload = {
        imageId: id,
        file: target.files[0],
      }
      setImagesToUpload((previosImages) => [...previosImages, imageToUpload])
    }
    target.value = '' // clear the event to make re-selection of the same file possible
  }

  const removeImageToUpload = (imageToUpload: ImageToUpload) => {
    setImagesToUpload((previosImages) =>
      previosImages.filter((image) => image.imageId !== imageToUpload.imageId)
    )
  }
  return (
    <ContentArea>
      <div className="mb-10 space-y-5">
        <Section>
          <SectionTitle>
            <FormattedMessage id="activity.detail.images" />
          </SectionTitle>
          <SectionList>
            <FieldArray
              name="images"
              render={(helper) => {
                return (
                  <>
                    {formik.values.images && formik.values.images.length > 0 ? (
                      formik.values.images.map((image, index) => {
                        const url = `${BACKEND_API}/v1/user/images/${image.imageId}`

                        return (
                          <ImageFormItem
                            key={index}
                            image={image}
                            name={`images.${index}.description`}
                            url={url}
                            onDelete={() => {
                              helper.remove(index)
                            }}
                          />
                        )
                      })
                    ) : (
                      <SectionItem>
                        <FormattedMessage id="activity.form.no.images" />
                      </SectionItem>
                    )}
                    {imagesToUpload.map((imageToUpload) => (
                      <ImageToUploadFormItem
                        key={imageToUpload.imageId}
                        imageToUpload={imageToUpload}
                        onUpload={createImage}
                        onSuccess={() => {
                          helper.push({
                            imageId: imageToUpload.imageId,
                            description: '',
                          })
                          removeImageToUpload(imageToUpload)
                        }}
                        onDelete={() => removeImageToUpload(imageToUpload)}
                      />
                    ))}
                  </>
                )
              }}
            />
          </SectionList>
        </Section>
        <SectionListAddButton
          text={intl.formatMessage({ id: 'activity.form.image.add' })}
          onClick={openImageSelectionDialog}
        />
        <input
          className="hidden"
          ref={hiddenFileInput}
          accept=".jpg, .png, .jpeg, .svg"
          onChange={onFileSelection}
          type="file"
        />
      </div>
    </ContentArea>
  )
}

export { ImageFormSection }
