import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { Error } from '../../../shared/error'
import { ContentArea } from '../../../shared/content'
import { useGetAllCostCenters } from '../../costCenter/db/costCenters'
import { TimeTrackingRecord } from '../types'
import { useGetTimeTrackingRecords } from '../db/records'
import { useHasModulePermission } from '../../../auth/hooks'
import { useGetProjectsByIds } from '../../project/db/projects'
import { Module, Permission } from '../../../auth/types'
import { TimeTrackingWidget } from './TimeTrackingWidget'
import {
  EnrichedTimeTracking,
  TimeTrackingOverviewList,
} from './TimeTrackingOverviewList'
import { TimeTrackingOverviewLoading } from './TimeTrackingOverviewLoading'

const TimeTrackingSuccess: React.FC<{
  data: TimeTrackingRecord[]
}> = ({ data }) => {
  const hasCreatePermissions = useHasModulePermission(
    Module.timeTacking,
    Permission.create
  )

  const { data: costCenters } = useGetAllCostCenters()
  const { data: projects } = useGetProjectsByIds(
    data
      .filter((timeTrackingRecord) => timeTrackingRecord.projectId !== null)
      .map((timeTrackingRecord) => timeTrackingRecord.projectId as string)
  )

  const enrichedData: EnrichedTimeTracking[] = data.map((timeTracking) => {
    const assignedCostCenter =
      timeTracking.costCenterId !== null && costCenters !== null
        ? costCenters.find(
            (costCenter) => costCenter.id === timeTracking.costCenterId
          )?.name
        : undefined

    const assignedProject =
      timeTracking.projectId !== null && projects !== null
        ? projects.find((project) => {
            return project.id === timeTracking.projectId
          })?.name
        : undefined

    return {
      ...timeTracking,
      costCenterName: assignedCostCenter,
      projectName: assignedProject,
    }
  })

  return (
    <>
      {hasCreatePermissions && <TimeTrackingWidget data={data} />}
      <TimeTrackingOverviewList data={enrichedData} />
    </>
  )
}

const TimeTrackingOverview: React.FC = () => {
  const intl = useIntl()
  const { data, error } = useGetTimeTrackingRecords()

  return (
    <>
      <HeaderArea>
        <HeaderTitle>
          <FormattedMessage id="timeTracking.overview.title" />
        </HeaderTitle>
      </HeaderArea>

      <ContentArea>
        <AsyncView
          data={data}
          error={error}
          renderLoading={
            <TimeTrackingOverviewLoading
              title={intl.formatMessage({ id: 'timeTracking.overview.title' })}
            />
          }
          renderSuccess={(data) => data && <TimeTrackingSuccess data={data} />}
          renderError={(error) => <Error text={error && error?.message} />}
        />
      </ContentArea>
    </>
  )
}

export { TimeTrackingOverview }
