import { clearDB } from '../db'
import { logOut } from './api'

export const logout = async (): Promise<void> => {
  try {
    await logOut()
  } catch (error: any) {
    // Status 401: complete logout because user has no more active session
    if (error.response !== null && error.response.status !== 401) {
      return
    }
  }

  try {
    await clearDB()

    window.location.pathname = '/login'
  } catch (error) {}
}
