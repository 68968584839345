import React, { PropsWithChildren } from 'react'
import { SectionItem } from '../../../shared/section'
import { IconKeyboardArrowRight } from '../../svgs'
import { Size } from '../../../shared/section/SectionItem'

type Id = string | number

type Props<T extends Id> = {
  itemId: T
  onSelect: (itemId: T) => void
}

function DialogSelectionListItem<T extends Id>({
  itemId,
  onSelect,
  children,
}: PropsWithChildren<Props<T>>) {
  return (
    <button
      onClick={() => {
        onSelect(itemId)
      }}
      className="block w-full hover:bg-gray-500 focus:outline-none"
    >
      <SectionItem size={Size.sm}>
        <div className="flex-1 text-left">{children}</div>
        <div>
          <IconKeyboardArrowRight className="w-5 h-5" />
        </div>
      </SectionItem>
    </button>
  )
}

export { DialogSelectionListItem }
