import React from 'react'
import { Project } from '../../project/types'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { RelatedProjectItem } from './RelatedProjectItem'

const RelatedProjectsList: React.FC<{
  data: Array<Project>
  title: string
}> = ({ data, title }) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.map((p, index) => (
          <RelatedProjectItem data={p} key={index} />
        ))}
      </SectionList>
    </Section>
  )
}

export { RelatedProjectsList }
