import React from 'react'
import { IntlProvider } from 'react-intl'
import sharedTranslations from '../shared/translations'
import { LogoCenter } from '../shared/logo'
import { i18n } from '../config/i18n'

const Loading: React.FC = () => {
  const language = i18n.detectBrowserLanguage()
  const messages = Object.assign({}, sharedTranslations[language])

  return (
    <IntlProvider locale={language} messages={messages}>
      <div className=" flex flex-col justify-center h-screen">
        <LogoCenter />
      </div>
    </IntlProvider>
  )
}

export { Loading }
