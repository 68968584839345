const getCookiePattern = (name: string): RegExp => RegExp(name + '=.[^;]*')

const getCookieFromDocument = (name: string): string | undefined => {
  if (typeof document !== 'undefined') {
    const pattern = getCookiePattern(name)
    const matched = document.cookie.match(pattern)
    if (!matched) {
      return undefined
    }
    const cookie = matched[0].split('=')
    return decodeURIComponent(cookie[1])
  }
  return ''
}

const setCookie = (name: string, value: string): void => {
  document.cookie = name + '=' + value + '; path=/;'
}

export { getCookieFromDocument, setCookie }
