import React from 'react'
import { AsyncView } from '@aboutbits/react-toolbox'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { useGetProjectById } from '../../project/db/projects'
import { IconKeyboardArrowRight } from '../../../shared/svgs'

const ActivityProjectSection: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const { data } = useGetProjectById(projectId)

  return (
    <AsyncView
      data={data}
      renderSuccess={(data) =>
        data && (
          <Section>
            <SectionTitle>
              <FormattedMessage id="activity.detail.project" />
            </SectionTitle>
            <SectionList>
              <Link to={`/projects/${data.id}`} className="block">
                <SectionItem>
                  <div className="flex-1">
                    <h2>{data.name}</h2>
                  </div>
                  <div>
                    <IconKeyboardArrowRight className="w-6 h-6" />
                  </div>
                </SectionItem>
              </Link>
            </SectionList>
          </Section>
        )
      }
    />
  )
}

export { ActivityProjectSection }
