import { axiosGet } from '../../shared/utils/apiUtils'
import { CostCenter } from './types'

export type CostCentersResponse = Array<CostCenter>

async function getCostCenters(): Promise<CostCentersResponse> {
  return axiosGet('v1/user/cost-centers')
}

export { getCostCenters }
