import React from 'react'
import { AdditionalAddress } from '../types'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { AdditionalAddressItem } from './AdditionalAddressItem'

const AdditionalAddressList: React.FC<{ data: Array<AdditionalAddress> }> = ({
  data,
}) => {
  return (
    <Section>
      <SectionTitle>{data[0].label}</SectionTitle>
      <SectionList>
        {data.map((additionalAddresses, index) => (
          <AdditionalAddressItem key={index} data={additionalAddresses} />
        ))}
      </SectionList>
    </Section>
  )
}

export { AdditionalAddressList }
