import { useContext } from 'react'
import { Module, ModuleAccessControl, Permission } from './types'
import { AuthContext } from './AuthContext'

function filterModule(
  modules: ModuleAccessControl[],
  searchedModule: Module
): ModuleAccessControl | null {
  const filteredModules = modules.filter((currentModule) => {
    return currentModule.module === searchedModule
  })

  if (filteredModules.length === 0) {
    return null
  }

  return filteredModules[0]
}

function useHasModule(searchedModule: Module): boolean {
  const authContext = useContext(AuthContext)
  const modules = authContext.data === null ? [] : authContext.data.modules

  return filterModule(modules, searchedModule) !== null
}

function useHasModulePermission(
  searchedModule: Module,
  searchedPermission: Permission
): boolean {
  const authContext = useContext(AuthContext)
  const modules = authContext.data === null ? [] : authContext.data.modules

  const module = filterModule(modules, searchedModule)

  if (module === null) return false

  return module.permissions[searchedPermission]
}

export { useHasModule, useHasModulePermission }
