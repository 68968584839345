import { FormattedMessage } from 'react-intl'
import React from 'react'
import classNames from 'classnames'
import logo1x from '../../images/logo.png'
import logo2x from '../../images/logo@2x.png'

const LogoLeft: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={classNames('flex flex-col items-start', className)}>
      <div className="flex items-center">
        <img
          srcSet={`${logo1x} 1x, ${logo2x} 2x`}
          alt="ZagZag"
          className="p-2 w-12 h-12 bg-black rounded-full"
        />
        <h1 className="ml-4 text-2xl font-bold">
          <FormattedMessage id="shared.logo.name" />
        </h1>
      </div>
      <h2 className="mt-4 mb-2 text-md">
        <FormattedMessage id="shared.logo.title" />
      </h2>
      <h3 className="text-sm">
        <FormattedMessage id="shared.logo.subtitle" />
      </h3>
    </div>
  )
}

export { LogoLeft }
