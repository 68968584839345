import React, { ComponentType } from 'react'
import { NavLink } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { IconProps } from '../../shared/svgs/types'
import { IconArrowRightAlt, IconLock } from '../../shared/svgs'

type PropType = {
  active: boolean
  titleId: string
  subTitleId: string
  to: string
  Icon: ComponentType<IconProps>
}

const HomeSection: React.FC<PropType> = ({
  active,
  Icon,
  titleId,
  subTitleId,
  to,
}) => {
  const intl = useIntl()

  return (
    <NavLink to={active ? to : ''}>
      <div
        className={`mx-4 rounded-lg flex-1 my-2 ${
          active ? 'bg-primary-500' : 'bg-gray-300'
        }`}
      >
        <div className="flex justify-between">
          <div className="py-6 px-5">
            <div
              className={`rounded-full h-16 w-16 flex items-center justify-center ${
                active ? 'bg-white' : 'bg-primary-500'
              }`}
            >
              <Icon height="50" />
            </div>
            <div className="mt-5">
              <h1 className="text-xl">{intl.formatMessage({ id: titleId })}</h1>
              <h2 className="mt-1">{intl.formatMessage({ id: subTitleId })}</h2>
            </div>
          </div>
          <div className="relative mt-5 mr-3">
            {!active ? (
              <div className="inline-flex absolute top-0 right-0 items-center py-1 px-4 bg-primary-500 rounded-full">
                <IconLock height="20" />
                <span className="ml-2 text-sm">
                  {intl.formatMessage({ id: 'home.nav.enable' })}
                </span>
              </div>
            ) : (
              <div />
            )}
            <IconArrowRightAlt
              height="40"
              className="absolute right-0 bottom-0 mb-2"
            />
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export { HomeSection }
