import { Module, ModuleAccessControl } from '../../auth/types'
import { checkSyncPermissions } from '../../shared/utils/syncUtil'
import { syncTimeTrackingRecords, TimeTrackingSyncPayload } from './api'
import {
  deleteBufferEntriesByIds,
  deleteBufferEntriesByTimeTrackingIds,
  getAllBufferEntries,
  getAllBufferEntriesExceptIds,
} from './db/buffer'
import {
  bulkUpsertTimeTrackingRecords,
  deleteTimeTrackingRecords,
} from './db/records'
import {
  isDeleteAction,
  isUpsertAction,
  TimeTrackingBufferEntry,
  TimeTrackingBufferPayloads,
} from './types'
import { getTimeTrackingSyncDate, setTimeTrackingSyncDate } from './db/config'

const mapBufferEntriesToSyncPayload = (
  entries: Array<TimeTrackingBufferEntry<TimeTrackingBufferPayloads>>,
  timestamp: string | null
): TimeTrackingSyncPayload => {
  const result: TimeTrackingSyncPayload = {
    timestamp,
    upsertedRecords: [],
    deletedRecords: [],
  }

  entries
    .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())
    .forEach((entry) => {
      if (isUpsertAction(entry)) {
        result.upsertedRecords.push({
          ...entry.payload,
        })
      } else if (isDeleteAction(entry)) {
        result.deletedRecords.push({ id: entry.timeTrackingId })
      }
    })

  return result
}

async function syncTimeTracking(
  modules: Array<ModuleAccessControl>
): Promise<void> {
  if (!checkSyncPermissions(modules, Module.timeTacking)) {
    return
  }

  const timeTrackingSyncDate = await getTimeTrackingSyncDate()
  const entriesToSync = await getAllBufferEntries()

  const payload = mapBufferEntriesToSyncPayload(
    entriesToSync,
    timeTrackingSyncDate
  )

  const response = await syncTimeTrackingRecords(payload)
  const syncedBufferEntryIds = entriesToSync.map((entry) => entry.id)
  const recordsToUpsert = [...response.data.upsertedRecords]
  const recordsToDelete = response.data.deletedRecords.map(
    (record) => record.id
  )

  // Replay the changes from buffer that have been made while sync requests was pending
  const bufferEntriesToReplay = await getAllBufferEntriesExceptIds(
    syncedBufferEntryIds
  )
  bufferEntriesToReplay.forEach((bufferEntryToReplay) => {
    if (isUpsertAction(bufferEntryToReplay)) {
      recordsToUpsert.push(bufferEntryToReplay.payload)
    } else if (isDeleteAction(bufferEntryToReplay)) {
      recordsToDelete.push(bufferEntryToReplay.timeTrackingId)
    }
  })

  // Update and delete time tracking records in DB
  await bulkUpsertTimeTrackingRecords(recordsToUpsert)
  await deleteTimeTrackingRecords(recordsToDelete)

  // Delete buffer entries, that have been successfully synced and applied in local DB
  await deleteBufferEntriesByIds(syncedBufferEntryIds)

  // Delete all buffer entries from time tracking records, that have been deleted on the server
  await deleteBufferEntriesByTimeTrackingIds(
    response.data.deletedRecords.map((record) => record.id)
  )

  // Set last buffer sync date
  await setTimeTrackingSyncDate(response.data.timestamp)
}

export { syncTimeTracking, mapBufferEntriesToSyncPayload }
