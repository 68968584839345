import React from 'react'
import { CostCenter } from '../types'
import {
  Section,
  SectionList,
  SectionPaginationInMemory,
  SectionTitle,
} from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconStore as CostCenterIcon } from '../../../shared/svgs'
import { PaginatedData } from '../../../db/types'
import { PaginationActions } from '../../../shared/pagination/types'
import { CostCentersSelectionItem } from './CostCentersSelectionItem'

type Props = {
  title: string
  empty: string
  data: PaginatedData<CostCenter>
  onSelect: (projectId: string) => void
} & PaginationActions

const CostCentersSelectionList: React.FC<Props> = ({
  title,
  empty,
  data,
  onSelect,
  paginationActions,
}) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.items.map((costCenter, index) => (
          <CostCentersSelectionItem
            key={index}
            costCenter={costCenter}
            onSelect={onSelect}
          />
        ))}
        {data.items.length === 0 && (
          <Empty icon={CostCenterIcon} text={empty} />
        )}
      </SectionList>
      <SectionPaginationInMemory
        total={data.total}
        size={data.size}
        page={data.page}
        paginationActions={paginationActions}
      />
    </Section>
  )
}

export { CostCentersSelectionList }
