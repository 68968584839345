import Internationalization from '@aboutbits/internationalization'
import { Language } from '../domain/user/types'

const supportedLanguages = [Language.DE]
const fallbackLanguage = Language.DE

const i18n = new Internationalization<Language>(
  supportedLanguages,
  fallbackLanguage
)

export { i18n }
