import React from 'react'
import { PaginatedData } from '../../../db/types'
import { Address } from '../types'
import {
  Section,
  SectionList,
  SectionPaginationRouter,
  SectionTitle,
} from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconPermContactCalendar } from '../../../shared/svgs'
import { AddressOverviewItem } from './AddressOverviewItem'

const AddressOverviewList: React.FC<{
  title: string
  empty: string
  data: PaginatedData<Address>
}> = ({ title, empty, data }) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {data.items.map((address, index) => {
          return <AddressOverviewItem address={address} key={index} />
        })}
        {data.items.length === 0 && (
          <Empty icon={IconPermContactCalendar} text={empty} />
        )}
      </SectionList>
      <SectionPaginationRouter
        page={data.page}
        size={data.size}
        total={data.total}
      />
    </Section>
  )
}

export { AddressOverviewList }
