import React from 'react'
import { Link } from 'react-router-dom'

const SectionListLink: React.FC<{ to: string }> = ({ to, children }) => {
  return (
    <Link to={to}>
      <div className="flex justify-center items-center mt-10">{children}</div>
    </Link>
  )
}

export { SectionListLink }
