import React from 'react'
import {
  FormattedDate,
  FormattedMessage,
  FormattedTime,
  useIntl,
} from 'react-intl'
import { Section, SectionTitle } from '../../../shared/section'
import { SectionHero } from '../../../shared/section/SectionHero'
import { Activity, ActivityConfig, Category, Progress } from '../types'
import { SectionItemContentWithIcon } from '../../../shared/section/SectionItemContentWithIcon'
import { IconEvent } from '../../../shared/svgs'
import { getDurationAsString } from '../form/dateFormatters'

const ConfigItems: React.FC<{ type: string; data: string | undefined }> = ({
  type,
  data,
}) => {
  return data ? (
    <div>
      <span className="font-bold">{type}: </span> {data}
    </div>
  ) : (
    <div>
      <span className="font-bold">{type}: </span> -
    </div>
  )
}

const ActivityDate: React.FC<{ date: string | null; intlId: string }> = ({
  date,
  intlId,
}) => {
  return date ? (
    <SectionItemContentWithIcon icon={IconEvent}>
      <span className="font-bold">
        <FormattedMessage id={intlId} />
      </span>
      : <FormattedDate value={new Date(date)} /> -{' '}
      <FormattedTime value={new Date(date)} />
    </SectionItemContentWithIcon>
  ) : null
}

const ActivityDetailHero: React.FC<{
  activity: Activity
  config: Array<ActivityConfig>
}> = ({ activity, config }) => {
  const intl = useIntl()
  let type: ActivityConfig | undefined
  let category: Category | undefined
  let progress: Progress | undefined

  type = config.find((type) => type.id === activity.activityTypeId)

  if (type !== undefined) {
    category = type.categories.find(
      (category) => category.id === activity.activityCategoryId
    )
  }

  if (category !== undefined) {
    progress = category.progresses.find(
      (progress) => progress.id === activity.activityProgressId
    )
  }

  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="activity.detail.title" />
      </SectionTitle>
      <SectionHero>
        <div>
          <h2 className="text-3xl">{activity.name}</h2>
        </div>
        <div className="flex overflow-hidden flex-wrap">
          <div className="overflow-hidden space-y-5 w-full lg:w-1/2">
            <ActivityDate
              date={activity.startDate}
              intlId="activity.detail.date.start"
            />
            <ActivityDate
              date={activity.endDate}
              intlId="activity.detail.date.end"
            />
            <ActivityDate
              date={activity.dueDate}
              intlId="activity.detail.date.due"
            />
            <SectionItemContentWithIcon icon={IconEvent}>
              <span className="font-bold">
                <FormattedMessage id="activity.detail.date.duration" />
              </span>
              : {getDurationAsString(activity.startDate, activity.endDate)}
            </SectionItemContentWithIcon>
          </div>
          <div className="overflow-hidden mt-10 lg:mt-0 space-y-5 w-full lg:w-1/2">
            <ConfigItems
              type={intl.formatMessage({ id: 'activity.detail.hero.type' })}
              data={type?.name}
            />
            <ConfigItems
              type={intl.formatMessage({ id: 'activity.detail.hero.category' })}
              data={category?.name}
            />
            <ConfigItems
              type={intl.formatMessage({ id: 'activity.detail.hero.progress' })}
              data={progress?.name}
            />
          </div>
        </div>
      </SectionHero>
    </Section>
  )
}

export { ActivityDetailHero }
