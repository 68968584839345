import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axios, { CancelToken, CancelTokenSource, AxiosResponse } from 'axios'
import imageCompression from 'browser-image-compression'
import { CircleBouncer } from '../../../../shared/loading/CircleBouncer'
import { ImageToUpload } from '../../types'
import { IconDelete } from '../../../../shared/svgs'
import { SectionItem } from '../../../../shared/section'

const ImageToUploadFormItem: React.FC<{
  imageToUpload: ImageToUpload
  onUpload: (
    image: ImageToUpload,
    cancelToken: CancelToken
  ) => Promise<AxiosResponse<any>>
  onSuccess: () => void
  onDelete: () => void
}> = ({ imageToUpload, onUpload, onSuccess, onDelete }) => {
  const [isUploadStarted, setIsUploadStarted] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [axiosSource] = useState<CancelTokenSource>(() =>
    axios.CancelToken.source()
  )
  const intl = useIntl()

  useEffect(() => {
    const compressAndUpload = async (image: ImageToUpload) => {
      try {
        await onUpload(
          {
            imageId: imageToUpload.imageId,
            file: await compress(image.file),
          },
          axiosSource.token
        )
        onSuccess()
      } catch (error: any) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setErrorMessage(
            error.response.data.errors.file
              ? error.response.data.errors.file[0]
              : error.response.data.message
          )
        } else {
          setErrorMessage(
            intl.formatMessage({
              id: 'activity.form.image.unknown.error',
            })
          )
        }
      }
    }
    if (!isUploadStarted) {
      const fileType = imageToUpload.file.type
      if (
        fileType === 'image/png' ||
        fileType === 'image/jpg' ||
        fileType === 'image/jpeg' ||
        fileType === 'image/svg'
      ) {
        compressAndUpload(imageToUpload)
      } else {
        setErrorMessage(
          intl.formatMessage({
            id: 'activity.form.image.filetype.error',
          })
        )
      }
      setIsUploadStarted(true)
    }
  }, [imageToUpload, intl, onSuccess, onUpload, isUploadStarted, axiosSource])

  useEffect(() => () => axiosSource.cancel(), [axiosSource])

  const compress = async (file: File): Promise<File> => {
    const imageIsCompressable = file.type !== 'image/svg'
    if (imageIsCompressable)
      return await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 750,
      })
    else return file
  }

  return (
    <SectionItem>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row justify-between items-start mb-8">
          {isUploadStarted && !errorMessage && <CircleBouncer />}
          {errorMessage && <div className="text-red-100">{errorMessage}</div>}
          <button
            type="button"
            aria-label={intl.formatMessage({
              id: 'activity.form.image.delete',
            })}
            onClick={onDelete}
          >
            <IconDelete className="h-5" />
          </button>
        </div>
        <div>{imageToUpload.file.name}</div>
      </div>
    </SectionItem>
  )
}

export { ImageToUploadFormItem }
