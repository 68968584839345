import React, { useState } from 'react'
import { FormikProps } from 'formik'
import { AsyncView } from '@aboutbits/react-toolbox'
import { FormattedMessage } from 'react-intl'
import { ContentArea } from '../../../shared/content'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { useGetProjectById } from '../../project/db/projects'
import { ActivityFormFields } from './types'
import { ProjectDialog } from './projectDialog/ProjectDialog'
import { FormDialogSelectItem } from './FormDialogSelectItem'

const ProjectFormSection: React.FC<{
  formik: FormikProps<ActivityFormFields>
}> = ({ formik }) => {
  const { values, setFieldValue } = formik
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const { data } = useGetProjectById(values.projectId)

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage id="activity.form.project.title" />
        </SectionTitle>
        <SectionList>
          {data ? (
            <AsyncView
              data={data}
              renderSuccess={(data) => (
                <FormDialogSelectItem onClick={() => setShowDialog(true)}>
                  {data.name}
                </FormDialogSelectItem>
              )}
            />
          ) : (
            <FormDialogSelectItem onClick={() => setShowDialog(true)}>
              <FormattedMessage id="activity.form.no.project" />
            </FormDialogSelectItem>
          )}
        </SectionList>
      </Section>
      <ProjectDialog
        onDismiss={() => setShowDialog(false)}
        onConfirm={(projectId, project) => {
          setFieldValue('projectId', projectId)
          setFieldValue('addressId', project.addressId)
          setShowDialog(false)
        }}
        onClear={() => {
          setFieldValue('projectId', '')
          setFieldValue('addressId', '')
          setShowDialog(false)
        }}
        isOpen={showDialog}
      />
    </ContentArea>
  )
}

export { ProjectFormSection }
