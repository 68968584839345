import React from 'react'
import { PrimitiveType } from 'intl-messageformat'
import { MessageDescriptor, useIntl } from 'react-intl'

type HeadProps = {
  title: {
    messageDescriptor: MessageDescriptor
    values?: Record<string, PrimitiveType>
  }
}

const Head: React.FC<HeadProps> = ({ title }) => {
  const intl = useIntl()
  // TODO: fix Next/Head
  return (
    <title>{intl.formatMessage(title.messageDescriptor, title.values)}</title>
  )
}

export { Head }
