import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { AddressFormFields } from '../forms/types'
import { updateAddress } from '../api'
import { overrideAddress } from '../db/addresses'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'
import { Address } from '../types'
import { AlertsContext } from '../../../app/AlertsContext'
import { AddressForm } from '../forms/AddressForm'

const AddressEditSection: React.FC<{ data: Address }> = ({ data }) => {
  const history = useHistory()
  const { addErrorMessage } = useContext(AlertsContext)

  const initialValues: AddressFormFields = {
    name: data.name,
    address: {
      street: data.address.street != null ? data.address.street : '',
      city: data.address.city != null ? data.address.city : '',
      cap: data.address.cap != null ? data.address.cap : '',
      country: data.address.country != null ? data.address.country : '',
      latitude: data.address.latitude,
      longitude: data.address.longitude,
    },
    phoneNumbers: data.phoneNumbers,
    emailAddresses: data.emailAddresses,
  }

  const onSubmit = async (
    values: AddressFormFields,
    { resetForm }: FormikHelpers<AddressFormFields>
  ): Promise<void> => {
    const newAddress = { ...data }
    newAddress.name = values.name
    newAddress.address = values.address
    newAddress.phoneNumbers = values.phoneNumbers
    newAddress.emailAddresses = values.emailAddresses

    try {
      const updatedAddress = await updateAddress(newAddress)
      await overrideAddress(updatedAddress)

      resetForm({ values })

      history.goBack()
    } catch (error: any) {
      addErrorMessage(extractErrorMessage(error))
    }
  }

  return <AddressForm initialValues={initialValues} onSubmit={onSubmit} />
}

export { AddressEditSection }
