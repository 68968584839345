import React from 'react'
import { AppLayout } from '../../layouts/app'
import { TimeTrackingOverview } from './overview/TimeTrackingOverview'

const TimeTrackingOverviewPage: React.FC = () => (
  <AppLayout title={{ messageDescriptor: { id: 'project.overview.title' } }}>
    <TimeTrackingOverview />
  </AppLayout>
)

export { TimeTrackingOverviewPage }
