import React from 'react'
import { CostCenter } from '../types'
import { DialogSelectionListItem } from '../../../shared/dialog/selection/DialogSelectionListItem'

const CostCentersSelectionItem: React.FC<{
  costCenter: CostCenter
  onSelect: (costCenterId: string) => void
}> = ({ costCenter, onSelect }) => {
  return (
    <DialogSelectionListItem<string> itemId={costCenter.id} onSelect={onSelect}>
      {costCenter.name}
    </DialogSelectionListItem>
  )
}

export { CostCentersSelectionItem }
