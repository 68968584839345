import Axios, { AxiosResponse } from 'axios'
import { axiosGet } from '../../shared/utils/apiUtils'
import { Project } from './types'

export type ProjectsResponse = Project[]

const getProjects = (): Promise<ProjectsResponse> =>
  axiosGet('v1/user/projects')

async function markAsFavorite(id: string): Promise<Project> {
  const res = await Axios.put<Record<string, unknown>, AxiosResponse<Project>>(
    `v1/user/projects/${id}/favorite`,
    {}
  )
  return res.data
}

async function unmarkAsFavorite(id: string): Promise<Project> {
  const res = await Axios.delete<
    Record<string, unknown>,
    AxiosResponse<Project>
  >(`v1/user/projects/${id}/favorite`, {})
  return res.data
}

export { getProjects, markAsFavorite, unmarkAsFavorite }
