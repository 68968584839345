import React from 'react'
import { MessageDescriptor } from 'react-intl'
import { PrimitiveType } from 'intl-messageformat'

import classNames from 'classnames'
import { Head } from '../shared/Head'
import { Footer } from '../shared/Footer'
import { LogoCenter, LogoLeft } from '../../shared/logo'
import styles from './GuestLayout.module.css'

type GuestLayoutProps = {
  title: {
    messageDescriptor: MessageDescriptor
    values?: Record<string, PrimitiveType>
  }
}

const GuestLayout: React.FC<GuestLayoutProps> = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div
        className={classNames(
          styles.background,
          'min-h-screen flex flex-col md:flex-row md:justify-start md:items-center'
        )}
      >
        <div className="flex flex-col flex-1 md:flex-initial md:w-xl md:bg-white md:rounded-r-lg md:shadow-lg">
          <header className="flex flex-col flex-1 justify-center px-2 md:px-6 my-8">
            <LogoCenter className="block md:hidden" />
            <LogoLeft className="hidden md:block" />
          </header>
          <div className="px-2 md:px-6 bg-primary-500 rounded-t-lg md:rounded-r-lg md:rounded-l-none">
            <main className="mt-12 mb-32">{children}</main>
            <Footer className="my-4" />
          </div>
        </div>
      </div>
    </>
  )
}

export { GuestLayout }
