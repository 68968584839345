import React from 'react'

const DialogHeaderArea: React.FC = ({ children }) => {
  return (
    <div className="flex flex-col md:flex-row md:items-center p-4 md:p-0 md:mb-12 bg-gray-700 md:bg-transparent rounded-b-lg">
      {children}
    </div>
  )
}

export { DialogHeaderArea }
