import { useFormikContext } from 'formik'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UnregisterCallback } from 'history'
import { LeaveDirtyFormDialog } from '../dialog/LeaveDirtyFormDialog'

export function LeaveDirtyFormPrompt(): ReactElement {
  const history = useHistory()
  const { dirty } = useFormikContext()

  const [showPrompt, setShowPrompt] = useState<boolean>(false)
  const [currentPath, setCurrentPath] = useState<string>('')

  const unblockRef = useRef<UnregisterCallback | null>(null)

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (dirty) {
        setCurrentPath(location.pathname)
        setShowPrompt(true)
        return false
      }
    })
    return () => {
      unblockRef.current?.()
    }
  }, [history, dirty])

  function leave() {
    unblockRef.current?.()
    setShowPrompt(false)
    history.push(currentPath)
  }

  return (
    <LeaveDirtyFormDialog
      isOpen={showPrompt}
      onDismiss={() => setShowPrompt(false)}
      onConfirm={() => leave()}
    />
  )
}
