import React from 'react'
import { AppLayout } from '../../layouts/app'
import { ActivityAdd } from './add/ActivityAdd'

const ActivityAddPage: React.FC = () => {
  return (
    <AppLayout title={{ messageDescriptor: { id: 'activity.add.title' } }}>
      <ActivityAdd />
    </AppLayout>
  )
}

export { ActivityAddPage }
