import React, { useContext, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Dialog } from '@reach/dialog'

import { ActionButton } from '../../../../shared/button/ActionButton'
import { IconDelete } from '../../../../shared/svgs'
import { DialogContent } from '../../../../shared/dialog/DialogContent'
import { Button } from '../../../../shared/button'
import { Variant } from '../../../../shared/button/Button'
import { deleteTimeTrackingRecord } from '../../db/records'
import { addDeleteBufferEntry } from '../../db/buffer'
import { syncTimeTracking } from '../../sync'
import { AuthContext } from '../../../../auth/AuthContext'

const TimeTrackingItemDelete: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const { data: moduleData } = useContext(AuthContext)

  const showDialog = () => {
    setShowDeleteConfirmation(true)
  }

  const dismissDialog = () => {
    setShowDeleteConfirmation(false)
  }

  const deleteRecord = async () => {
    setShowDeleteConfirmation(false)
    await deleteTimeTrackingRecord(id)
    await addDeleteBufferEntry(id)
    if (moduleData?.modules) {
      await syncTimeTracking(moduleData.modules)
    }
  }

  return (
    <>
      <ActionButton onClick={showDialog}>
        <IconDelete className="mr-2 w-5 h-5" />
        <FormattedMessage id="timeTracking.item.delete" />
      </ActionButton>
      {showDeleteConfirmation && (
        <Dialog
          onDismiss={dismissDialog}
          aria-label={intl.formatMessage({
            id: 'timeTracking.item.delete.confirmation.label',
          })}
        >
          <DialogContent>
            <div className="text-xl font-bold">
              <FormattedMessage id="timeTracking.item.delete.confirmation.title" />
            </div>
            <p className="mt-3">
              <FormattedMessage id="timeTracking.item.delete.confirmation.description" />
            </p>
            <div className="mt-8 space-x-4 text-right">
              <Button variant={Variant.secondary} onClick={dismissDialog}>
                <FormattedMessage id="shared.cancel" />
              </Button>
              <Button variant={Variant.primary} onClick={deleteRecord}>
                <FormattedMessage id="shared.ok" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export { TimeTrackingItemDelete }
