import React from 'react'
import { PaginatedData } from '../../../db/types'
import { Activity } from '../types'
import {
  Section,
  SectionList,
  SectionPaginationRouter,
  SectionTitle,
} from '../../../shared/section'
import { Empty } from '../../../shared/empty'
import { IconFormatListBulleted } from '../../../shared/svgs'
import { useGetProjectsByIds } from '../../project/db/projects'
import { ActivityOverviewItem } from './ActivityOverviewItem'
import { populateActivityData } from './populateActivityData'

const ActivityGeneralOverviewList: React.FC<{
  title: string
  empty: string
  data: PaginatedData<Activity>
}> = ({ empty, data, title }) => {
  const { data: projects } = useGetProjectsByIds(
    data.items.map((activity) => activity.projectId)
  )

  const items = populateActivityData(data.items, projects)

  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        {items.map((activity) => (
          <ActivityOverviewItem activity={activity} key={activity.id} />
        ))}
        {items.length === 0 && (
          <Empty icon={IconFormatListBulleted} text={empty} />
        )}
        <SectionPaginationRouter
          page={data.page}
          size={data.size}
          total={data.total}
        />
      </SectionList>
    </Section>
  )
}

export { ActivityGeneralOverviewList }
