import React, { ComponentType } from 'react'
import { IconProps } from '@aboutbits/react-material-icons/dist/types'

const SectionItemAction: React.FC<{
  icon: ComponentType<IconProps>
  label: string
  disabled?: boolean
  onClick: () => void
}> = ({ icon: Icon, label, disabled = false, onClick }) => {
  return (
    <div>
      <button
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        aria-label={label}
        disabled={disabled}
        className="text-black hover:text-gray-700 focus:text-gray-700 align-text-top"
      >
        <Icon className="w-6 h-6 fill-current" title={label} />
      </button>
    </div>
  )
}

export { SectionItemAction }
