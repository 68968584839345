import { format, intervalToDuration } from 'date-fns'

const formatDateForAPI = (date: string): string => {
  return format(new Date(date), 'yyyy-MM-dd HH:mm:ss').split(' ').join('T')
}

const getDurationAsString = (startDate: string, endDate: string): string => {
  const duration = intervalToDuration({
    start: new Date(endDate),
    end: new Date(startDate),
  })
  const hours =
    duration?.hours?.toString().length === 1
      ? '0' + duration.hours
      : duration.hours
  const minutes =
    duration?.minutes?.toString().length === 1
      ? '0' + duration.minutes
      : duration.minutes

  return `${hours}:${minutes}`
}

const getDurationFromString = (
  duration: string
): { hours: number; minutes: number } | null => {
  const durationParsedString = duration.split(':')

  if (durationParsedString[0] && durationParsedString[1]) {
    return {
      hours: parseInt(durationParsedString[0]),
      minutes: parseInt(durationParsedString[1]),
    }
  } else {
    return null
  }
}

export { formatDateForAPI, getDurationAsString, getDurationFromString }
