import React from 'react'
import classNames from 'classnames'

const HorizontalSpacer: React.FC<{ className?: string }> = ({
  className,
  children,
}) => {
  return <div className={classNames(className, 'mx-2 md:mx-0')}>{children}</div>
}

export { HorizontalSpacer }
