import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { useGetQueryParam } from '../../../shared/utils/routerUtils'
import { ActivityAddSection } from './ActivityAddSection'

const ActivityAdd: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const addressId = useGetQueryParam('address')
  const projectId = useGetQueryParam('project')

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage({ id: 'shared.goBack' })}
            onClick={() => history.goBack()}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage id="activity.add.title" />
        </HeaderTitle>
      </HeaderArea>
      <ActivityAddSection addressId={addressId} projectId={projectId} />
    </>
  )
}

export { ActivityAdd }
