import React from 'react'
import { Link } from 'react-router-dom'
import { Project } from '../../project/types'
import { SectionItem } from '../../../shared/section'
import { IconKeyboardArrowRight } from '../../../shared/svgs'

const RelatedProjectItem: React.FC<{ data: Project }> = ({ data }) => {
  return (
    <Link to={`/projects/${data.id}`} className="block">
      <SectionItem>
        <div className="flex-1">
          <h1>{data.name}</h1>
        </div>
        <div>
          <IconKeyboardArrowRight className="w-6 h-6" />
        </div>
      </SectionItem>
    </Link>
  )
}

export { RelatedProjectItem }
