import React, { useContext } from 'react'
import { IntlProvider } from 'react-intl'
import { registerLocale } from 'react-datepicker'
import { de } from 'date-fns/locale'
import { AuthContext } from '../auth/AuthContext'

import sharedTranslations from '../shared/translations'
import appTranslations from '../layouts/app/translations'
import guestTranslations from '../domain/guest/translations'
import projectTranslations from '../domain/project/translations'
import timeTrackingTranslations from '../domain/timeTracking/translations'
import costCenterTranslations from '../domain/costCenter/translations'
import homeTranslations from '../domain/home/translations'
import addressTranslations from '../domain/address/translations'
import activityTranslations from '../domain/activity/translations'
import { i18n } from '../config/i18n'

registerLocale('de', de)

const Internationalization: React.FC = ({ children }) => {
  const auth = useContext(AuthContext)
  const currentLanguage = auth.data?.user.language || i18n.detectLanguage()

  const messages = Object.assign(
    {},
    appTranslations[currentLanguage],
    sharedTranslations[currentLanguage],
    homeTranslations[currentLanguage],
    guestTranslations[currentLanguage],
    projectTranslations[currentLanguage],
    timeTrackingTranslations[currentLanguage],
    costCenterTranslations[currentLanguage],
    addressTranslations[currentLanguage],
    activityTranslations[currentLanguage]
  )

  return (
    <IntlProvider locale={currentLanguage} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { Internationalization }
