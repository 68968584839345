import { Language } from '../domain/user/types'

export enum Module {
  timeTacking = 'time-tracking',
  addresses = 'addresses',
  activities = 'activities',
}

export enum Permission {
  create = 'create',
  edit = 'edit',
  delete = 'delete',
}

export type User = {
  name: string
  email: string
  language: Language
  username: string
}

export type ModuleAccessControl = {
  module: Module
  permissions: {
    [Permission.create]: boolean
    [Permission.edit]: boolean
    [Permission.delete]: boolean
  }
}

export type Me = {
  user: User
  modules: ModuleAccessControl[]
}
