import React from 'react'
import { AppLayout } from '../../layouts/app'
import { AddressAdd } from './add/AddressAdd'

const AddressAddPage: React.FC = () => {
  return (
    <AppLayout title={{ messageDescriptor: { id: 'address.add.title' } }}>
      <AddressAdd />
    </AppLayout>
  )
}

export { AddressAddPage }
