import { Translations } from '../../../shared/translations/types'
import enMessages from './en.json'
import deMessages from './de.json'
import itMessages from './it.json'

const translations: Translations = {
  en: enMessages,
  de: deMessages,
  it: itMessages,
}

export default translations
