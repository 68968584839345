export enum RecordType {
  WORK_START = 'work_start',
  WORK_STOP = 'work_stop',
  WORK_PAUSE = 'work_pause',
  PROJECT_COSTCENTER = 'project_costcenter',
}

export type TimeTrackingRecord = {
  id: string
  time: string
  location: {
    longitude: number | null
    latitude: number | null
  }
  type: RecordType
  projectId: string | null
  costCenterId: string | null
}

export enum TimeTrackingBufferAction {
  UPSERT = 'upsert',
  DELETE = 'delete',
}

export type TimeTrackingBufferEntry<T> = NewTimeTrackingBufferEntry<T> & {
  id: number
}

export type NewTimeTrackingBufferEntry<T> = {
  timeTrackingId: string
  timestamp: Date
  action: TimeTrackingBufferAction
  payload: T
}

export type TimeTrackingBufferUpsertPayload = TimeTrackingRecord
export type TimeTrackingBufferDeletePayload = null

export type TimeTrackingBufferPayloads =
  | TimeTrackingBufferUpsertPayload
  | TimeTrackingBufferDeletePayload

export function isUpsertAction(
  data: TimeTrackingBufferEntry<TimeTrackingBufferPayloads>
): data is TimeTrackingBufferEntry<TimeTrackingBufferUpsertPayload> {
  return data.action === TimeTrackingBufferAction.UPSERT
}

export function isDeleteAction(
  data: TimeTrackingBufferEntry<TimeTrackingBufferPayloads>
): data is TimeTrackingBufferEntry<TimeTrackingBufferDeletePayload> {
  return data.action === TimeTrackingBufferAction.DELETE
}
