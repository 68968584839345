import React, { useContext } from 'react'
import { v4 as uuid } from 'uuid'
import { useHistory } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { ActivityFormFields } from '../form/types'
import { ActivityForm } from '../form/ActivityForm'
import { Activity } from '../types'
import { formatDateForAPI } from '../form/dateFormatters'
import { saveActivity } from '../api'
import { overrideActivity } from '../db/activities'
import { AlertsContext } from '../../../app/AlertsContext'
import { extractErrorMessage } from '../../../shared/utils/apiUtils'

const ActivityAddSection: React.FC<{
  addressId: string | null
  projectId: string | null
}> = ({ addressId, projectId }) => {
  const history = useHistory()
  const { addErrorMessage } = useContext(AlertsContext)

  const initialValues: ActivityFormFields = {
    name: '',
    description: '',
    activityTypeId: '',
    categories: [],
    activityCategoryId: '',
    progresses: [],
    activityProgressId: '',
    startDate: '',
    endDate: '',
    dueDate: '',
    projectId: projectId || '',
    addressId: addressId || '',
    articles: [],
    images: [],
  }

  const onSubmit = async (
    values: ActivityFormFields,
    { resetForm }: FormikHelpers<ActivityFormFields>
  ) => {
    try {
      const apiActivity: Activity = {
        ...values,
        id: uuid(),
        startDate: formatDateForAPI(values.startDate),
        endDate: formatDateForAPI(values.endDate),
        dueDate: values.dueDate ? formatDateForAPI(values.dueDate) : null,
        signature: null,
      }

      const activity = await saveActivity(apiActivity)
      await overrideActivity(activity)

      resetForm({ values })

      history.goBack()
    } catch (error: any) {
      addErrorMessage(extractErrorMessage(error))
    }
  }

  return <ActivityForm initialValues={initialValues} onSubmit={onSubmit} />
}

export { ActivityAddSection }
