import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ContentArea } from '../../../shared/content'
import {
  Section,
  SectionItemForm,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { InputWithLabel } from '../../../shared/form'

const GeneralEditSection: React.FC = () => {
  const intl = useIntl()

  return (
    <ContentArea>
      <Section>
        <SectionTitle>
          <FormattedMessage id="address.edit.title.general" />
        </SectionTitle>
        <SectionList>
          <SectionItemForm>
            <InputWithLabel
              type="text"
              name="name"
              placeholder={intl.formatMessage({
                id: 'address.edit.name.placeholder',
              })}
              label={<FormattedMessage id="address.edit.name.label" />}
            />
            <InputWithLabel
              type="text"
              name="address.street"
              placeholder={intl.formatMessage({
                id: 'address.edit.street.placeholder',
              })}
              label={<FormattedMessage id="address.edit.street.label" />}
            />
            <InputWithLabel
              type="text"
              name="address.cap"
              placeholder={intl.formatMessage({
                id: 'address.edit.cap.placeholder',
              })}
              label={<FormattedMessage id="address.edit.cap.label" />}
            />
            <InputWithLabel
              type="text"
              name="address.city"
              placeholder={intl.formatMessage({
                id: 'address.edit.city.placeholder',
              })}
              label={<FormattedMessage id="address.edit.city.label" />}
            />
            <InputWithLabel
              type="text"
              name="address.country"
              placeholder={intl.formatMessage({
                id: 'address.edit.country.placeholder',
              })}
              label={<FormattedMessage id="address.edit.country.label" />}
            />
          </SectionItemForm>
        </SectionList>
      </Section>
    </ContentArea>
  )
}

export { GeneralEditSection }
