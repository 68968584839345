import React, { useState } from 'react'
import { FieldArray, FieldArrayRenderProps, FormikProps } from 'formik'
import { AsyncView } from '@aboutbits/react-toolbox'
import { FormattedMessage, useIntl } from 'react-intl'
import { useGetArticlesByIds } from '../db/article'
import { populateArticleData } from '../detail/populateArticleData'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { SectionListAddButton } from '../../../shared/section/SectionListAddButton'
import { ContentArea } from '../../../shared/content'
import { ArticleFormItem } from './activityForm/ArticleFormItem'
import { ActivityFormFields } from './types'
import { ArticleDialog } from './activityForm/ArticleDialog'
import { ArticleEditDialog } from './activityForm/ArticleEditDialog'

const ArticleFormSection: React.FC<{
  formik: FormikProps<ActivityFormFields>
}> = ({ formik }) => {
  const intl = useIntl()
  const { values, setFieldValue } = formik
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false)
  const [amountDialog, setAmountDialog] = useState<{
    show: boolean
    index: number
    count: number
  }>({ show: false, index: 0, count: 1 })
  const [indexOfSelectedRecord, setIndexOfSelectedRecord] = useState<
    number | null
  >(null)
  let arrayHelperBinding: FieldArrayRenderProps

  const toggleSelectedRecord = (id: number) => {
    if (indexOfSelectedRecord === id) {
      setIndexOfSelectedRecord(null)
    } else {
      setIndexOfSelectedRecord(id)
    }
  }

  const updateActivityAmount = (amount: number) => {
    const index = amountDialog.index
    const article = values.articles[index]

    setFieldValue(`articles[${index}]`, {
      ...article,
      count: amount,
    })

    setAmountDialog({
      show: false,
      count: 0,
      index: 0,
    })
  }

  const { data } = useGetArticlesByIds(
    values.articles.map((article) => article.articleId)
  )
  const articleDetails = populateArticleData(values.articles, data)

  return (
    <ContentArea>
      <div className="mb-10 space-y-5">
        <Section>
          <SectionTitle>
            <FormattedMessage id="activity.detail.article" />
          </SectionTitle>
          <AsyncView
            data={articleDetails}
            renderSuccess={(articleDetails) => (
              <SectionList>
                <FieldArray
                  name="articles"
                  render={(helper) => {
                    arrayHelperBinding = helper

                    return (
                      <>
                        {articleDetails && articleDetails.length > 0 ? (
                          articleDetails.map((article, index) => (
                            <ArticleFormItem
                              key={index}
                              article={article}
                              showActions={indexOfSelectedRecord === index}
                              onClick={() => toggleSelectedRecord(index)}
                              onEditClick={() => {
                                setAmountDialog({
                                  count: values.articles[index].count,
                                  show: true,
                                  index,
                                })
                              }}
                              onDeleteClick={() => {
                                helper.remove(index)
                                toggleSelectedRecord(index)
                              }}
                            />
                          ))
                        ) : (
                          <SectionItem>
                            <FormattedMessage id="activity.form.no.articles" />
                          </SectionItem>
                        )}
                      </>
                    )
                  }}
                />
              </SectionList>
            )}
          />
        </Section>
        <SectionListAddButton
          text={intl.formatMessage({ id: 'activity.article.add' })}
          onClick={() => {
            setShowAddDialog(true)
          }}
        />
      </div>
      <ArticleDialog
        onDismiss={() => {
          setShowAddDialog(false)
        }}
        isOpen={showAddDialog}
        onConfirm={(article) => {
          setShowAddDialog(false)
          arrayHelperBinding.push(article)
        }}
        label={intl.formatMessage({
          id: 'activity.article.overview',
        })}
      />
      <ArticleEditDialog
        onDismiss={() => {
          setAmountDialog({
            show: false,
            count: 0,
            index: 0,
          })
        }}
        isOpen={amountDialog.show}
        onConfirm={updateActivityAmount}
        label={intl.formatMessage({
          id: 'activity.article.amount.title',
        })}
        amount={amountDialog.count}
      />
    </ContentArea>
  )
}

export { ArticleFormSection }
