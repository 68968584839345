import React from 'react'
import { useParams } from 'react-router-dom'
import { AppLayout } from '../../layouts/app'
import { ActivityDetail } from './detail/ActivityDetail'

const ActivityDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <AppLayout title={{ messageDescriptor: { id: 'activity.detail.title' } }}>
      <ActivityDetail id={id} />
    </AppLayout>
  )
}

export { ActivityDetailPage }
