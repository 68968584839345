import React from 'react'
import { LoadingBlock } from '../loading'
import { Section, SectionItem, SectionList, SectionTitle } from './index'

const SectionListLoading: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        <SectionItem>
          <LoadingBlock className="w-24 md:w-64" />
        </SectionItem>
        <SectionItem>
          <LoadingBlock className="w-24 md:w-64" />
        </SectionItem>
        <SectionItem>
          <LoadingBlock className="w-24 md:w-64" />
        </SectionItem>
      </SectionList>
    </Section>
  )
}

export { SectionListLoading }
