import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { ActivityImage } from '../types'
import { BACKEND_API } from '../../../config/env'

const ActivityImageItem: React.FC<{ image: ActivityImage }> = ({ image }) => {
  const link = `${BACKEND_API}/v1/user/images/${image.imageId}`

  const onLoadingError = () => {}

  return (
    <SectionItem>
      <div className="w-2/6 h-32 bg-gray-500 rounded-lg">
        <img
          src={link}
          className="flex object-cover overflow-hidden items-center w-full h-full text-center rounded-lg"
          onError={onLoadingError}
          alt={image.description}
        />
      </div>
      <div className="w-4/6">{image.description}</div>
    </SectionItem>
  )
}

const ActivityImageSection: React.FC<{ images: Array<ActivityImage> }> = ({
  images,
}) => {
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id="activity.detail.images" />
      </SectionTitle>
      <SectionList>
        {images.map((image, index) => (
          <ActivityImageItem image={image} key={index} />
        ))}
      </SectionList>
    </Section>
  )
}

export { ActivityImageSection }
