import { FormattedMessage } from 'react-intl'
import React from 'react'
import classNames from 'classnames'
import logo1x from '../../images/logo.png'
import logo2x from '../../images/logo@2x.png'

const LogoCenter: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={classNames('flex flex-col items-center', className)}>
      <img
        srcSet={`${logo1x} 1x, ${logo2x} 2x`}
        alt="ZagZag"
        className="p-3 bg-black rounded-full"
      />
      <h1 className="mt-4 mb-2 text-xl">
        <FormattedMessage id="shared.logo.title" />
      </h1>
      <h2 className="text-md">
        <FormattedMessage id="shared.logo.subtitle" />
      </h2>
    </div>
  )
}

export { LogoCenter }
