import { AsyncView } from '@aboutbits/react-toolbox'
import React, { useMemo } from 'react'
import { Error } from '../../../shared/error'
import { useGetAddressesByIds } from '../../address/db/addresses'
import { AdditionalAddress } from '../types'
import { AdditionalAddressGroup } from './AdditionalAddressGroup'
import { additionalAddressesFilter } from './additionalAddressesFilter'

type Props = {
  additionalAddresses: Array<AdditionalAddress>
}

export function ProjectAdditionalAddresses({ additionalAddresses }: Props) {
  const ids = useMemo(() => {
    return additionalAddresses.map((address) => address.addressId)
  }, [additionalAddresses])

  const { data: addressData, error: additionalAddressError } =
    useGetAddressesByIds(ids)

  const additionalAddressData = additionalAddressesFilter(
    additionalAddresses,
    addressData
  )

  return (
    <AsyncView
      data={additionalAddressData}
      error={additionalAddressError}
      renderSuccess={(additionalAddressData) => (
        <AdditionalAddressGroup data={additionalAddressData} />
      )}
      renderError={(additionalAddressError) => (
        <Error
          text={additionalAddressError && additionalAddressError.message}
        />
      )}
    />
  )
}
