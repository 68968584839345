import React from 'react'
import { Dialog, DialogContent } from '@reach/dialog'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '../../../shared/button'
import { Variant } from '../../../shared/button/Button'

const ActivityDeleteDialog: React.FC<{
  isOpen: boolean
  disableDelete: boolean
  onDismiss: () => void
  onConfirm: () => void
}> = ({ isOpen, disableDelete, onDismiss, onConfirm }) => {
  const intl = useIntl()

  return (
    <Dialog
      isOpen={isOpen}
      className="md:my-64 mx-auto md:w-full md:max-w-lg bg-white md:bg-transparent"
      aria-label={intl.formatMessage({
        id: 'activity.detail.delete.title',
      })}
    >
      <DialogContent
        aria-label={intl.formatMessage({
          id: 'activity.detail.delete.title',
        })}
      >
        <div className="py-4 px-4 bg-white rounded-lg">
          <div className="text-xl font-bold">
            <FormattedMessage id="activity.detail.delete.title" />
          </div>
          <p className="mt-3">
            <FormattedMessage id="activity.detail.delete.text" />
          </p>
          <div className="mt-10 space-x-4 text-right">
            <Button variant={Variant.secondary} onClick={onDismiss}>
              <FormattedMessage id="shared.cancel" />
            </Button>
            <Button
              disabled={disableDelete}
              variant={Variant.primary}
              onClick={onConfirm}
            >
              <FormattedMessage id="shared.delete" />
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export { ActivityDeleteDialog }
