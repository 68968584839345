import { Module, ModuleAccessControl } from '../../auth/types'
import { checkSyncPermissions } from '../../shared/utils/syncUtil'
import { getAddresses } from './api'
import { clearAddresses, overrideAddresses } from './db/addresses'

const syncAddresses = async (
  modules: Array<ModuleAccessControl>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(modules, Module.addresses)) {
      return
    }

    const addresses = await getAddresses()
    await overrideAddresses(addresses)
  } catch (error: any) {
    if (error.response.status === 401) {
      await clearAddresses()
    }
  }
}

export { syncAddresses }
