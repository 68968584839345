import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { IconClose } from '../../../shared/svgs'
import { useGetActivityById } from '../db/activities'
import { ActivityEditSection } from './ActivityEditSection'

const ActivityEdit: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const history = useHistory()

  const { data } = useGetActivityById(id)

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage({ id: 'shared.goBack' })}
            onClick={() => history.goBack()}
            icon={IconClose}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage id="activity.edit.title" />
        </HeaderTitle>
      </HeaderArea>
      <AsyncView
        data={data}
        renderSuccess={(data) =>
          data && <ActivityEditSection activity={data} />
        }
      />
    </>
  )
}

export { ActivityEdit }
