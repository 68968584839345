import React from 'react'
import { useIntl } from 'react-intl'
import { Section, SectionList, SectionTitle } from '../../../shared/section'
import { SectionListVariant } from '../../../shared/section/SectionList'
import { Project } from '../types'
import { ProjectsSeletionItem } from './ProjectsSelectionItem'

export const ProjectsSelectionListFavorites: React.FC<{
  data: Project[]
  onSelect: (projectId: string) => void
}> = ({ data, onSelect }) => {
  const intl = useIntl()

  return data.length > 0 ? (
    <Section>
      <SectionTitle>
        {intl.formatMessage({ id: 'project.overview.list.favorites' })}
      </SectionTitle>
      <SectionList variant={SectionListVariant.PRIMARY}>
        {data.map((project, index) => (
          <ProjectsSeletionItem
            key={index}
            project={project}
            onSelect={onSelect}
          />
        ))}
      </SectionList>
    </Section>
  ) : null
}
