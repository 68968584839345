import { Module, ModuleAccessControl } from '../../auth/types'
import { checkSyncPermissions } from '../../shared/utils/syncUtil'
import { getActivities, getActivityConfig, getArticles } from './api'
import { overrideActivities, overrideActivityConfig } from './db/activities'
import { overrideArticles } from './db/article'

const syncActivities = async (
  modules: Array<ModuleAccessControl>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(modules, Module.activities)) {
      return
    }

    const activities = await getActivities()
    await overrideActivities(activities)
  } catch (error: any) {
    if (error.response.status === 401) {
    }
  }
}

const syncActivityConfigs = async (
  modules: Array<ModuleAccessControl>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(modules, Module.activities)) {
      return
    }

    const activityConfigs = await getActivityConfig()
    await overrideActivityConfig(activityConfigs.activityTypes)
  } catch (error: any) {
    if (error.response.status === 401) {
    }
  }
}

const syncArticles = async (
  modules: Array<ModuleAccessControl>
): Promise<void> => {
  try {
    if (!checkSyncPermissions(modules, Module.activities)) {
      return
    }

    const articles = await getArticles()
    await overrideArticles(articles)
  } catch (error: any) {
    if (error.response.status === 401) {
    }
  }
}

export { syncActivities, syncArticles, syncActivityConfigs }
