import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteProps } from 'react-router'
import { LoginPage } from '../domain/guest/login/LoginPage'
import { ProtectedArea } from '../auth'
import { ProjectsOverviewPage } from '../domain/project/ProjectsOverviewPage'
import { ProjectDetailPage } from '../domain/project/ProjectDetailPage'
import { HomePage } from '../domain/home/HomePage'
import { TimeTrackingOverviewPage } from '../domain/timeTracking/TimeTrackingOverviewPage'
import { TimeTrackingEditPage } from '../domain/timeTracking/TimeTrackingEditPage'
import { NotFoundError } from '../layouts/Error'
import { useHasModule, useHasModulePermission } from '../auth/hooks'
import { Module, Permission } from '../auth/types'
import { AddressesOverviewPage } from '../domain/address/AddressesOverviewPage'
import { AddressDetailPage } from '../domain/address/AddressDetailPage'
import { AddressEditPage } from '../domain/address/AddressEditPage'
import { AddressAddPage } from '../domain/address/AddressAddPage'
import { ActivitiesOverviewPage } from '../domain/activity/ActivitiesOverviewPage'
import { ActivityDetailPage } from '../domain/activity/ActivityDetailPage'
import { ActivityEditPage } from '../domain/activity/ActivityEditPage'
import { ActivitySignaturePage } from '../domain/activity/ActivitySignaturePage'
import { ActivityAddPage } from '../domain/activity/ActivityAddPage'

type ProtectedModuleRouteProps = {
  module: Module
} & RouteProps

type ProtectedModulePermissionRouteProps = {
  module: Module
  permission: Permission
} & RouteProps

const ProtectedModuleRoute: React.FC<ProtectedModuleRouteProps> = ({
  module,
  children,
  ...props
}) => {
  const hasModule = useHasModule(module)

  return <Route {...props}>{hasModule ? children : <NotFoundError />}</Route>
}

const ProtectedModulePermissionRoute: React.FC<ProtectedModulePermissionRouteProps> =
  ({ module, permission, children, ...props }) => {
    const hasModulePermission = useHasModulePermission(module, permission)

    return (
      <Route {...props}>
        {hasModulePermission ? children : <NotFoundError />}
      </Route>
    )
  }

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/login" exact>
        <LoginPage />
      </Route>
      <ProtectedArea>
        <Switch>
          <Route path="/projects" exact>
            <ProjectsOverviewPage />
          </Route>
          <Route path="/projects/:id" exact>
            <ProjectDetailPage />
          </Route>
          <ProtectedModuleRoute
            module={Module.timeTacking}
            path="/time-tracking"
            exact
          >
            <TimeTrackingOverviewPage />
          </ProtectedModuleRoute>
          <ProtectedModulePermissionRoute
            module={Module.timeTacking}
            permission={Permission.edit}
            path="/time-tracking/:id/edit"
            exact
          >
            <TimeTrackingEditPage />
          </ProtectedModulePermissionRoute>
          <ProtectedModuleRoute
            module={Module.addresses}
            path="/addresses"
            exact
          >
            <AddressesOverviewPage />
          </ProtectedModuleRoute>
          <ProtectedModuleRoute
            module={Module.addresses}
            path="/addresses/add"
            exact
          >
            <AddressAddPage />
          </ProtectedModuleRoute>
          <ProtectedModuleRoute
            module={Module.addresses}
            path="/addresses/:id"
            exact
          >
            <AddressDetailPage />
          </ProtectedModuleRoute>
          <ProtectedModuleRoute
            module={Module.addresses}
            path="/addresses/:id/edit"
            exact
          >
            <AddressEditPage />
          </ProtectedModuleRoute>
          <ProtectedModuleRoute
            module={Module.activities}
            path="/activities"
            exact
          >
            <ActivitiesOverviewPage />
          </ProtectedModuleRoute>
          <ProtectedModuleRoute
            module={Module.activities}
            path="/activities/add"
            exact
          >
            <ActivityAddPage />
          </ProtectedModuleRoute>
          <ProtectedModuleRoute
            module={Module.activities}
            path="/activities/:id"
            exact
          >
            <ActivityDetailPage />
          </ProtectedModuleRoute>
          <ProtectedModuleRoute
            module={Module.activities}
            path="/activities/:id/sign"
            exact
          >
            <ActivitySignaturePage />
          </ProtectedModuleRoute>
          <ProtectedModuleRoute
            module={Module.activities}
            path="/activities/:id/edit"
            exact
          >
            <ActivityEditPage />
          </ProtectedModuleRoute>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="*">
            <NotFoundError />
          </Route>
        </Switch>
      </ProtectedArea>
    </Switch>
  )
}

export { Routes }
