import React from 'react'
import {
  Section,
  SectionItem,
  SectionList,
  SectionTitle,
} from '../../../shared/section'
import { LoadingBlock } from '../../../shared/loading'

const TimeTrackingOverviewLoading: React.FC<{ title: string }> = ({
  title,
}) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionList>
        <SectionItem>
          <LoadingBlock className="w-24 md:w-64" />
        </SectionItem>
        <SectionItem>
          <LoadingBlock className="w-24 md:w-64" />
        </SectionItem>
        <SectionItem>
          <LoadingBlock className="w-24 md:w-64" />
        </SectionItem>
      </SectionList>
    </Section>
  )
}

export { TimeTrackingOverviewLoading }
