import React from 'react'
import classnames from 'classnames'
import { FormattedNumber } from 'react-intl'
import { SectionItem } from '../../../../shared/section'
import { Size } from '../../../../shared/section/SectionItem'
import { ActionButton } from '../../../../shared/button/ActionButton'
import { ActivityArticleDetail } from '../../detail/populateArticleData'
import { IconDelete, IconEdit } from '../../../../shared/svgs'

const ArticleFormItem: React.FC<{
  article: ActivityArticleDetail
  showActions: boolean
  onClick: (value: string) => void
  onEditClick: (value: string) => void
  onDeleteClick: (value: string) => void
}> = ({ article, onClick, showActions, onDeleteClick, onEditClick }) => {
  return (
    <div
      className={classnames(
        showActions ? 'bg-white shadow-lg' : '',
        'divide-y divide-gray-500'
      )}
    >
      <>
        <button
          className="block w-full text-left focus:outline-none"
          type="button"
          onClick={() => onClick(article.id)}
        >
          <SectionItem size={Size.xs}>
            <div className="flex-1">
              <h2>{article.name}</h2>
              <h3 className="pt-1 text-sm break-all">{article.code}</h3>
            </div>
            <div className="break-all">
              <FormattedNumber value={article.count} />
            </div>
          </SectionItem>
        </button>
        {showActions && (
          <div className="flex flex-row divide-x divide-gray-500">
            <ActionButton type="button" onClick={() => onEditClick(article.id)}>
              <IconEdit height={24} />
              <span className="pl-2">Edit</span>
            </ActionButton>
            <ActionButton
              type="button"
              onClick={() => onDeleteClick(article.id)}
            >
              <IconDelete height={24} />
              <span className="pl-2">Delete</span>
            </ActionButton>
          </div>
        )}
      </>
    </div>
  )
}

export { ArticleFormItem }
