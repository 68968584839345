import { useState, useEffect } from 'react'

import useDeepCompareEffect from 'use-deep-compare-effect'
import debounce from 'lodash.debounce'
import {
  ListResponse,
  ItemResponse,
  PaginatedResponse,
  PaginatedData,
} from './types'
import { matchAllWithPrefix } from './queryUtils'
import { db } from './index'

function attachTableListener(queryAndSetState: () => void, tableName: string) {
  const debouncedQueryAndSetState = debounce(
    () => {
      queryAndSetState()
    },
    500,
    { trailing: true }
  )

  const listener = function (this: any) {
    this.onsuccess = () => {
      debouncedQueryAndSetState()
    }
  }

  db.table(tableName).hook('creating').subscribe(listener)
  db.table(tableName).hook('updating').subscribe(listener)
  db.table(tableName).hook('deleting').subscribe(listener)

  return () => {
    debouncedQueryAndSetState.cancel()
    db.table(tableName).hook('creating').unsubscribe(listener)
    db.table(tableName).hook('updating').unsubscribe(listener)
    db.table(tableName).hook('deleting').unsubscribe(listener)
  }
}

function useGetList<T>(
  tableName: string,
  options: { sortBy?: string; direction?: 'desc' | 'asc' } = {}
): ListResponse<T> {
  const [data, setData] = useState<Array<T> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      const query = options.sortBy
        ? options.direction === 'asc'
          ? db.table(tableName).orderBy(options.sortBy).reverse()
          : db.table(tableName).orderBy(options.sortBy)
        : db.table(tableName)

      query
        .toArray()
        .then((items) => {
          setData(items)
          setError(null)
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, tableName)
  }, [options.direction, options.sortBy, tableName])

  return { data, error }
}

function useGetById<T>(tableName: string, id: string): ItemResponse<T> {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    const queryAndSetState = () =>
      db
        .table(tableName)
        .get(id)
        .then((item) => {
          setData(item)
          setError(null)
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })

    queryAndSetState()

    return attachTableListener(queryAndSetState, tableName)
  }, [id, tableName])

  return { data, error }
}

function useGetByIds<T>(tableName: string, ids: string[]): ListResponse<T> {
  const [data, setData] = useState<Array<T> | null>(null)
  const [error, setError] = useState(null)

  useDeepCompareEffect(() => {
    const queryAndSetState = () =>
      db
        .table(tableName)
        .bulkGet(ids)
        .then((items) => {
          setData(items.filter((item) => item !== undefined))
          setError(null)
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })

    queryAndSetState()

    return attachTableListener(queryAndSetState, tableName)
  }, [ids, tableName])

  return { data, error }
}

function useGetPaginatedList<T>(
  tableName: string,
  search: string,
  page: number,
  size: number,
  sortBy = 'name',
  sortDirection: 'asc' | 'desc' = 'asc'
): PaginatedResponse<T> {
  const [data, setData] = useState<PaginatedData<T> | null>(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    function queryAndSetState() {
      const getQuery = async (
        search: string
      ): Promise<{ items: Array<T>; count: number }> => {
        if (search === '') {
          const count = await db.table(tableName).count()
          let dbQuery = db
            .table(tableName)
            .orderBy(sortBy)
            .offset((page - 1) * size)
            .limit(size)

          return sortDirection === 'desc'
            ? dbQuery
                .reverse()
                .toArray()
                .then((items) => ({ items, count }))
            : dbQuery.toArray().then((items) => ({ items, count }))
        } else {
          return matchAllWithPrefix(
            db,
            tableName,
            'search',
            search.split(' ').filter((term) => term !== ''),
            (page - 1) * size,
            size,
            sortBy,
            sortDirection
          )
        }
      }

      getQuery(search)
        .then(({ items, count }) => {
          setData({
            items,
            total: count,
            page,
            size,
          })
          setError(null)
        })
        .catch((error) => {
          setData(null)
          setError(error)
        })
    }

    queryAndSetState()

    return attachTableListener(queryAndSetState, tableName)
  }, [tableName, search, page, size, sortBy, sortDirection])

  return { data, error }
}

export {
  useGetList,
  useGetById,
  useGetByIds,
  attachTableListener,
  useGetPaginatedList,
}
