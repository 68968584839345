import React from 'react'
import classnames from 'classnames'

const ActionButton: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ children, className, ...props }) => {
  return (
    <button
      className={classnames(
        className,
        'flex flex-row justify-center items-center py-4 w-1/3 md:w-1/4 text-xs hover:bg-gray-500 focus:outline-none'
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export { ActionButton }
