import React from 'react'
import { Dialog } from '@reach/dialog'
import { ArticleAmountView } from './steps/ArticleAmountView'

const ArticleEditDialog: React.FC<{
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  isOpen: boolean
  onConfirm: (amount: number) => void
  amount?: number
  label: string
}> = ({ isOpen, onConfirm, onDismiss, amount = 0, label }) => {
  return (
    <Dialog
      isOpen={isOpen}
      aria-label={label}
      className="md:my-20 mx-auto md:w-full md:max-w-lg bg-white md:bg-transparent"
    >
      <ArticleAmountView
        onDismiss={onDismiss}
        onConfirm={onConfirm}
        amount={amount}
      />
    </Dialog>
  )
}

export { ArticleEditDialog }
