import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useHistory } from 'react-router-dom'
import { ActionButton } from '../../../../shared/button/ActionButton'
import { IconEdit } from '../../../../shared/svgs'

const TimeTrackingItemEdit: React.FC<{ id: string }> = ({ id }) => {
  const history = useHistory()

  const navigateToEditForm = () => {
    history.push('/time-tracking/' + id + '/edit')
  }

  return (
    <ActionButton onClick={navigateToEditForm}>
      <IconEdit className="mr-2 w-5 h-5" />
      <FormattedMessage id="timeTracking.item.edit" />
    </ActionButton>
  )
}

export { TimeTrackingItemEdit }
