import React, { useState, useCallback, useEffect } from 'react'
import { Loading } from '../layouts/Loading'
import { syncAuth } from '../db'
import { useInterval } from '../packages/reactUseInterval'
import { getAuthSyncDate, getAuthMe } from '../db/tables/settings'
import { Me } from './types'

const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000

export type AuthContextValue = {
  authenticated: boolean
  data: null | Me
}

const initialContext = {
  authenticated: false,
  data: null,
}

export const AuthContext = React.createContext<AuthContextValue>(initialContext)

const AuthChecker: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthContextValue>()
  const [delay, setDelay] = useState<number | null>(null)

  const updateAuthContext = useCallback((me: Me | null): void => {
    if (!me) {
      setAuth({ authenticated: false, data: null })
    } else {
      setAuth({ authenticated: true, data: me })
    }
  }, [])

  const refresh = useCallback((): void => {
    syncAuth()
      .then((me) => {
        updateAuthContext(me)
      })
      .catch((e) => {
        if (e?.response?.status === 401) {
          updateAuthContext(null)
        }
      })
      .finally(() => {
        setDelay(FIVE_MINUTES_IN_MILLISECONDS)
      })
  }, [updateAuthContext])

  useInterval(() => {
    refresh()
  }, delay)

  useEffect(() => {
    // On first render, set the user from DB if present, else ignore
    // We have to ignore otherwise after the login, the user will be redirected back to the login page, as there is still nothing in the DB
    getAuthMe().then((me) => {
      if (me !== null) {
        updateAuthContext(me)
      }
    })

    getAuthSyncDate()
      .then((date) => {
        const delta = new Date().getTime() - date.getTime()

        if (delta > FIVE_MINUTES_IN_MILLISECONDS) {
          refresh()
        } else {
          setDelay(FIVE_MINUTES_IN_MILLISECONDS - delta)
        }
      })
      .catch(() => {
        refresh()
      })
  }, [refresh, updateAuthContext])

  if (auth === undefined) {
    return <Loading />
  } else {
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
  }
}

export { AuthChecker }
