import React from 'react'
import classNames from 'classnames'

export enum SectionListVariant {
  NORMAL,
  PRIMARY,
}

const SectionList: React.FC<{
  variant?: SectionListVariant
  className?: string
}> = ({ variant = SectionListVariant.NORMAL, className, children }) => {
  const variantStyles = {
    [SectionListVariant.NORMAL]: 'bg-gray-300',
    [SectionListVariant.PRIMARY]: 'bg-primary-500',
  }

  return (
    <div
      className={classNames(
        'md:rounded-lg divide-y divide-gray-500 md:overflow-hidden',
        variantStyles[variant],
        className
      )}
    >
      {children}
    </div>
  )
}

export { SectionList }
