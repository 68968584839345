import React from 'react'
import { AppLayout } from '../../layouts/app'
import { AddressOverview } from './overview/AddressOverview'

const AddressesOverviewPage: React.FC = () => (
  <AppLayout title={{ messageDescriptor: { id: 'address.overview.title' } }}>
    <AddressOverview />
  </AppLayout>
)

export { AddressesOverviewPage }
