export function groupByDate<K extends string, T extends Record<K, string>>(
  property: K,
  objectArray: Array<T>
): Array<{ date: string; data: Array<T> }> {
  let temp: Record<string, Array<T>> = {}

  const groupedObject = objectArray.reduce((acc, obj) => {
    const jsDate = new Date(obj[property])
    const month = jsDate.getMonth() + 1
    const date =
      jsDate.getFullYear() +
      '-' +
      (month > 9 ? month : '0' + month) +
      '-' +
      (jsDate.getDate() > 9 ? jsDate.getDate() : '0' + jsDate.getDate())

    if (!acc[date]) {
      acc[date] = []
    }

    // Add object to list for given key's value
    acc[date].push(obj)
    return acc
  }, temp)

  return Object.keys(groupedObject)
    .map((date) => {
      return { date, data: groupedObject[date] }
    })
    .sort((a, b) => b.date.localeCompare(a.date))
}

export function groupArrayByKey<T>(
  data: Array<T>,
  groupBy: string
): Array<Array<T>> {
  const group = data.reduce((res: any, acc: any) => {
    res[acc[groupBy]] = res[acc[groupBy]] || []
    res[acc[groupBy]].push(acc)
    return res
  }, Object.create(null))

  return Object.values(group)
}
