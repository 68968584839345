import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router'

import { AsyncView } from '@aboutbits/react-toolbox'
import { Error } from '../../../shared/error'
import { HeaderArea, HeaderTitle } from '../../../shared/header'
import { HeaderBackAction } from '../../../shared/header/HeaderBackAction'
import { useGetTimeTrackingRecordById } from '../db/records'
import { TimeTrackingEditForm } from './TimeTrackingEditForm'
import { TimeTrackingLoading } from './TimeTrackingLoading'

const TimeTrackingEdit: React.FC<{ id: string }> = ({ id }) => {
  const intl = useIntl()
  const history = useHistory()

  const onBack = (): void => {
    history.goBack()
  }

  const { data, error } = useGetTimeTrackingRecordById(id)

  return (
    <>
      <HeaderArea
        navigation={
          <HeaderBackAction
            label={intl.formatMessage({ id: 'shared.goBack' })}
            onClick={onBack}
          />
        }
      >
        <HeaderTitle>
          <FormattedMessage id="timeTracking.edit.title" />
        </HeaderTitle>
      </HeaderArea>

      <AsyncView
        data={data}
        error={error}
        renderLoading={<TimeTrackingLoading />}
        renderSuccess={(data) => data && <TimeTrackingEditForm data={data} />}
        renderError={(error) => <Error text={error && error.message} />}
      />
    </>
  )
}

export { TimeTrackingEdit }
