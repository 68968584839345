import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Error } from '../../../shared/error'
import { useGetFavoriteProjects, useGetProjects } from '../db/projects'
import {
  HeaderArea,
  HeaderSearch,
  HeaderSmallAction,
  HeaderTitle,
  HeaderRightArea,
} from '../../../shared/header'
import { IconSearch } from '../../../shared/svgs'
import { useSearchAndPaginationQueryUrlParameters } from '../../../shared/pagination/routerPagination'
import { ContentArea } from '../../../shared/content'
import { SectionListLoading } from '../../../shared/section/SectionListLoading'
import { ProjectsOverviewList } from './ProjectsOverviewList'
import { ProjectsOverviewFavorites } from './ProjectsOverviewFavorites'

const ProjectsOverview: React.FC = () => {
  const intl = useIntl()
  const { page, size, search, searchActions } =
    useSearchAndPaginationQueryUrlParameters()

  const [searchShow, setSearchShow] = useState<boolean>(search !== '')

  const startSearch = (): void => {
    setSearchShow(true)
  }

  const stopSearch = (): void => {
    setSearchShow(false)
    searchActions.clear()
  }

  const searching = searchShow && search !== ''

  const { data: favoritesData } = useGetFavoriteProjects()

  const { data: projectData, error: projectError } = useGetProjects(
    search,
    page,
    size
  )

  const title = searching
    ? intl.formatMessage({ id: 'project.overview.search.result' })
    : intl.formatMessage({ id: 'project.overview.list.all' })

  const empty = searching
    ? intl.formatMessage({ id: 'project.overview.search.empty' })
    : intl.formatMessage({ id: 'project.overview.list.empty' })

  return (
    <>
      {searchShow ? (
        <HeaderSearch
          text={search}
          setText={searchActions.search}
          stopSearch={stopSearch}
          clearSearch={searchActions.clear}
        />
      ) : (
        <HeaderArea>
          <HeaderTitle>
            <FormattedMessage id="project.overview.title" />
          </HeaderTitle>
          <HeaderRightArea>
            <HeaderSmallAction
              icon={IconSearch}
              label={intl.formatMessage({
                id: 'project.overview.search.start',
              })}
              onClick={startSearch}
            />
          </HeaderRightArea>
        </HeaderArea>
      )}
      <ContentArea>
        {page === 1 && search === '' && (
          <AsyncView
            data={favoritesData}
            renderSuccess={(favoritesData) =>
              favoritesData && (
                <ProjectsOverviewFavorites data={favoritesData} />
              )
            }
          />
        )}
        <AsyncView
          data={projectData}
          error={projectError}
          renderLoading={<SectionListLoading title={title} />}
          renderSuccess={(projectData) =>
            projectData && (
              <ProjectsOverviewList
                title={title}
                empty={empty}
                data={projectData}
              />
            )
          }
          renderError={(projectError) => (
            <Error text={projectError && projectError.message} />
          )}
        />
      </ContentArea>
    </>
  )
}

export { ProjectsOverview }
