import React from 'react'
import classnames from 'classnames'

import styles from './DialogContent.module.css'

const DialogContent: React.FC = ({ children }) => {
  return (
    <div
      className={classnames(
        styles['center-dialog'],
        'p-4 max-w-lg bg-white rounded-lg'
      )}
    >
      {children}
    </div>
  )
}

export { DialogContent }
