import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { AsyncView } from '@aboutbits/react-toolbox'
import { Error } from '../../../shared/error'
import { ContentArea } from '../../../shared/content'
import { useSearchAndPaginationInMemory } from '../../../shared/pagination/inMemoryPagination'
import { useGetCostCentersBySearch } from '../db/costCenters'
import { DialogSelectionLoading } from '../../../shared/dialog/selection/DialogSelectionLoading'
import { DialogSearchHeader } from '../../../shared/dialog/DialogSearchHeader'
import { CostCentersSelectionList } from './CostCentersSelectionList'

type Props = {
  onDismiss: (event?: React.SyntheticEvent<Element, Event> | undefined) => void
  onConfirm: (value: string) => void
}

const CostCentersSelection: React.FC<Props> = ({ onDismiss, onConfirm }) => {
  const intl = useIntl()
  const { page, size, search, searchActions, paginationActions } =
    useSearchAndPaginationInMemory()

  const searching = search !== ''

  const { data, error } = useGetCostCentersBySearch(search, page, size)

  const title = searching
    ? intl.formatMessage({ id: 'costCenter.overview.search.result' })
    : intl.formatMessage({ id: 'costCenter.overview.list.all' })

  const empty = searching
    ? intl.formatMessage({ id: 'costCenter.overview.search.empty' })
    : intl.formatMessage({ id: 'costCenter.overview.list.empty' })

  return (
    <>
      <DialogSearchHeader
        searchActions={searchActions}
        search={search}
        searchLabelId="costCenter.overview.search.start"
        title={<FormattedMessage id="costCenter.overview.title" />}
        onDismiss={onDismiss}
      />
      <ContentArea>
        <AsyncView
          data={data}
          error={error}
          renderLoading={<DialogSelectionLoading title={title} />}
          renderSuccess={(data) =>
            data && (
              <CostCentersSelectionList
                title={title}
                empty={empty}
                data={data}
                onSelect={onConfirm}
                paginationActions={paginationActions}
              />
            )
          }
          renderError={(error) => <Error text={error && error.message} />}
        />
      </ContentArea>
    </>
  )
}

export { CostCentersSelection }
